/* Elements Forms */
.form-control {
	height: 38px;
	font-size: 13px;
	border-radius: 0;
	margin-bottom: 20px;
	color: #5a5a5a;
	border-color: #eaeaea;
	padding: 9px 15px;
	transition: all 0.4s;
	box-shadow: none;
	text-align: left;

	&.input-sm {
		height: 32px;
		font-size:11px;
		padding: 7px 10px;
	}

	&.input-lg {
		height: 43px;
		font-size:15px;
		padding: 10.5px 20px;
	}

	&:focus {
		border-color: $first-color;
		box-shadow: none;
	}
	
	// Gray Background
	.bg-gray & {
		background-color: darken($gray, 6%);
		border-color: darken($gray, 6%);

		&:focus {
			background-color: darken($gray, 9%);
			border-color: darken($gray, 9%);
		}
	}

	// Dark Background
	.bg-dark & {
		background-color: lighten($dark, 4%);
		border-color: lighten($dark, 4%);
		color: #9e9e9e;

		&::placeholder {
			color: #9e9e9e;
		}

		&:focus {
			background-color: lighten($dark, 6%);
			border-color: lighten($dark, 6%);
		}
	}
}

.input-group {
	margin-bottom:20px;
	border-radius: 0;

	.input-group-addon {
		font-size:13px;
		border-radius:0;
		border-color: #eaeaea;
		background-color: #f6f6fa;
		color: #4a4a4a;
		padding: 6px 15px;
	}

	&.input-group-sm {
		.input-group-addon {
			font-size:11px;
			height:32px;
			padding:3px 10px;
		}

		.form-control {
			height: 32px;
			padding: 6px 10px;
		}
	}

	&.input-group-lg {
		.input-group-addon {
			height: 43px;
			font-size:15px;
			padding:3px 20px;
		}

		.form-control {
			height: 43px;
			padding: 8.5px 20px;
		}
	}

	.form-control {
		border-radius: 0;

		&:last-child {
			border-radius: 0;
		}
	}

}

label,
.input-desc {
	font-weight: 600;
	margin-bottom: 5px;
}


textarea.form-control {
	min-height: 150px;
}

.textarea-container {
	position: relative;
	width:100%;
	padding-top:38px;

	.input-group-addon {
		position: absolute;
		top:0;
		left:0;
		width:100%;
		padding:11.5px 15px;
		z-index:90;
		text-align: left;
		border-right: 1px solid #eaeaea;
	}

	.form-control {
		border-top-color:transparent;

		&:focus {
			border-top-color: $first-color;
		}
	}
}

/* Validation error */ 
label.error {
	display: block;
	font-weight: 400;
	font-size:11px;
	color: #ca1515;
	margin:-18px 0 10px;
}

.form-control.error {
	border-color:#ca1515;
}

/* Custom Radio & Checkbox */
.radio label.custom-radio-wrapper, 
.checkbox label.custom-checkbox-wrapper {
	padding-left:0;
}

.input-group-addon.custom-checkbox-wrapper,
.input-group-addon.custom-radio-wrapper {
	line-height: 0;
}

.custom-checkbox-container,
.custom-radio-container {
	display:inline-block;
	width:14px;
	height:14px;
	border:1px solid #e1e1e1;
	position: relative;
	margin-right:10px;
	border-radius: 0;
}

.custom-radio-container { 
	border-radius: 50px;
}

.custom-checkbox-container  {
	input[type="checkbox"] {
		display:block;
		opacity:0;
		filter:alpha(opacity=0);
		width:100%;
		height:100%;
		margin:0;
		padding:0;
		position: absolute;
		left:0;
		top:0;
		z-index:10;
	}

	.custom-checkbox-icon {
		position:absolute;
		z-index:9;
		width:10px;
		height: 10px;
		left:50%;
		top:50%;
		margin-top:-5px;
		margin-left:-5px;
		color: $first-color;
		opacity:0;
		filter:alpha(opacity=0);
		border-radius: 0;
		font: 15px/1 FontAwesome;

		&:before {
			content:"\f00c";
			position: absolute;
			top:-3px;
			left:0;
		}
	}
}

.custom-radio-container  {
	input[type="radio"] {
		display:block;
		opacity:0;
		filter:alpha(opacity=0);
		width:100%;
		height:100%;
		margin:0;
		padding:0;
		position: absolute;
		left:0;
		top:0;
		z-index:10;
	}

	.custom-radio-icon {
		position:absolute;
		z-index:9;
		display: block;
		opacity:0;
		filter:alpha(opacity=0);
		width:10px;
		height:10px;
		left:50%;
		top:50%;
		margin-top:-5px;
		margin-left:-5px;
		background-color: $first-color;
		border-radius: 50%;
		transition: all 0.4s;
		transform: scale(0);
	}
}

.disabled .custom-checkbox-container .custom-checkbox-icon  {
	display: none;
}

.disabled .custom-radio-container .custom-radio-icon {
	background-color: transparent;
}

.custom-checkbox-container input[type="checkbox"]:checked + .custom-checkbox-icon,
.custom-radio-container input[type="radio"]:checked + .custom-radio-icon {
	opacity:1;
	filter:alpha(opacity=100);
}

.custom-radio-container input[type="radio"]:checked + .custom-radio-icon {
	transform: scale(1);
}

.disabled .custom-checkbox-container input[type="checkbox"]:checked + .custom-checkbox-icon {
	background-color: transparent;
}

.custom-checkbox-wrapper,
.custom-radio-wrapper {
	* {
		vertical-align: middle;
	}

	&.checkbox-inline,
	&.radio-inline {
		padding-left:0;
	}
}