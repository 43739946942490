.pricing-table {
	position: relative;
	text-align:center;
	color: #555;
	margin-bottom:30px;
	max-width: 380px;
	margin-left:auto;
	margin-right:auto;

	@media (min-width: $maxmqmd) {
		margin-bottom:60px;
	}

	header {
		background-color: $first-color;
		color:#fff;
		padding:21px 0 22px;

		.pricing-title {
			font-size:18px;
			font-weight:400;
			color: #fff;
			padding-bottom: 19px;
			border-bottom: 1px solid lighten($first-color, 5%);
			margin-bottom: 20px;
		}

		.price {
			font-size:48px;
			line-height:1;
			font-weight: 700;

			span {
				font-size: 50%;
				vertical-align:super;
			}
		}

		.price-dur {
			display:block;
			font-size:13px;
		}
	}

	.pricing-body {
		border:1px solid #eaeaea;
		border-top:none;
		background-color: #f6f6fa;
		padding:23px 25px;

		.desc {
			padding: 0 20px;
			font-size:12px;

			&:last-child {
				margin-bottom:0;
			}
		}

		ul {
			margin-bottom:15px;

			&:last-child {
				margin-bottom:0;
			}

			li {
				padding:7px 0;
				border-bottom:1px solid #eaeaea;

				&:first-child {
					border-top: 1px solid #eee;
				}

				i {
					margin-right:8px;
					color: #6e6e6e;
				}

				&.disabled {
					text-decoration: line-through;
				}
			}
		}

		.price-action {
			margin-top:20px;
		}
	}

	.badge.hot {
		color: #fff;
		background-color: $second-color;
		width:54px;
		height:54px;
		font-size: 13px;
		line-height:54px;
		text-align:center;
		position: absolute;
		font-weight:400;
		padding:0;
		right:10px;
		top:12px;
		transform: rotate(14deg);
		box-shadow: 0 0 2px rgba(255, 255, 255, 0.1);
		border-radius: 50%;
	}

	&.active {
		@media (min-width: $minmqmd) {
			margin-top:-20px;

			header {
				padding-top:30px;
				padding-bottom: 23px;

				.pricing-title {
					font-weight:400;
					padding-bottom:29px;
					margin-bottom:21px;
				}
			}
		}
	}

	&.dark {
		header {
			background-color: #171717;

			.pricing-title {
				border-bottom-color: #444;
			}
		}

		.badge.hot {
			background-color: $first-color;
		}
	}
}