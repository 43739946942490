/* Elements Callouts */

.container > .callout {
	padding-left: 30px;
	padding-right:30px;

	@media (max-width: $maxmqsm) {
		padding-left: 15px;
		padding-right:15px;
	}
}

.callout {
	padding:35px 0;
	width:100%;
	background-color: #f6f6fa;
	margin-bottom:50px;


	.callout-title {
		line-height:1.5;
		color: #2a2a2a
	}

	small {
		font-size: 85%;
		color: #323232;
	}

	&.callout-half  {
		margin-bottom:0;

		form {
			position: relative;
			max-width:450px;

			.btn {
				position: absolute;
				right:2px;
				top:2px;
				bottom:2px;
			}
		}

		.form-control {
			margin-bottom:0;
			height:44px;
			border-width:2px;
			padding:11px 110px 11px 20px;
			border-color: #bbb;

			&:focus {
				border-color: #9a9a9a;
			}
		}

		small {
			font-size: 75%;
		}

		&.dark {
			.form-control {
				background-color: #191919;
				border-color: #0b0b0b;
				color: #fff;

				&:focus {
					border-color: #000;
				}
			}
		}

		&.custom {
			.form-control {
				background-color: darken($first-color, 5%);
				border-color: darken($first-color, 10%);
				color: #fff;

				&:focus {
					border-color: darken($first-color, 13%);
				}
			}
		}

		&.dark,
		&.custom {
			.form-control::-moz-placeholder {
				color: #dedede;
			}
			.form-control:-ms-input-placeholder {
			  color: #dedede;
			}
			.form-control::-webkit-input-placeholder {
			  color: #dedede;
			}
		}
	}

	@media (min-width: $minmqsm) {
		&,
		> .container {
			display:table;
		}

		.left,
		.right {
			display:table-cell;
			vertical-align: middle;
		}

		.right {
			text-align: right;
			padding-left:20px;
		}

		.callout-title {
			margin-bottom:0;
		}

		&.callout-half  {
			position: relative;
			padding-top:35px;
			padding-bottom:35px;

			> .container {
				table-layout: fixed;
				position: relative;
				z-index:1;

				.left {
					width:50%;
					padding-right:40px;
			 	}

				.right {
					width: 50%;
					padding-left: 40px;
				}
			}

			form {
				margin-left:auto;
				margin-right:auto;
			}

			&:before {
				content:'';
				display:block;
				position: absolute;
				left:50%;
				right:0;
				top:0;
				bottom:0;
				z-index:0;
				background-color: #eaeaea;
			}

			&.dark {
				&:before {
					background-color: #131313;
				}
			}

			&.custom {
				&:before {
					background-color: darken($first-color, 4%);
				}
			}
		}
	}

	
	// dark colors
	&.dark {
		background-color: #202020;
		color: #9e9e9e;

		.callout-title {
			color: #fff;
		}

		small {
			color: #9e9e9e;
		}
	}
	
	// custom colors
	&.custom {
		background-color: $first-color;
		color: lighten($first-color, 20%);

		.callout-title {
			color: #fff;
		}

		small {
			color: lighten($first-color, 20%);
		}
	}

}