/* Homepages - Index */
.slider-container-index {
	background-color: #e1e1e1;
	
	@media (min-width: $minmqlg) {
		height: 520px;
	}

	.tp-caption {
		strong {
			font-weight: 700 !important;
		}
	}

	.hebe .tp-bullet-image {
		border-radius: 0;
	}
}

.tparrows.custom {
	width:54px;
	height: 54px;
	color: #121212;
	background-color: rgba(255, 255, 255, 0.85);
	transition: background-color 0.4s;

	&:before {
		color: #121212;
		font-size: 16px;
		line-height: 54px;
	}

	&:hover,
	&:focus {
		color: #101010;
		background-color: #fff;

		&:before {
			color: #101010;
		}
	}
}