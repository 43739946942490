/* Category(Shop) Pages */
.category-filter-row {
	margin-bottom:25px;
	padding:6px;
	background-color: #f6f6fa;

	@media (min-width: $minmqsm) {
		padding:8px 15px;
	}

	.left {
		white-space: nowrap;

		.filter-container {
			float:left;

			&.filter-show {
				float:right;
			}
		}

		@media (min-width: $minmqsm) {
			float:left;
			margin-bottom:0;

			.filter-container, {
				float:left;

				&.filter-show {
					float:left;
				}
			}

			.filter-sort {
				margin-right: 25px;
			}
		}

		&:after {
			content:'';
			display:table;
			clear:both;
		}
	}

	.right {
		margin-bottom:10px;
		white-space: nowrap;
		@media (min-width:$minmqsm) {
			float:right;
			margin-bottom:0;
		}

		&:after {
			content:'';
			display:table;
			clear:both;
		}
	}

	label,
	.form-control {
		display:inline-block;
		vertical-align: middle;
		margin-bottom:0;
		width: auto;
	}

	label {
		margin-right:6px;
	}

	.form-control {
		padding-left:5px;
		padding-right:5px;
	}

	&:after {
		content:'';
		display:table;
		clear:both;
	}

	.cat-product-count,
	.btn,
	.filter-product-view {
		display:inline-block;
		vertical-align:middle;
	}

	.btn + .btn {
		margin-left:5px;
	}

	.cat-product-count {
		font-weight:600;
		line-height:32px;
		margin-right:25px;
	}

	.cat-product-count {
		@media (max-width: $maxmqsm) {
			float:left;
		}
	}

	.filter-product-view  {
		font-size:0;

		.btn.btn-sm {
			min-width:32px;
			text-align:center;
			font-size:18px;
			padding:1px 3px 2px;
			i {
				margin:0;
			}
		}
		@media (max-width: $maxmqsm) {
			float:right;
		}
	}
}


/* Sidebar */
.sidebar.shop-sidebar {
	@media (max-width: $maxmqmd) {
		margin-bottom:70px;
	}
	
	.widget {
		margin-bottom:35px;

		.widget-title {
			margin-bottom: 18px;
		}

		.btn {
			color: #fff;
		}
	}
}

.category-widget-btn {
	display:block;
	width:20px;
	height:20px;
	font-family: 'FontAwesome';
	font-size:10px;
	line-height: 21px;
	text-align: center;
	color: $first-color;
	background-color: #eaeaea;
	border-radius: 50%;
	position: absolute;
	left:10px;
	top:8px;
	transition: all 0.4s;
	-webkit-transition: all 0.4s;

	&:after {
		content: "\f105";
	}

	&:hover,
	&:focus {
		background-color: #333;
		color:#fff;
	}
}

.open .category-widget-btn:after {
	content: "\f107";
}

.filter-price-label {
	font-size:12px;
}

.filter-color-container,
.filter-size-container  {
	font-size:0;

	.filter-row {
		margin-left:-2.5px;
		margin-right:-2.5px;

		&:after {
			content: '';
			display: table;
			clear: both;
		}
	}
}

.filter-color-box,
.filter-size-box {
	display: inline-block;
	width:24px;
	height:24px;
	margin: 0 2.5px 5px;
	transition: all 0.5s;

	&:hover,
	&:focus {
		box-shadow: 0 0 3px rgba(0,0,0, 0.4);
	}

}

.filter-size-box {
	color:#323232 !important;
	background-color:#eaeaea;
	font-size:11px;
	line-height:24px;
	text-align: center;
	text-transform: uppercase;

	&.active,
	&:hover,
	&:focus {
		color:#fff !important;
		background-color: $first-color;
	}
}

.sidebar .filter-group-widget .panel-body {
	padding:35px 0 30px;
}

.filter-brand-list {
	margin-top:-10px;

	a {
		color: #323232;
		display:block;
		font-size:13px;
		line-height:22px;
		color:#7e7e7e;
		padding:2px 0;

		&:hover,
		&:focus {
			color: $first-color;
		}

		i {
			margin-right: 8px;
		}

		span {
			float:right;
		}
	}
}

#filter-range-details.row {
	margin-left:-5px;
	margin-right:-5px;

	[class*="col-"] {
		padding-left:5px;
		padding-right:5px;
	}
}


#price-slider {
	margin:0 0 35px;
}

/* noUiSlider Styles */
/* Functional styling;
* These styles are required for noUiSlider to function.
* You don't need to change these rules to apply your design.
*/
.noUi-target,
.noUi-target * {
-webkit-touch-callout: none;
-webkit-user-select: none;
-ms-touch-action: none;
-ms-user-select: none;
-moz-user-select: none;
-moz-box-sizing: border-box;
	box-sizing: border-box;
}
.noUi-target {
	position: relative;
	direction: ltr;
}
.noUi-base {
	width: 100%;
	height: 100%;
	position: relative;
	z-index: 1; /* Fix 401 */
}
.noUi-origin {
	position: absolute;
	right: 0;
	top: -1px;
	left: 0;
	bottom: 0;
	height:3px;
}
.noUi-handle {
	position: relative;
	z-index: 1;
}
.noUi-stacking .noUi-handle {
/* This class is applied to the lower origin when
   its values is > 50%. */
	z-index: 10;
}
.noUi-state-tap .noUi-origin {
	transition: left 0.3s, top 0.3s;
}
.noUi-state-drag * {
	cursor: inherit !important;
}

/* Painting and performance;
 * Browsers can paint handles in their own layer.
 */
.noUi-base {
	transform: translate3d(0,0,0);
}

/* Slider size and handle placement;
 */
.noUi-horizontal {
	height: 3px;
}
.noUi-horizontal .noUi-handle {
	width: 13px;
	height: 13px;
	left: -6.5px;
	top: -5.5px;
}

/* Styling;
 */
.noUi-background {
	background: #eaeaea;
}
.noUi-connect {
	background: $first-color;
	transition: all 0.4s;
}
.noUi-origin {
	border-radius: 2px;
}
.noUi-target {
	border-radius: 8px;
	border: 1px solid #eaeaea;
}

/* Handles and cursors;
 */
.noUi-dragable {
	cursor: w-resize;
}
.noUi-vertical .noUi-dragable {
	cursor: n-resize;
}
.noUi-handle {
	border-radius: 50px;
	background: #fff;
	cursor: default;
	box-shadow: 0 0 0 3px rgba(122, 174, 182, 0.43);
}

.noUi-handle > div {
	position: absolute;
	top:100%;
	left:50%;
	transform: translateX(-50%);
	margin-top:4px;
	font-size:12px;
	color: #5a5a5a;
}