/*Side Menu - Dark */
.header.dark {
	.side-menu {
		li {
			a {
				color: #eee;
			}

			&.active,
			&:hover,
			&:focus {
				> a,
				> a:hover,
				> a:focus {
					color: $first-color;
				}
			}
		}
	}
}