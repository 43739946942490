.error-page {
	padding-top:20px;
	padding-bottom:90px;
	
	@media (min-width: $minmqsm) {
		padding-top:30px;
		padding-bottom:100px;
	}

	@media (min-width: $minmqmd) {
		padding-top:60px;
		padding-bottom:130px;
	}

	h1 {
		font-size:120px;
		margin-bottom:35px;
		line-height:0.7;
		color: $first-color;
		font-weight: 600;

		@media (min-width: $minmqsm) {
			font-size:180px;
			margin-bottom:50px;
		}

		@media (min-width: $minmqmd) {
			font-size:260px;
			margin-bottom:70px;
		}
	}

	h2 {
		font-size: 22px;
		font-weight: 700;
		margin-bottom:20px;

		@media (min-width: $minmqsm) {
			font-size: 26px;
		}
		
		@media (min-width: $minmqmd) {
			font-size: 32px;
			margin-bottom:30px;
		}
	}

	p {
		font-weight:300;
		font-size:15px;
		line-height:1.5;
		max-width:420px;
		margin-left:auto;
		margin-right:auto;
		margin-bottom:25px;

		@media (min-width: $minmqsm) {
			font-size:18px;
		}
		
		@media (min-width: $minmqmd) {
			font-size:20px;
			max-width:520px;
			margin-bottom:30px;
		}
	}
}