/* Elements Buttons */

.btn {
	position: relative;
	z-index:2;
	transition: background 0.45s, border 0.45s;
	font-size: 13px;
	font-weight:400;
	padding:9px 15px;
	border-radius:0;

	&.btn-rounded {
		border-radius: 5px;
	}

	&.btn-border {
		padding:8px 14px;
		border-width:2px;
	}

	&.btn-sm {
		font-size: 11px;
		padding:7px 10px;

		&.btn-rounded {
			border-radius:4px;
		}

		&.btn-border {
			padding:6px 9px;
			border-width:2px;
		}
	}

	&.btn-lg {
		font-size: 15px;
		padding:11px 20px;

		&.btn-rounded {
			border-radius:7px;
		}

		&.btn-border {
			padding:10px 19px;
			border-width:2px;
		}
	}

	i {
		margin-right:5px;
		font-size: 80%;
		vertical-align:middle;
		margin-top:-3px;
	}

	&:active,
	&.active {
		box-shadow:none;
		-webkit-box-shadow:none;
	}

	&:focus,
	&:active {
		outline:none;
	}

	&.min-width {
		min-width:150px;
		text-align:center !important;

		@media (max-width: $maxmqxs) {
			min-width:0;
		}
	}

	&.min-width-sm {
		min-width:120px;
		text-align:center !important;

		@media (max-width: $maxmqxs) {
			min-width:0;
		}
	}

	&.min-width-lg {
		min-width:170px;
		text-align:center !important;

		@media (max-width: $maxmqxs) {
			min-width:0;
		}
	}
}

// For Revolution slider buttons
.tp-caption.btn i {
	font-size: inherit;
	margin-top: auto;
	margin-right:0;
}

.btn-group {
	> .btn + .btn {
		border-left: 1px solid rgba(255, 255, 255, 0.15);
	}

	/* Dropdown */
	.dropdown-menu {
		padding:7px 0;
		border-radius: 0;
		font-size:13px;
		border-color: #eee;

		li {
			a {
				color:#323232;
				padding:4px 15px;
				transition: background 0.4s;

				&:hover,
				&:focus {
					color:#323232;
					background-color: #f6f6fa;
				}
			}
		}
	}
}



/* Button Colors */
.btn.btn-custom {
	@include button(#fff, $first-color);

	&.btn-border {
		@include button-border($first-color, $first-color);
	}
}

.btn.btn-custom2 {
	@include button(#fff, $second-color);

	&.btn-border {
		@include button-border($second-color, $second-color);
	}
}

.btn.btn-red {
	@include button(#fff, #f41d1d);

	&.btn-border {
		@include button-border(#f41d1d, #f41d1d);
	}
}

.btn.btn-darkred {
	@include button(#fff, #d10707);

	&.btn-border {
		@include button-border(#d10707, #d10707);
	}
}

.btn.btn-blue {
	@include button(#fff, #0172b8);

	&.btn-border {
		@include button-border(#0172b8, #0172b8);
	}
}

.btn.btn-lightblue {
	@include button(#fff, #2599e2);

	&.btn-border {
		@include button-border(#2599e2, #2599e2);
	}
}

.btn.btn-green {
	@include button(#fff, #49ba17);

	&.btn-border {
		@include button-border(#49ba17, #49ba17);
	}
}

.btn.btn-green2 {
	@include button(#fff, #06b0aa);

	&.btn-border {
		@include button-border(#06b0aa, #06b0aa);
	}
}

.btn.btn-lightgreen {
	@include button(#fff, #7aed47);

	&.btn-border {
		@include button-border(#7aed47, #7aed47);
	}
}

.btn.btn-yellow {
	@include button(#fff, #f4b907);

	&.btn-border {
		@include button-border(#f4b907, #f4b907);
	}
}

.btn.btn-orange {
	@include button(#fff, #f08903);

	&.btn-border {
		@include button-border(#f08903, #f08903);
	}
}

.btn.btn-purple {
	@include button(#fff, #f003d7);

	&.btn-border {
		@include button-border(#f003d7, #f003d7);
	}
}


.btn.btn-darkpurple {
	@include button(#fff, #b0069e);

	&.btn-border {
		@include button-border(#b0069e, #b0069e);
	}
}

.btn.btn-black {
	@include button(#fff, #181818);

	&.btn-border {
		@include button-border(#181818, #181818);
	}
}

.btn.btn-gray {
	@include button(#333, #e2e2e2);
}

.btn.btn-white {
	@include button(#222, #fff);

	&.btn-border {
		color: #fff;
	    background-color: transparent;
	    border-color: #fff;

	    &:hover,
	    &:focus {
	        color: #fff;
	        background-color: $first-color;
	        border-color:  $first-color
	    }
	}
}

/* Added elements page for margin */
.elements-page-btn {
	 .btn  {
		margin-bottom:4px;
	}

	.btn-group {
		.btn {
			margin-bottom: 0;
		}
	}
}