/* Homepages - Index Photography */
.fotorama__thumb-border {
	border-color: $first-color;
}

/* Fotorama Navigation Arrows */
.fotorama__arr {
	font-family: 'FontAwesome';
	background-image: none;
	font-size:18px;
	color:#fff;
	background-color: rgba(0,0,0, 0.3);
	text-align: center;
	width: 34px;
	height:36px;
	line-height:36px;
	margin-top: -18px;
	transition: opacity 0.4s;

	@media (min-width: $minmqsm) {
		font-size:22px;
		width: 40px;
		height:42px;
		line-height:42px;
		margin-top: -21px;
	}

	@media (min-width: $minmqmd) {
		font-size:26px;
		width: 48px;
		height:52px;
		line-height:52px;
		margin-top: -26px;
	}

	&:hover,
	&:focus {
		background-color: rgba(0,0,0, 0.6);
	}
}

.fotorama__arr--prev {
	left:0;
	&:before {
		content: "\f104";
	}
}

.fotorama__arr--next {
	right:0;
	&:before {
		content: "\f105";
	}
}

/* Thumbnail over */
.fotorama-nav-over {
	.fotorama__nav-wrap {
		position: absolute;
		left:0;
		bottom:20px;
		z-index:99;
	}
}

/* Circle Thumbnails */
.fotorama-circle-thumbs {
	.fotorama__thumb,
	.fotorama__thumb-border {
		&,
		& img {
			border-radius: 50%;
		}
	}
}

/* Caption */
.fotorama__caption {
	left:40px;
	right:40px;
	font:300 26px/1 $font-family;
	bottom:auto;
	top: 50%;
	transform: translateY(-50%);
	text-align: center;
	text-shadow: 1px 0 3px rgba(0,0,0, 0.2);
	text-transform: uppercase;

	@media (min-width: $minmqsm) {
		font-size:34px;
		left:50px;
		right:50px;
	}

	@media (min-width: $minmqmd) {
		font-size:42px;
		left:60px;
		right:60px;
	}

	.fotorama__caption__wrap {
		background-color: transparent;
		color: #fff;
		background-color: rgba(0,0,0, 0.56);
		padding: 0.3em 0.75em;
	}
}