body {
	position: relative;
	direction: ltr;
	color: $body-text-color;
	font: 400 #{$body-font-size}/#{$body-line-height} $font-family;
	background-color: $body-bg-color;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	overflow-x:hidden;

	&.edge {
		padding:5px;

		@media (min-width: $minmqsm) {
			padding: 10px;
		}

		@media (min-width: $minmqmd) {
			padding: 15px;
		}

		&.bg-white {
			#wrapper {
				background-color: #f2f2fa;
			}
		}
	}
}

#wrapper {
	position: relative;
	background-color: $wrapper-bg-color;
	
	&,
	&.boxed,
	&.boxed-long {
		width:100%;
	}
	
	&.boxed {
		@media (min-width: $minmqsm) {
			margin:30px auto;
			width:750px;
		}

		@media (min-width: $minmqmd) {
			width:970px;
		}

		@media (min-width: $minmqlg) {
			margin:50px auto;
			width:1170px;
		}
	}

	&.boxed-long {
		@media (min-width: $minmqsm) {
			margin:0 auto;
			width:750px;
		}
		
		@media (min-width: $minmqmd) {
			width:970px;
		}

		@media (min-width: $minmqlg) {
			width:1170px;
		}
	}
}

::selection {
    background-color: $first-color;
    color: #fff;
}

// Clear row and paddings 
.fullwidth-container.container,
.fullwidth-container.container-fluid {
	padding-left:0;
	padding-right:0;

	.row {
		margin-left:0;
		margin-right:0;

		[class*="col-"] {
			padding-left:0;
			padding-right:0;
		}
	}
}

// Smaller column space 
.row.row-sm {
	margin-left:-10px;
	margin-right:-10px;

	[class*="col-"] {
		padding-left:10px;
		padding-right:10px;
	}
}

.fullwidth-row {
	margin-left:0;
	margin-right:0;

	[class*="col-"] {
		padding-left:0;
		padding-right:0;
	}
}

ul,
ol {
	list-style:none;
	padding:0;
	margin:0;
}

a,
select,
input,
textarea,
button {
	&:focus,
	&:active {
		outline: none;
	}
}

.col-xss-12 {
	@media (max-width: $maxmqxs) { 
		width:100%;
		float:none;
	}
}

hr {
  margin-top: 20px;
  margin-bottom: 20px;
  border: 0;
  border-top: 1px solid #e6e6e6;
}

.fullscreen {
	min-height: 100vh;
}

.fullheight {
	height: 100vh;
}

.fullscreen,
.fullheight {
	&.vertical-center {
		position: relative;
		overflow: hidden;
		
		.vcenter-content {
			position: absolute;
			top:50%;
			left: 0;
			right:0;
			transform: translateY(-50%);
			z-index:99;

			h1 {
				font-weight:700;
				font-size:34px;
				margin-bottom:18px;
				text-shadow: 0 2px 5px rgba(0,0,0, 0.2);

				@media (min-width: $minmqxs) {
					font-size:42px;
					margin-bottom:7px;
				}

				@media (min-width: $minmqsm) {
					font-size:52px;
					margin-bottom:9px;
				}

				@media (min-width: $minmqmd) {
					font-size:60px;
					margin-bottom:12px;
				}

				@media (min-width: $minmqlg) {
					font-size:68px;
				}
			}

			p {
				max-width: 720px;
				margin-bottom:12px;
				font-size:13px;
				display:none;
				text-shadow: 0 2px 5px rgba(0,0,0, 0.2);

				@media (min-width: $minmqxs) {
					display:block;
					font-size:14px;
					margin-bottom:15px;
				}

				@media (min-width: $minmqsm) {
					font-size:16px;
					margin-bottom:20px;
				}

				@media (min-width: $minmqmd) {
					margin-bottom:25px;
					font-size:18px;
				}
			}

			&.text-center p {
				margin-left: auto;
				margin-right: auto;
			}
		}
	}
}

.overflow-hidden {
	overflow: hidden;
}

// Helper Background Classes
.bg-custom {
	background-color: $first-color !important;
	color:#fff;

	&.border {
		border-top:1px solid darken($first-color,15%);
		border-bottom:1px solid darken($first-color,15%);
	}

	&.bordered {
		border:1px solid darken($first-color,15%);
	}
}

.bg-custom2 {
	background-color: $second-color !important;
	color:#fff;

	&.border {
		border-top:1px solid darken($second-color,22%);
		border-bottom:1px solid darken($second-color,22%);
	}

	&.bordered {
		border:1px solid darken($second-color,22%);
	}
}

.bg-dark {
	background-color: $dark-bg !important;
	color:#fff;

	&.text-muted {
		color: #777;
	}

	&.border {
		border-top:1px solid lighten($dark-bg, 8%);
		border-bottom:1px solid  lighten($dark-bg, 8%);
	}

	&.bordered {
		border:1px solid  lighten($dark-bg, 8%);
	}

	hr {
		border-top-color: lighten($dark-bg, 12%);
	}
}

.bg-darker {
	background-color: $darker-bg !important;
	color:#fff;

	&.border {
		border-top:1px solid lighten($darker-bg, 12%);
		border-bottom:1px solid lighten($darker-bg, 12%);
	}

	&.bordered {
		border:1px solid lighten($darker-bg, 12%);
	}

	hr {
		border-top-color: lighten($darker-bg, 12%);
	}
}

.bg-lightgray {
	background-color: $lightgray !important;
	&.border {
		border-top:1px solid darken($lightgray, 5%);
		border-bottom:1px solid darken($lightgray, 5%);
	}

	&.bordered {
		border:1px solid darken($lightgray, 5%);
	}
}

.bg-gray {
	background-color: $gray !important;
	&.border {
		border-top:1px solid darken($gray, 5%);
		border-bottom:1px solid darken($gray, 5%);
	}

	&.bordered {
		border:1px solid darken($gray, 5%);
	}

	hr {
		border-top-color: darken($gray, 10%);
	}
}

.bg-gray2 {
	background-color: $gray2 !important;
	&.border {
		border-top:1px solid darken($gray2, 6%);
		border-bottom:1px solid darken($gray2, 6%);
	}

	&.bordered {
		border:1px solid darken($gray2, 6%);
	}

	hr {
		border-top-color: darken($gray2, 10%);
	}
}

.bg-white {
	background-color: #fff;
	&.border {
		border-top:1px solid $gray;
		border-bottom: 1px solid $gray;
	}

	&.bordered {
		border:1px solid $gray;
	}
}

.bg-image,
.parallax {
	position: relative;
	background-size:cover;
	background-position: center center;
	background-repeat: no-repeat;
}

.parallax {
	background-attachment: fixed;
}

.overlay-container {
	position: relative;
	
	> .container,
	> .container-fluid {
		position: relative;
		z-index:2;
	}

	.overlay {
		position: absolute;
		left:0;
		right:0;
		top:0;
		bottom:0;
		opacity: 0.4;
		background-color: #000;
		z-index:0;

		&.dark {
			background-color: #000;	
		}

		&.custom {
			background-color: $first-color;
		}

		&.custom2 {
			background-color: $second-color;
		}

		&.light {
			background-color: #fff;
		}
	}
}

.table-row {
	+ .table-row:not(.no-border) {
		border-top:1px solid #eee;
	}

	@media (min-width: $minmqmd) {
		display: table;
		table-layout: fixed;
		width: 100%;
		border:none;
	}

	.table-cell {
		padding: 40px 15px 10px;
		@media (min-width: $minmqmd) {
			display: table-cell;
			vertical-align: middle;
			width: 50%;
			padding: 45px 25px 15px;

			&.smaller {
				width: 25%;
			}

			&.larger {
				width: 75%;
				padding: 60px 45px 30px;
			}
		}

		@media (min-width: $minmqlg) {
			padding: 55px 50px 25px;
		}
		
		&.cell-content {
			.row {
				@media (min-width: $minmqmd) {
					max-width: 570px;
					float:right;

					.row {
						float:none;
						max-width: none;
					}
				}
			}

			&.larger {
				.row {
					@media (min-width: $minmqmd) {
						max-width: none;
						float:none;
					}
				}

				.table-cell-wrapper {
					@media (min-width: $minmqmd) {
						float:right;
						max-width: 860px;
					}

					@media (min-width: $minmqxlg) {
						max-width: 75%;
					}
				}
			}

			&.center {
				.row {
					@media (min-width: $minmqmd) {
						max-width:80%;
						float:none;
						margin-left: auto;
						margin-right: auto;
					}
				}
			}
		}

		&.cell-image {
			background-size: cover;
			background-position: center center;
			background-repeat: no-repeat;
			padding-top: 100px;
			padding-bottom:100px;
			text-align:center;

			h2,h3,h4,h5,h6,p {
				position: relative;
				z-index:2;
				margin:0;
			}

			&.empty-cell {
				min-height:240px;
			}

			@media (min-width: $minmqmd) {
				padding-top: 0;
				padding-bottom:0;
			}

			+ .cell-content {
				.row {
					@media (min-width: $minmqmd) {
						float:none;
					}
				}

				&.larger {
					.table-cell-wrapper {
						@media (min-width: $minmqmd) {
							float:none;
						}
					}
				}
			}
		}

		&.cell-map-container {
			padding:0;
			position: relative;

			@media (max-width: $maxmqmd) {
				height:360px;
			}

			@media (max-width: $maxmqsm) {
				height:260px;
			}

			.cell-map {
				position: absolute !important;
				left:0;
				right:0;
				top:0;
				bottom:0;
				height: 100% !important;
				margin:0 !important;
				z-index:99;
				
				// fix for ie otherwise map won't show up
				@media (min-width: $minmqmd) {
					min-height: 598px;
				}

				@media (min-width: $minmqlg) {
					min-height: 618px;
				}
			}

			+ .cell-content {
				.row {
					@media (min-width: $minmqmd) {
						float:none;
					}
				}

				&.larger {
					.table-cell-wrapper {
						@media (min-width: $minmqmd) {
							float:none;
						}
					}
				}
			}
		}
	}
}

// video popup/lightbox button
.trigger-video-btn {
	position: relative;
	z-index:10;
	display:inline-block;
	width:72px;
	height:72px;
	font-size:28px;
	line-height:72px;
	color: #fff;
	background-color: rgba(0,0,0, 0.35);
	border-radius: 50%;
	box-shadow: 0 0 0 4px rgba(255, 255, 255, 0.46), 0 4px 3px rgba(0,0,0, 0.4);
	transition: all 0.4s;

	&:hover,
	&:focus {
		color: #fff;
		@include background-opacity($first-color, $opacity: 0.65);
		box-shadow: 0 0 0 6px rgba(255, 255, 255, 0.64), 0 4px 3px rgba(0,0,0, 0.7);
	}
}
.col-xs-20 {
        width: 50%;
        float: left;
        border:1px solid white;
        	img{

        	}
}

.col-sm-20 {
        width: 50%;
        float: left;
        border:1px solid white;
}



.col-md-20 {
        width: 20%;
        float: left;
        border:1px solid white;
}

.col-lg-20 {
        width: 20%;
        float: left;
        border:1px solid white;
}
.shadow{
	-webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
-moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
}