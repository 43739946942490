/* Homepages - Index7 */
.slider-container-index7 {
	background-color: #e1e1e1;

	@media (min-width: $minmqlg) {
		height: 600px;
	}

	.tp-caption {
		strong {
			font-weight: 700 !important;
		}
	}
}

#portfolio-filter.vertical-filter {
	margin-bottom:30px;
	@media (min-width: $minmqmd) {
		border:none;

		> li {
			float:none;
			display:block;
			text-align:left;
			margin:6px 0;

			> a {
				display:block;
				padding:0 0 0 10px;
			}

			&:before {
				left: auto;
				right: auto;
				top:50%;
				bottom:50%;
				width:2px;
				height: auto;
			}

			&.active {
				&:before {
					top:0;
					bottom:0;
				}
			}
		}
	}
}

.video-box-section-wrapper {
	max-width:630px;
	border:12px solid rgba(255, 255, 255, 0.4);
	margin: 0 auto;
}