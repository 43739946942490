/* Homepages - Index8 */
.slider-container8 {
	background-color: #e1e1e1;

	@media (min-width: $minmqlg) {
		height: 600px;
	}
}

.contact-info-area {
	text-align: center;
	margin-bottom: 25px;

	i {
		width:46px;
		height:46px;
		font-size: 18px;
		line-height: 46px;
		border-radius:50%;
		text-align:center;
		background-color: $first-color;
		color: #fff;
		margin-bottom: 12px;
	}

	strong {
		color: #555;
	}

	address {
		line-height:1.6;
	}

	address,
	p {
		line-height:1.6;
		margin-bottom:3px;
	}
}