/* Page Loader Animation */
#page-loader {
	position: fixed;
	left:0;
	right:0;
	top:0;
	bottom:0;
	z-index: 9999;
	background-color: #f6f6fa;
	text-align: center;
	overflow: hidden;

	&.dark {
		background-color: $dark;
	}

	&.white {
		background-color: #fff;
	}
}

.sk-wave {
	$rectCount: 5;
	$animationDuration: 1.2s;
	$delayRange: 0.4s;
	$spinkit-size: 50px !default;

	width: $spinkit-size * 1.25;
	height: $spinkit-size;
	text-align: center;
	font-size: 10px;
	position: absolute;
	top:50%;
	left:50%;
	margin-left: -($spinkit-size * 1.25) / 2;
	margin-top: -$spinkit-size / 2;

	.sk-rect {
		background-color:$first-color;
		height: 100%;
		width: 4px;
		display: inline-block;
		animation: sk-waveStretchDelay $animationDuration infinite ease-in-out;
	}

	@for $i from 1 through $rectCount {
		.sk-rect#{$i} { animation-delay: - $animationDuration + $delayRange / ($rectCount - 1) * ($i - 1); }
	}
}

@keyframes sk-waveStretchDelay {
	0%, 40%, 100% { transform: scaleY(0.4) }
	20% { transform: scaleY(1.0) }
}
/* Loader Animation End */