/* Homepages - Index 4*/
.page-header.header-top-page {
	padding:165px 0 0;

	@media (min-width: $minmqsm) {
		padding-top: 235px;
	}

	@media (min-width: $minmqmd) {
		padding-top: 200px;
	}

	@media (min-width: $minmqlg) {
		
	}
}

.typed-header {
	color:#fff;
	font-size:14px;
	font-weight: 400;
	line-height: 1.3;

	@media (min-width: $minmqxs) {
		font-size:18px;
	}

	@media (min-width: $minmqsm) {
		font-size:22px;
	}

	@media (min-width: $minmqmd) {
		font-size:26px;
	}

	@media (min-width: $minmqlg) {
		font-size:30px;
	}
}