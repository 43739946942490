#portfolio-filter {
	border-top:1px solid #eaeaea;
	border-bottom:1px solid #eaeaea;
	margin:0 0 60px;
	font-size:0;

	.bg-gray & {
		border-color: darken($gray, 6%);
	}

	li {
		position: relative;
		display:inline-block;

		&:before {
			content:'';
			position: absolute;
			top:-1px;
			left:50%;
			right:50%;
			display:block;
			height:2px;
			background-color: $first-color;
			transition: all 0.35s;
		}

		a {
			display:inline-block;
			color: #323232;
			font-size: 11px;
			font-weight:600;
			text-transform: uppercase;
			padding:10px;
			transition: all 0.4s;

			@media (min-width: $minmqsm) {
				font-size:12px;
				padding:15px 10px;
			}

			@media (min-width: $minmqmd) {
				font-size:13px;
			}


			&:hover,
			&:focus {
				color:$first-color;
			}
		}

		&.active {
			&:before {
				left:0;
				right:0;
			}

			a {
				color: $first-color;
			}
		}
	}
}

#portfolio-item-container {
	transition: all 0.45s;
	margin-bottom: 70px;

	&:after {
		content: '';
		display: table;
		clear: both;
	}
}

.portfolio-item {
	position: relative;
	float: left;
	margin-bottom:30px;
	width:100%;
	
	a {
		color:#222;

		&:hover,
		&:focus {
			color: $first-color;
		}
	}

	figure {
		position: relative;
		img {
			display: block;
			width: 100%;
			height: auto;
		}

		&:after {
			content:'';
			position: absolute;
			left:0;
			right:0;
			top:0;
			bottom:0;
			background-color: #101010;
			opacity: 0;
			visibility:hidden;
			transition: all 0.5s;
		}
	}

	.zoom-btn,
	.link-btn {
		display:inline-block;
		position: absolute;
		top:50%;
		margin-top:-19px;
		width:38px;
		height:38px;
		font-size:15px;
		line-height:38px;
		text-align:center;
		color: #fff;
		border-radius: 50%;
		opacity: 0;
		visibility:hidden;
		transition: all 0.45s;
	}

	.zoom-btn {
		@include background-opacity($first-color, 0.6);
		transform: translateX(-25px);
		left:50%;
		margin-left:-36px;
		z-index: 100;

		&:hover,
		&:focus {
			color: #fff;
			@include background-opacity($first-color, 0.88);
		}
	}

	.link-btn {
		@include background-opacity(#101010, 0.6);
		transform: translateX(25px);
		right:50%;
		margin-right:-36px;
		z-index:99;

		&:hover,
		&:focus {
			color: #fff;
			@include background-opacity(#101010, 0.88);
			z-index:101;
		}
	}

	.portfolio-meta {
		position: relative;
		margin-top:14px;
	}

	.portfolio-title {
		color: #222;
		font-size: 14px;
		line-height:1.25;
		font-weight:400;
		margin:0 0 3px;
	}

	.portfolio-tags {
		font-size: 12px;
		
		a {
			color: #9e9e9e;

			&:hover,
			&:focus {
				color: $first-color;
			}
		}
	}

	&:hover {
		figure {
			&:after {
				visibility: visible;
				opacity:0.5;
			}
		}

		.zoom-btn,
		.link-btn  {
			visibility: visible;
			opacity:1;
			transform: translateX(0);
		}
	}
	
	/* Portfolio No gap */
	&.portfolio-anim {
		margin-bottom:0;
		background-color: $first-color;

		figure {
			position: relative;
			z-index:1;
			transition: all 0.5s;
		}

		.portfolio-meta {
			position: absolute;
			left:0;
			right:0;
			bottom:0;
			color: #fff;
			margin:0;
			z-index:0;
			padding:14px 15px 13px;
		}

		.portfolio-title {
			a {
				color: #eee;

				&:hover,
				&:focus {
					color:  #fff;
				}
			}
		}

		.portfolio-tags {
			color: #b7b7b7;
			a {
				color: lighten($first-color, 15%);

				&:hover,
				&:focus {
					color:  #fff;
				}
			}
		}

		&:hover {
			figure {
				transform: translateY(-72px);
			}
		}
	}
}

.portfolio-row {
	margin-left: -15px;
	margin-right: -15px;

	&:after {
		content: '';
		display: table;
		clear: both;
	}
}

.row,
.portfolio-row {
	> #portfolio-item-container {
		margin-bottom:40px;

		> .portfolio-item {
			padding:0 15px;
		}
	}
}

.max-col-6,
.max-col-5,
.max-col-4,
.max-col-3,
.max-col-2 {
	.portfolio-item {
		@media (min-width: $minmqsm) {
			width:50%;
		}
	}
}

.max-col-4,
.max-col-3 {
	.portfolio-item {
		@media (min-width: $minmqmd) {
			width:33.3%;
		}
	}
}

.max-col-6,
.max-col-5{
	.portfolio-item {
		@media (min-width: $minmqmd) {
			width:25%;
		}
	}
}

.max-col-4 {
	.portfolio-item {
		@media (min-width: $minmqlg) {
			width:25%;
			&.wide {
				width:50%;
			}
		}
	}
}

.max-col-5 {
	.portfolio-item {
		@media (min-width: $minmqlg) {
			width:20%;
			&.wide {
				width:40%;
			}
		}
	}
}

.max-col-6 {
	.portfolio-item {
		@media (min-width: $minmqlg) {
			width:16.66%;
			&.wide {
				width:33.3%;
			}
		}
	}
}

/* Light gallery lightbox custom css */
.lg-outer {
	.lg-thumb {
		padding: 8px 0;
	}
	.lg-thumb-item {
		border-radius: 0;
	}
}


/* Single Portfolio */
.portfolio-post-media {
	margin-bottom:40px;
	img {
		display:block;
		width:100%;
		height:auto;
	}
}

.portfolio-post-content {
	margin-bottom:40px;

	.portfolio-title {
		font-size:20px;
		line-height:1.2;
	}
	ul {
		padding: 13px 15px 10px;
		background-color: #f6f6fa;
		li {
			padding:5px 0;
			border-bottom:1px solid #eee;

			> span {
				display: inline-block;
				font-weight:600;
				color: #222;
				margin-right:5px;
				min-width: 70px;
			}

			i {
				margin-right:5px;
			}

			&:last-child {
				border-bottom:none;
			}
		}
	}
}

.portfolio-related-carousel {
	.portfolio-item {
		margin-bottom:0;
	}
}