@charset "UTF-8";
/* 
Template Name: Simple - Multipurpose Premium Html5 Template
Template URI: http://www.eonythemes.com
Description: Multipurpose Html5 Template
Author: Eon

-------- # Table Of Contents # -------- //
-------- 1-  General
-------- 2-  Typography
-------- 3-  Elements
-------- 4-  Pricing
-------- 5-  Headers
-------- 6-  Homepages
-------- 7-  About
-------- 8-  Services Pages
-------- 9-  Blog
-------- 10- Portfolio
-------- 11- Category(Shop) - Product
-------- 12- Cart
-------- 13- Compare
-------- 14- Checkout
-------- 15- Contact
-------- 16- 404
-------- 17- FaQs
-------- 18- Footers
-------- 19- Owl Carousel
-------- 20- Coming Soon
-------- # Table Of Contents # ------- //
*/
/* Page Loader Animation */
#page-loader {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 9999;
  background-color: #f6f6fa;
  text-align: center;
  overflow: hidden;
}

#page-loader.dark {
  background-color: #171717;
}

#page-loader.white {
  background-color: #fff;
}

.sk-wave {
  width: 62.5px;
  height: 50px;
  text-align: center;
  font-size: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -31.25px;
  margin-top: -25px;
}

.sk-wave .sk-rect {
  background-color: #0069b0;
  height: 100%;
  width: 4px;
  display: inline-block;
  animation: sk-waveStretchDelay 1.2s infinite ease-in-out;
}

.sk-wave .sk-rect1 {
  animation-delay: -1.2s;
}

.sk-wave .sk-rect2 {
  animation-delay: -1.1s;
}

.sk-wave .sk-rect3 {
  animation-delay: -1s;
}

.sk-wave .sk-rect4 {
  animation-delay: -0.9s;
}

.sk-wave .sk-rect5 {
  animation-delay: -0.8s;
}

@keyframes sk-waveStretchDelay {
  0%, 40%, 100% {
    transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1);
  }
}

/* Loader Animation End */
/* ----------- 1.General --------------
--------------------------------------*/
body {
  position: relative;
  direction: ltr;
  color: #323232;
  font: 400 13px/1.7 "Open Sans", Arial, sans-serif;
  background-color: #9a9a9a;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

body.edge {
  padding: 5px;
}

@media (min-width: 768px) {
  body.edge {
    padding: 10px;
  }
}

@media (min-width: 992px) {
  body.edge {
    padding: 15px;
  }
}

body.edge.bg-white #wrapper {
  background-color: #f2f2fa;
}

#wrapper {
  position: relative;
  background-color: #fff;
}

#wrapper, #wrapper.boxed, #wrapper.boxed-long {
  width: 100%;
}

@media (min-width: 768px) {
  #wrapper.boxed {
    margin: 30px auto;
    width: 750px;
  }
}

@media (min-width: 992px) {
  #wrapper.boxed {
    width: 970px;
  }
}

@media (min-width: 1200px) {
  #wrapper.boxed {
    margin: 50px auto;
    width: 1170px;
  }
}

@media (min-width: 768px) {
  #wrapper.boxed-long {
    margin: 0 auto;
    width: 750px;
  }
}

@media (min-width: 992px) {
  #wrapper.boxed-long {
    width: 970px;
  }
}

@media (min-width: 1200px) {
  #wrapper.boxed-long {
    width: 1170px;
  }
}

::selection {
  background-color: #0069b0;
  color: #fff;
}

.fullwidth-container.container,
.fullwidth-container.container-fluid {
  padding-left: 0;
  padding-right: 0;
}

.fullwidth-container.container .row,
.fullwidth-container.container-fluid .row {
  margin-left: 0;
  margin-right: 0;
}

.fullwidth-container.container .row [class*="col-"],
.fullwidth-container.container-fluid .row [class*="col-"] {
  padding-left: 0;
  padding-right: 0;
}

.row.row-sm {
  margin-left: -10px;
  margin-right: -10px;
}

.row.row-sm [class*="col-"] {
  padding-left: 10px;
  padding-right: 10px;
}

.fullwidth-row {
  margin-left: 0;
  margin-right: 0;
}

.fullwidth-row [class*="col-"] {
  padding-left: 0;
  padding-right: 0;
}

ul,
ol {
  list-style: none;
  padding: 0;
  margin: 0;
}

a:focus, a:active,
select:focus,
select:active,
input:focus,
input:active,
textarea:focus,
textarea:active,
button:focus,
button:active {
  outline: none;
}

@media (max-width: 480px) {
  .col-xss-12 {
    width: 100%;
    float: none;
  }
}

hr {
  margin-top: 20px;
  margin-bottom: 20px;
  border: 0;
  border-top: 1px solid #e6e6e6;
}

.fullscreen {
  min-height: 100vh;
}

.fullheight {
  height: 100vh;
}

.fullscreen.vertical-center,
.fullheight.vertical-center {
  position: relative;
  overflow: hidden;
}

.fullscreen.vertical-center .vcenter-content,
.fullheight.vertical-center .vcenter-content {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  z-index: 99;
}

.fullscreen.vertical-center .vcenter-content h1,
.fullheight.vertical-center .vcenter-content h1 {
  font-weight: 700;
  font-size: 34px;
  margin-bottom: 18px;
  text-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

@media (min-width: 481px) {
  .fullscreen.vertical-center .vcenter-content h1,
  .fullheight.vertical-center .vcenter-content h1 {
    font-size: 42px;
    margin-bottom: 7px;
  }
}

@media (min-width: 768px) {
  .fullscreen.vertical-center .vcenter-content h1,
  .fullheight.vertical-center .vcenter-content h1 {
    font-size: 52px;
    margin-bottom: 9px;
  }
}

@media (min-width: 992px) {
  .fullscreen.vertical-center .vcenter-content h1,
  .fullheight.vertical-center .vcenter-content h1 {
    font-size: 60px;
    margin-bottom: 12px;
  }
}

@media (min-width: 1200px) {
  .fullscreen.vertical-center .vcenter-content h1,
  .fullheight.vertical-center .vcenter-content h1 {
    font-size: 68px;
  }
}

.fullscreen.vertical-center .vcenter-content p,
.fullheight.vertical-center .vcenter-content p {
  max-width: 720px;
  margin-bottom: 12px;
  font-size: 13px;
  display: none;
  text-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

@media (min-width: 481px) {
  .fullscreen.vertical-center .vcenter-content p,
  .fullheight.vertical-center .vcenter-content p {
    display: block;
    font-size: 14px;
    margin-bottom: 15px;
  }
}

@media (min-width: 768px) {
  .fullscreen.vertical-center .vcenter-content p,
  .fullheight.vertical-center .vcenter-content p {
    font-size: 16px;
    margin-bottom: 20px;
  }
}

@media (min-width: 992px) {
  .fullscreen.vertical-center .vcenter-content p,
  .fullheight.vertical-center .vcenter-content p {
    margin-bottom: 25px;
    font-size: 18px;
  }
}

.fullscreen.vertical-center .vcenter-content.text-center p,
.fullheight.vertical-center .vcenter-content.text-center p {
  margin-left: auto;
  margin-right: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.bg-custom {
  background-color: #0069b0 !important;
  color: #fff;
}

.bg-custom.border {
  border-top: 1px solid #003b64;
  border-bottom: 1px solid #003b64;
}

.bg-custom.bordered {
  border: 1px solid #003b64;
}

.bg-custom2 {
  background-color: #c00f3c !important;
  color: #fff;
}

.bg-custom2.border {
  border-top: 1px solid #58071b;
  border-bottom: 1px solid #58071b;
}

.bg-custom2.bordered {
  border: 1px solid #58071b;
}

.bg-dark {
  background-color: #171717 !important;
  color: #fff;
}

.bg-dark.text-muted {
  color: #777;
}

.bg-dark.border {
  border-top: 1px solid #2b2b2b;
  border-bottom: 1px solid #2b2b2b;
}

.bg-dark.bordered {
  border: 1px solid #2b2b2b;
}

.bg-dark hr {
  border-top-color: #363636;
}

.bg-darker {
  background-color: #101010 !important;
  color: #fff;
}

.bg-darker.border {
  border-top: 1px solid #2f2f2f;
  border-bottom: 1px solid #2f2f2f;
}

.bg-darker.bordered {
  border: 1px solid #2f2f2f;
}

.bg-darker hr {
  border-top-color: #2f2f2f;
}

.bg-lightgray {
  background-color: #f8f8f8 !important;
}

.bg-lightgray.border {
  border-top: 1px solid #ebebeb;
  border-bottom: 1px solid #ebebeb;
}

.bg-lightgray.bordered {
  border: 1px solid #ebebeb;
}

.bg-gray {
  background-color: #f0f0f0 !important;
}

.bg-gray.border {
  border-top: 1px solid #e3e3e3;
  border-bottom: 1px solid #e3e3e3;
}

.bg-gray.bordered {
  border: 1px solid #e3e3e3;
}

.bg-gray hr {
  border-top-color: #d7d7d7;
}

.bg-gray2 {
  background-color: #e1e1e1 !important;
}

.bg-gray2.border {
  border-top: 1px solid #d2d2d2;
  border-bottom: 1px solid #d2d2d2;
}

.bg-gray2.bordered {
  border: 1px solid #d2d2d2;
}

.bg-gray2 hr {
  border-top-color: #c8c8c8;
}

.bg-white {
  background-color: #fff;
}

.bg-white.border {
  border-top: 1px solid #f0f0f0;
  border-bottom: 1px solid #f0f0f0;
}

.bg-white.bordered {
  border: 1px solid #f0f0f0;
}

.bg-image,
.parallax {
  position: relative;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.parallax {
  background-attachment: fixed;
}

.overlay-container {
  position: relative;
}

.overlay-container > .container,
.overlay-container > .container-fluid {
  position: relative;
  z-index: 2;
}

.overlay-container .overlay {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 0.4;
  background-color: #000;
  z-index: 0;
}

.overlay-container .overlay.dark {
  background-color: #000;
}

.overlay-container .overlay.custom {
  background-color: #0069b0;
}

.overlay-container .overlay.custom2 {
  background-color: #c00f3c;
}

.overlay-container .overlay.light {
  background-color: #fff;
}

.table-row + .table-row:not(.no-border) {
  border-top: 1px solid #eee;
}

@media (min-width: 992px) {
  .table-row {
    display: table;
    table-layout: fixed;
    width: 100%;
    border: none;
  }
}

.table-row .table-cell {
  padding: 40px 15px 10px;
}

@media (min-width: 992px) {
  .table-row .table-cell {
    display: table-cell;
    vertical-align: middle;
    width: 50%;
    padding: 45px 25px 15px;
  }
  .table-row .table-cell.smaller {
    width: 25%;
  }
  .table-row .table-cell.larger {
    width: 75%;
    padding: 60px 45px 30px;
  }
}

@media (min-width: 1200px) {
  .table-row .table-cell {
    padding: 55px 50px 25px;
  }
}

@media (min-width: 992px) {
  .table-row .table-cell.cell-content .row {
    max-width: 570px;
    float: right;
  }
  .table-row .table-cell.cell-content .row .row {
    float: none;
    max-width: none;
  }
}

@media (min-width: 992px) {
  .table-row .table-cell.cell-content.larger .row {
    max-width: none;
    float: none;
  }
}

@media (min-width: 992px) {
  .table-row .table-cell.cell-content.larger .table-cell-wrapper {
    float: right;
    max-width: 860px;
  }
}

@media (min-width: 1600px) {
  .table-row .table-cell.cell-content.larger .table-cell-wrapper {
    max-width: 75%;
  }
}

@media (min-width: 992px) {
  .table-row .table-cell.cell-content.center .row {
    max-width: 80%;
    float: none;
    margin-left: auto;
    margin-right: auto;
  }
}

.table-row .table-cell.cell-image {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  padding-top: 100px;
  padding-bottom: 100px;
  text-align: center;
}

.table-row .table-cell.cell-image h2, .table-row .table-cell.cell-image h3, .table-row .table-cell.cell-image h4, .table-row .table-cell.cell-image h5, .table-row .table-cell.cell-image h6, .table-row .table-cell.cell-image p {
  position: relative;
  z-index: 2;
  margin: 0;
}

.table-row .table-cell.cell-image.empty-cell {
  min-height: 240px;
}

@media (min-width: 992px) {
  .table-row .table-cell.cell-image {
    padding-top: 0;
    padding-bottom: 0;
  }
}

@media (min-width: 992px) {
  .table-row .table-cell.cell-image + .cell-content .row {
    float: none;
  }
}

@media (min-width: 992px) {
  .table-row .table-cell.cell-image + .cell-content.larger .table-cell-wrapper {
    float: none;
  }
}

.table-row .table-cell.cell-map-container {
  padding: 0;
  position: relative;
}

@media (max-width: 991px) {
  .table-row .table-cell.cell-map-container {
    height: 360px;
  }
}

@media (max-width: 767px) {
  .table-row .table-cell.cell-map-container {
    height: 260px;
  }
}

.table-row .table-cell.cell-map-container .cell-map {
  position: absolute !important;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: 100% !important;
  margin: 0 !important;
  z-index: 99;
}

@media (min-width: 992px) {
  .table-row .table-cell.cell-map-container .cell-map {
    min-height: 598px;
  }
}

@media (min-width: 1200px) {
  .table-row .table-cell.cell-map-container .cell-map {
    min-height: 618px;
  }
}

@media (min-width: 992px) {
  .table-row .table-cell.cell-map-container + .cell-content .row {
    float: none;
  }
}

@media (min-width: 992px) {
  .table-row .table-cell.cell-map-container + .cell-content.larger .table-cell-wrapper {
    float: none;
  }
}

.trigger-video-btn {
  position: relative;
  z-index: 10;
  display: inline-block;
  width: 72px;
  height: 72px;
  font-size: 28px;
  line-height: 72px;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.35);
  border-radius: 50%;
  box-shadow: 0 0 0 4px rgba(255, 255, 255, 0.46), 0 4px 3px rgba(0, 0, 0, 0.4);
  transition: all 0.4s;
}

.trigger-video-btn:hover, .trigger-video-btn:focus {
  color: #fff;
  background-color: rgba(0, 105, 176, 0.65);
  box-shadow: 0 0 0 6px rgba(255, 255, 255, 0.64), 0 4px 3px rgba(0, 0, 0, 0.7);
}

.col-xs-20 {
  width: 50%;
  float: left;
  border: 1px solid white;
}

.col-sm-20 {
  width: 50%;
  float: left;
  border: 1px solid white;
}

.col-md-20 {
  width: 20%;
  float: left;
  border: 1px solid white;
}

.col-lg-20 {
  width: 20%;
  float: left;
  border: 1px solid white;
}

.shadow {
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
}

/* ----------- 2.Typography -----------
--------------------------------------*/
/* Elements Typography */
h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  font-weight: 600;
  font-family: "Open Sans", Arial, sans-serif, Arial, sans-serif;
  line-height: 1;
  color: #222;
  margin-top: 0;
  margin-bottom: 20px;
}

h1 small, .h1 small,
h2 small, .h2 small,
h3 small, .h3 small,
h4 small, .h4 small,
h5 small, .h5 small,
h6 small, .h6 small {
  color: inherit;
}

h1,
.h1 {
  font-size: 24px;
}

h2,
.h2 {
  font-size: 20px;
}

h3,
.h3 {
  font-size: 18px;
}

h4,
.h4 {
  font-size: 16px;
  margin-bottom: 15px;
}

h5,
.h5 {
  font-size: 14px;
  margin-bottom: 15px;
}

h6,
.h6 {
  font-size: 12px;
  margin-bottom: 15px;
}

a {
  color: #0069b0;
  transition: color 0.4s;
}

a:hover, a:focus {
  color: #001d31;
  text-decoration: none;
}

.text-custom {
  color: #0069b0;
}

.text-custom2 {
  color: #c00f3c;
}

.text-white {
  color: #fff !important;
}

.text-muted {
  color: #777 !important;
}

.text-light {
  font-weight: 300 !important;
}

.text-spaced {
  letter-spacing: 0.05em;
}

.text-lineth {
  text-decoration: line-through;
}

.text-underline {
  text-decoration: underline;
}

blockquote {
  position: relative;
  padding: 25px 30px 23px 30px;
  margin: 0 0 20px;
  font-size: 14px;
  line-height: 1.6;
  background-color: #f6f6fa;
  font-style: italic;
  border: none;
  border-radius: 0;
}

.bg-lightgray blockquote {
  background-color: #f0f0f0;
}

@media (max-width: 767px) {
  blockquote {
    padding: 18px 15px 16px 15px;
  }
}

blockquote p {
  margin-bottom: 0;
}

blockquote cite {
  display: inline-block;
  margin-top: 16px;
  font-size: 13px;
  font-weight: 600;
  font-style: italic;
  color: #0069b0;
}

blockquote.blockquote-reverse {
  border: none;
  padding: 25px 30px 23px 30px;
}

@media (max-width: 767px) {
  blockquote.blockquote-reverse {
    padding: 18px 15px 16px 15px;
  }
}

p {
  margin-bottom: 15px;
}

.highlight {
  background-color: #101010;
  color: #fff;
  padding: 0 4px;
}

.highlight.rounded {
  border-radius: 5px;
}

.highlight.gray {
  color: #323232;
  background-color: #eaeaea;
}

.highlight.darkgray {
  color: #323232;
  background-color: #c7c7c7;
}

.highlight.custom {
  background-color: #0069b0;
}

.highlight.custom2 {
  background-color: #c00f3c;
}

.dropcap::first-letter {
  float: left;
  font-size: 2.2em;
  padding: 0 0.2em;
  margin-right: 0.2em;
  line-height: 1.4;
  color: #fff;
  background-color: #101010;
}

.dropcap.gray::first-letter {
  color: #222;
  background-color: #eaeaea;
}

.dropcap.custom::first-letter {
  background-color: #0069b0;
}

.dropcap.custom2::first-letter {
  color: #fff;
  background-color: #c00f3c;
}

.fa-ul {
  margin-left: 23px;
  margin-bottom: 20px;
}

.fa-ul li {
  margin-bottom: 7px;
}

.fa-ul li .fa-li {
  left: -23px;
  width: auto;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
}

.lead.max-width {
  max-width: 680px;
}

.text-center .lead.max-width {
  margin-left: auto;
  margin-right: auto;
}

.lead.smaller {
  font-size: 15px;
}

@media (min-width: 768px) {
  .lead.smaller {
    font-size: 17px;
  }
}

/* ----------- 3.Elements ------------
--------------------------------------*/
/* Elements Titles */
.title {
  position: relative;
  padding-bottom: 15px;
}

.title:after {
  content: '';
  display: block;
  width: 50px;
  height: 2px;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: #eaeaea;
}

.title.text-center:after {
  left: 50%;
  margin-left: -25px;
}

.title.text-right:after {
  left: auto;
  right: 0;
}

.title.custom:after {
  background-color: #0069b0;
}

.title.custom2:after {
  background-color: #c00f3c;
}

.title.dark:after {
  background-color: #171717;
}

.title.text-white:after, .title.white:after {
  background-color: #fff;
}

.title.title-bottom-border:after {
  height: 4px;
  bottom: -2px;
}

.title-desc {
  font-size: 16px;
  font-weight: 300;
  max-width: 720px;
  margin-bottom: 50px;
}

.title-desc.text-center {
  margin-left: auto;
  margin-right: auto;
}

.title-border {
  padding: 0 0 0 0.6em;
  border-left: 3px solid transparent;
}

.title-border > span {
  display: inline-block;
  padding: 0 0.6em;
  background-color: #fff;
  position: relative;
  z-index: 1;
}

.edge.bg-white #wrapper .title-border > span {
  background-color: #f2f2fa;
}

.bg-gray .title-border > span {
  background-color: #f0f0f0 !important;
}

.bg-gray2 .title-border > span {
  background-color: #e1e1e1 !important;
}

.title-border.text-right,
.text-right > .title-border {
  padding: 0 0.6em 0 0;
  border-left: none;
  border-right: 3px solid transparent;
}

.title-border.text-center,
.text-center > .title-border {
  padding: 0;
  border: none;
}

.title-border.text-center > span,
.text-center > .title-border > span {
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
}

.title-border.title-border {
  margin-bottom: 10px;
}

.title-border.custom, .title-border.text-right.custom,
.title-border.text-center.custom > span {
  border-color: #0069b0;
}

.title-border.title-bg-line, .title-border.text-center.title-bg-line, .title-border.title-bg, .title-border.text-center.title-bg {
  padding: 0;
}

.bg-dark .title-border > span {
  background-color: #171717;
}

.bg-gray .title-border > span {
  background-color: #f0f0f0;
}

.bg-lightgray .title-border > span {
  background-color: #f8f8f8;
}

.bg-lightergray .title-border > span {
  background-color: #fafafa;
}

.bg-gray2 .title-border > span {
  background-color: #e1e1e1;
}

.title-bottom-border.custom {
  border-color: #0069b0;
}

.title-bg-line,
.title-underline {
  position: relative;
}

.title-bg-line > span,
.title-underline > span {
  padding: 0 0.6em;
}

.title-bg-line:after {
  content: '';
  display: block;
  height: 1px;
  background-color: transparent;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  z-index: 0;
}

.title-block {
  margin-bottom: 30px;
}

.title-block .title {
  margin-bottom: 10px;
}

.title-block > p {
  font-size: 1.1em;
}

.title > .light,
.title-block .light {
  font-weight: normal;
}

.title-bottom-border {
  border-bottom: 1px solid transparent;
}

.title-bottom-border.dashed {
  border-bottom-style: dashed;
}

.title-bottom-border.dotted {
  border-bottom-style: dotted;
}

.title-border.dark,
.title-border.text-right.dark,
.title-border.text-center.dark > span,
.title-bottom-border.dark {
  border-color: #171717;
}

.title-border.title-bg.dark,
.title-bg-line.dark:after {
  background-color: #171717;
}

.title-border.gray,
.title-border.text-right.gray,
.title-border.text-center.gray > span,
.title-bottom-border.gray {
  border-color: #eaeaea;
}

.title-border.title-bg.gray,
.title-bg-line.gray:after {
  background-color: #eaeaea;
}

.title-border.white,
.title-border.text-right.white,
.title-border.text-center.white > span,
.title-bottom-border.white {
  border-color: #fff;
}

.title-border.title-bg.white,
.title-bg-line.white:after {
  background-color: #fff;
}

.title-border.title-bg.custom,
.title-bg-line.custom:after,
.bg-custom .title-border > span {
  background-color: #0069b0;
}

.title-border.title-bg.custom2,
.title-bg-line.custom2:after,
.bg-custom2 .title-border > span {
  background-color: #c00f3c;
}

/* Breadcrumbs */
.breadcrumb {
  padding: 18px 0;
  margin: 0;
  background-color: transparent;
  border-radius: 0;
  font-size: 1.25rem;
  font-weight: 400;
}

.breadcrumb li a {
  color: #0069b0;
}

.breadcrumb li a:hover, .breadcrumb li a:focus {
  color: #004b7d;
}

.breadcrumb > .active {
  color: #222;
}

.breadcrumb li + li:before {
  color: #333;
  content: "\f101";
  font-family: 'FontAwesome';
  padding: 0 13px 0 6px;
}

/* Page Headers */
.page-header {
  padding: 25px 0;
  margin: 0 0 50px;
  background-color: #f6f6fa;
  border-bottom: 1px solid #eeeeee;
  /* Parallax + overlay */
  /* Dark background */
  /* Custom background */
  /* Custom2 background */
}

@media (min-width: 768px) {
  .page-header {
    margin-bottom: 60px;
  }
}

@media (min-width: 992px) {
  .page-header {
    margin-bottom: 70px;
  }
}

.page-header .breadcrumb {
  padding: 0;
}

.page-header h1 {
  font-family: "Montserrat", Arial, sans-serif;
  font-size: 28px;
  line-height: 1.15;
  font-weight: 700;
  color: #333;
  text-transform: uppercase;
  margin: 0;
}

.page-header h1 + .breadcrumb {
  margin-top: 6px;
}

.page-header p {
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.5;
  margin: 0;
}

.page-header.larger {
  padding: 40px 0;
}

.page-header.largest {
  padding: 80px  0;
}

.page-header.largest h1 {
  font-size: 32px;
}

.page-header.larger-top {
  padding-top: 130px;
}

@media (min-width: 768px) and (max-width: 991px) {
  .page-header.larger-top {
    padding-top: 200px;
  }
}

@media (min-width: 992px) {
  .page-header.larger-top {
    padding-top: 160px;
  }
}

.page-header.largest-top {
  padding-top: 160px;
}

@media (min-width: 768px) and (max-width: 991px) {
  .page-header.largest-top {
    padding-top: 230px;
  }
}

@media (min-width: 992px) {
  .page-header.largest-top {
    padding-top: 200px;
  }
}

.page-header.fullheight > .container-fluid, .page-header.fullscreen > .container-fluid {
  display: table;
  width: 100%;
  height: 100%;
}

@media (max-width: 767px) {
  .page-header.fullheight > .container, .page-header.fullscreen > .container {
    width: 100%;
  }
}

.page-header.fullheight .page-header-cell, .page-header.fullscreen .page-header-cell {
  display: table-cell;
  vertical-align: middle;
}

.page-header.parallax, .page-header.bg-image {
  position: relative;
}

.page-header.parallax:before, .page-header.bg-image:before {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 0;
  background-color: #fff;
  opacity: 0.65;
}

.page-header.parallax .container,
.page-header.parallax .container-fluid, .page-header.bg-image .container,
.page-header.bg-image .container-fluid {
  position: relative;
  z-index: 1;
}

.page-header.dark {
  background-color: #171717;
  color: #9a9a9a;
  border-color: #101010;
}

.page-header.dark h1 {
  color: #fff;
}

.page-header.dark .breadcrumb li a {
  color: #9a9a9a;
}

.page-header.dark .breadcrumb li a:hover, .page-header.dark .breadcrumb li a:focus {
  color: #fff;
}

.page-header.dark .breadcrumb > .active {
  color: #fff;
}

.page-header.dark .breadcrumb li + li:before {
  color: #bbb;
}

.page-header.dark.parallax:before, .page-header.dark.bg-image:before {
  background-color: #000;
  opacity: 0.6;
}

.page-header.custom {
  background-color: #0069b0;
  color: #0087e3;
  border-color: #0078ca;
}

.page-header.custom h1 {
  color: #fff;
}

.page-header.custom .breadcrumb li a {
  color: #54baff;
}

.page-header.custom .breadcrumb li a:hover, .page-header.custom .breadcrumb li a:focus {
  color: #fff;
}

.page-header.custom .breadcrumb > .active {
  color: #fff;
}

.page-header.custom .breadcrumb li + li:before {
  color: #0d9dff;
}

.page-header.custom.parallax:before, .page-header.custom.bg-image:before {
  background-color: #0069b0;
}

.page-header.custom2 {
  background-color: #c00f3c;
  color: #ed154c;
  border-color: #d81143;
}

.page-header.custom2 h1 {
  color: #fff;
}

.page-header.custom2 .breadcrumb li a {
  color: #f2577f;
}

.page-header.custom2 .breadcrumb li a:hover, .page-header.custom2 .breadcrumb li a:focus {
  color: #fff;
}

.page-header.custom2 .breadcrumb > .active {
  color: #fff;
}

.page-header.custom2 .breadcrumb li + li:before {
  color: #f03b69;
}

.page-header.custom2.parallax:before, .page-header.custom2.bg-image:before {
  background-color: #c00f3c;
}

/* Added this for elements page to clear margin bottom just for this page */
.elements-page .page-header {
  margin-bottom: 0;
}

/* Elements Collapses */
.panel-group .panel + .panel {
  margin-top: 4px;
}

.panel-group .panel-heading + .panel-collapse > .panel-body {
  border-top: none;
}

.panel {
  background-color: transparent;
  margin-bottom: 40px;
  border-radius: 0;
  border: none;
  box-shadow: none;
  /* Panel Default */
  /* Panel Inverse */
  /* Panel Custom */
}

.panel .panel-heading {
  padding: 0;
  border-radius: 0;
  border: none;
}

.panel .panel-heading .panel-title {
  font-weight: 400;
  position: relative;
  font-size: 13px;
  font-family: "Open Sans", Arial, sans-serif;
}

.panel .panel-heading .panel-title a {
  border: 1px solid transparent;
  display: block;
  padding: 12px 16px;
  color: #333;
  transition: all 0.4s;
}

.panel .panel-heading .panel-title a:before {
  display: inline-block;
  min-width: 15px;
  font-family: 'FontAwesome';
  content: "\f107";
}

.panel .panel-heading .panel-title a.collapsed:before {
  content: "\f105";
}

.panel .panel-body {
  padding: 16px 0;
}

.panel .panel-body p {
  margin-bottom: 13px;
}

.panel .panel-body p:last-child {
  margin-bottom: 0;
}

.panel.panel-default .panel-heading a {
  color: #333;
  background-color: #f6f6fa;
  border-color: #f0f0fa;
}

.panel.panel-default .panel-heading a:hover, .panel.panel-default .panel-heading a:focus {
  color: #333;
  background-color: #eee;
  border-color: #e8e8e8;
}

.panel.panel-inverse .panel-heading a {
  background-color: #222;
  border-color: #101010;
  color: #fff;
}

.panel.panel-inverse .panel-heading a:hover, .panel.panel-inverse .panel-heading a:focus {
  color: #fff;
  background-color: #101010;
  border-color: #000;
}

.panel.panel-custom .panel-heading a {
  background-color: #0069b0;
  border-color: #005a97;
  color: #fff;
}

.panel.panel-custom .panel-heading a:hover, .panel.panel-custom .panel-heading a:focus {
  color: #fff;
  background-color: #005a97;
  border-color: #004b7d;
}

/* Elements Tabs */
.nav.nav-tabs > li > a, .nav.nav-pills > li > a {
  padding: 9px 15px;
  color: #323232;
  transition: background 0.35s;
}

.nav.nav-tabs > li > a:hover, .nav.nav-pills > li > a:hover {
  background-color: #f6f6fa;
}

@media (max-width: 480px) {
  .nav.nav-tabs:not(.nav-justified) > li > a, .nav.nav-pills:not(.nav-justified) > li > a {
    padding-left: 7px;
    padding-right: 7px;
    max-width: 70px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}

.nav.nav-justified > li > a {
  margin: 0;
  border-radius: 0;
}

.nav.nav-justified > li + li {
  margin-left: 0 !important;
}

.nav-tabs {
  margin-bottom: 15px;
  border-bottom-color: #e1e1e1;
}

.nav-tabs li a {
  border-radius: 0;
  position: relative;
}

.nav-tabs li a i {
  margin-right: 7px;
}

.nav-tabs li a:before {
  content: '';
  position: absolute;
  display: block;
  left: 50%;
  right: 50%;
  height: 2px;
  top: -1px;
  background-color: #0069b0;
  visibility: hidden;
  transition: all 0.4s;
}

.nav-tabs li a:hover {
  border-color: #e1e1e1;
}

.nav-tabs li.active a, .nav-tabs li.active a:hover, .nav-tabs li.active a:focus {
  background-color: #fff;
  border-color: #e1e1e1;
  border-bottom-color: transparent;
}

.bg-lightgray .nav-tabs li.active a, .bg-lightgray .nav-tabs li.active a:hover, .bg-lightgray .nav-tabs li.active a:focus {
  background-color: #f8f8f8;
}

.nav-tabs li.active a:before {
  visibility: visible;
  left: -1px;
  right: -1px;
}

.tab-content.bordered {
  padding: 15px;
  border: 1px solid #e1e1e1;
  border-top: none;
  margin-top: -15px;
}

.tab-content.bordered p:last-child {
  margin-bottom: 0;
}

.tab-content + .nav-tabs {
  border-bottom: none;
  border-top: 1px solid #e1e1e1;
}

.tab-content + .nav-tabs > li > a {
  border-top: none;
}

.tab-content + .nav-tabs > li > a:before {
  top: auto;
  bottom: -1px;
}

.tab-content + .nav-tabs > li.active a, .tab-content + .nav-tabs > li.active a:hover, .tab-content + .nav-tabs > li.active a:focus {
  margin-top: -1px;
  border: 1px solid #eee;
  border-top-color: transparent;
}

.tab-content + .nav-tabs > li.active a:before {
  visibility: visible;
  left: 0;
  right: 0;
}

@media (min-width: 768px) {
  .vertical-tab {
    display: table;
  }
  .vertical-tab .nav-tabs,
  .vertical-tab .nav-pils,
  .vertical-tab .tab-content {
    display: table-cell;
  }
  .vertical-tab .nav-tabs > li,
  .vertical-tab .nav-pils > li,
  .vertical-tab .tab-content > li {
    float: none;
    white-space: nowrap;
  }
  .vertical-tab.left .tab-content {
    padding-left: 15px;
  }
  .vertical-tab.right .tab-content {
    padding-right: 15px;
  }
  .vertical-tab .nav-tabs {
    margin-bottom: 0;
    border: none;
  }
  .vertical-tab.left .nav-tabs {
    border-right: 1px solid #eee;
  }
  .vertical-tab.left .nav-tabs > li {
    margin: 0;
  }
  .vertical-tab.left .nav-tabs > li a {
    margin: 0;
  }
  .vertical-tab.left .nav-tabs > li a:before {
    top: 50%;
    bottom: 50%;
    left: -1px;
    right: auto;
    height: auto;
    width: 2px;
  }
  .vertical-tab.left .nav-tabs > li.active a,
  .vertical-tab.left .nav-tabs > li.active a:hover,
  .vertical-tab.left .nav-tabs > li.active a:focus {
    border: 1px solid #eee;
    margin: 0;
    margin-right: -1px;
    border-right-color: transparent;
  }
  .vertical-tab.left .nav-tabs > li.active a:before,
  .vertical-tab.left .nav-tabs > li.active a:hover:before,
  .vertical-tab.left .nav-tabs > li.active a:focus:before {
    top: -1px;
    bottom: -1px;
  }
  .vertical-tab.right .tab-content + .nav-tabs > li > a {
    border-top: 1px solid transparent;
  }
  .vertical-tab.right .nav-tabs {
    border-left: 1px solid #eee;
  }
  .vertical-tab.right .nav-tabs > li a {
    margin: 0;
    border-width: 1px;
  }
  .vertical-tab.right .nav-tabs > li a:before {
    top: 50%;
    bottom: 50%;
    right: -1px;
    left: auto;
    height: auto;
    width: 2px;
  }
  .vertical-tab.right .nav-tabs > li.active a,
  .vertical-tab.right .nav-tabs > li.active a:hover,
  .vertical-tab.right .nav-tabs > li.active a:focus {
    border: 1px solid #eee;
    margin: 0;
    margin-left: -1px;
    border-left-color: transparent;
  }
  .vertical-tab.right .nav-tabs > li.active a:before,
  .vertical-tab.right .nav-tabs > li.active a:hover:before,
  .vertical-tab.right .nav-tabs > li.active a:focus:before {
    top: -1px;
    bottom: -1px;
  }
  .vertical-tab .nav-pills {
    min-width: 110px;
  }
  .vertical-tab .nav-pills > li {
    display: block;
    width: 100%;
  }
  .vertical-tab .nav-pills > li + li {
    margin-left: 0;
  }
}

.nav-pills {
  margin-bottom: 15px;
}

.nav-pills > li > a {
  border-radius: 0;
}

.nav-pills > li + li {
  margin-left: 3px;
}

.nav-pills > li.active a,
.nav-pills > li.active a:hover,
.nav-pills > li.active a:focus {
  color: #fff;
  background-color: #0069b0;
}

/* Elements Buttons */
.btn {
  position: relative;
  z-index: 2;
  transition: background 0.45s, border 0.45s;
  font-size: 13px;
  font-weight: 400;
  padding: 9px 15px;
  border-radius: 0;
}

.btn.btn-rounded {
  border-radius: 5px;
}

.btn.btn-border {
  padding: 8px 14px;
  border-width: 2px;
}

.btn.btn-sm {
  font-size: 11px;
  padding: 7px 10px;
}

.btn.btn-sm.btn-rounded {
  border-radius: 4px;
}

.btn.btn-sm.btn-border {
  padding: 6px 9px;
  border-width: 2px;
}

.btn.btn-lg {
  font-size: 15px;
  padding: 11px 20px;
}

.btn.btn-lg.btn-rounded {
  border-radius: 7px;
}

.btn.btn-lg.btn-border {
  padding: 10px 19px;
  border-width: 2px;
}

.btn i {
  margin-right: 5px;
  font-size: 80%;
  vertical-align: middle;
  margin-top: -3px;
}

.btn:active, .btn.active {
  box-shadow: none;
  -webkit-box-shadow: none;
}

.btn:focus, .btn:active {
  outline: none;
}

.btn.min-width {
  min-width: 150px;
  text-align: center !important;
}

@media (max-width: 480px) {
  .btn.min-width {
    min-width: 0;
  }
}

.btn.min-width-sm {
  min-width: 120px;
  text-align: center !important;
}

@media (max-width: 480px) {
  .btn.min-width-sm {
    min-width: 0;
  }
}

.btn.min-width-lg {
  min-width: 170px;
  text-align: center !important;
}

@media (max-width: 480px) {
  .btn.min-width-lg {
    min-width: 0;
  }
}

.tp-caption.btn i {
  font-size: inherit;
  margin-top: auto;
  margin-right: 0;
}

.btn-group {
  /* Dropdown */
}

.btn-group > .btn + .btn {
  border-left: 1px solid rgba(255, 255, 255, 0.15);
}

.btn-group .dropdown-menu {
  padding: 7px 0;
  border-radius: 0;
  font-size: 13px;
  border-color: #eee;
}

.btn-group .dropdown-menu li a {
  color: #323232;
  padding: 4px 15px;
  transition: background 0.4s;
}

.btn-group .dropdown-menu li a:hover, .btn-group .dropdown-menu li a:focus {
  color: #323232;
  background-color: #f6f6fa;
}

/* Button Colors */
.btn.btn-custom {
  color: #fff;
  background-color: #0069b0;
  border-color: #0069b0;
}

.btn.btn-custom:hover, .btn.btn-custom:focus {
  color: #fff;
  background-color: #004b7d;
  border-color: #004b7d;
}

.btn.btn-custom.btn-border {
  color: #0069b0;
  background-color: transparent;
  border-color: #0069b0;
}

.btn.btn-custom.btn-border:hover, .btn.btn-custom.btn-border:focus {
  color: #fff;
  background-color: #0069b0;
  border-color: #0069b0;
}

.btn.btn-custom2 {
  color: #fff;
  background-color: #c00f3c;
  border-color: #c00f3c;
}

.btn.btn-custom2:hover, .btn.btn-custom2:focus {
  color: #fff;
  background-color: #910b2d;
  border-color: #910b2d;
}

.btn.btn-custom2.btn-border {
  color: #c00f3c;
  background-color: transparent;
  border-color: #c00f3c;
}

.btn.btn-custom2.btn-border:hover, .btn.btn-custom2.btn-border:focus {
  color: #fff;
  background-color: #c00f3c;
  border-color: #c00f3c;
}

.btn.btn-red {
  color: #fff;
  background-color: #f41d1d;
  border-color: #f41d1d;
}

.btn.btn-red:hover, .btn.btn-red:focus {
  color: #fff;
  background-color: #d40a0a;
  border-color: #d40a0a;
}

.btn.btn-red.btn-border {
  color: #f41d1d;
  background-color: transparent;
  border-color: #f41d1d;
}

.btn.btn-red.btn-border:hover, .btn.btn-red.btn-border:focus {
  color: #fff;
  background-color: #f41d1d;
  border-color: #f41d1d;
}

.btn.btn-darkred {
  color: #fff;
  background-color: #d10707;
  border-color: #d10707;
}

.btn.btn-darkred:hover, .btn.btn-darkred:focus {
  color: #fff;
  background-color: #a00505;
  border-color: #a00505;
}

.btn.btn-darkred.btn-border {
  color: #d10707;
  background-color: transparent;
  border-color: #d10707;
}

.btn.btn-darkred.btn-border:hover, .btn.btn-darkred.btn-border:focus {
  color: #fff;
  background-color: #d10707;
  border-color: #d10707;
}

.btn.btn-blue {
  color: #fff;
  background-color: #0172b8;
  border-color: #0172b8;
}

.btn.btn-blue:hover, .btn.btn-blue:focus {
  color: #fff;
  background-color: #015385;
  border-color: #015385;
}

.btn.btn-blue.btn-border {
  color: #0172b8;
  background-color: transparent;
  border-color: #0172b8;
}

.btn.btn-blue.btn-border:hover, .btn.btn-blue.btn-border:focus {
  color: #fff;
  background-color: #0172b8;
  border-color: #0172b8;
}

.btn.btn-lightblue {
  color: #fff;
  background-color: #2599e2;
  border-color: #2599e2;
}

.btn.btn-lightblue:hover, .btn.btn-lightblue:focus {
  color: #fff;
  background-color: #197cbb;
  border-color: #197cbb;
}

.btn.btn-lightblue.btn-border {
  color: #2599e2;
  background-color: transparent;
  border-color: #2599e2;
}

.btn.btn-lightblue.btn-border:hover, .btn.btn-lightblue.btn-border:focus {
  color: #fff;
  background-color: #2599e2;
  border-color: #2599e2;
}

.btn.btn-green {
  color: #fff;
  background-color: #49ba17;
  border-color: #49ba17;
}

.btn.btn-green:hover, .btn.btn-green:focus {
  color: #fff;
  background-color: #378d11;
  border-color: #378d11;
}

.btn.btn-green.btn-border {
  color: #49ba17;
  background-color: transparent;
  border-color: #49ba17;
}

.btn.btn-green.btn-border:hover, .btn.btn-green.btn-border:focus {
  color: #fff;
  background-color: #49ba17;
  border-color: #49ba17;
}

.btn.btn-green2 {
  color: #fff;
  background-color: #06b0aa;
  border-color: #06b0aa;
}

.btn.btn-green2:hover, .btn.btn-green2:focus {
  color: #fff;
  background-color: #047f7a;
  border-color: #047f7a;
}

.btn.btn-green2.btn-border {
  color: #06b0aa;
  background-color: transparent;
  border-color: #06b0aa;
}

.btn.btn-green2.btn-border:hover, .btn.btn-green2.btn-border:focus {
  color: #fff;
  background-color: #06b0aa;
  border-color: #06b0aa;
}

.btn.btn-lightgreen {
  color: #fff;
  background-color: #7aed47;
  border-color: #7aed47;
}

.btn.btn-lightgreen:hover, .btn.btn-lightgreen:focus {
  color: #fff;
  background-color: #58e819;
  border-color: #58e819;
}

.btn.btn-lightgreen.btn-border {
  color: #7aed47;
  background-color: transparent;
  border-color: #7aed47;
}

.btn.btn-lightgreen.btn-border:hover, .btn.btn-lightgreen.btn-border:focus {
  color: #fff;
  background-color: #7aed47;
  border-color: #7aed47;
}

.btn.btn-yellow {
  color: #fff;
  background-color: #f4b907;
  border-color: #f4b907;
}

.btn.btn-yellow:hover, .btn.btn-yellow:focus {
  color: #fff;
  background-color: #c29306;
  border-color: #c29306;
}

.btn.btn-yellow.btn-border {
  color: #f4b907;
  background-color: transparent;
  border-color: #f4b907;
}

.btn.btn-yellow.btn-border:hover, .btn.btn-yellow.btn-border:focus {
  color: #fff;
  background-color: #f4b907;
  border-color: #f4b907;
}

.btn.btn-orange {
  color: #fff;
  background-color: #f08903;
  border-color: #f08903;
}

.btn.btn-orange:hover, .btn.btn-orange:focus {
  color: #fff;
  background-color: #be6c02;
  border-color: #be6c02;
}

.btn.btn-orange.btn-border {
  color: #f08903;
  background-color: transparent;
  border-color: #f08903;
}

.btn.btn-orange.btn-border:hover, .btn.btn-orange.btn-border:focus {
  color: #fff;
  background-color: #f08903;
  border-color: #f08903;
}

.btn.btn-purple {
  color: #fff;
  background-color: #f003d7;
  border-color: #f003d7;
}

.btn.btn-purple:hover, .btn.btn-purple:focus {
  color: #fff;
  background-color: #be02aa;
  border-color: #be02aa;
}

.btn.btn-purple.btn-border {
  color: #f003d7;
  background-color: transparent;
  border-color: #f003d7;
}

.btn.btn-purple.btn-border:hover, .btn.btn-purple.btn-border:focus {
  color: #fff;
  background-color: #f003d7;
  border-color: #f003d7;
}

.btn.btn-darkpurple {
  color: #fff;
  background-color: #b0069e;
  border-color: #b0069e;
}

.btn.btn-darkpurple:hover, .btn.btn-darkpurple:focus {
  color: #fff;
  background-color: #7f0472;
  border-color: #7f0472;
}

.btn.btn-darkpurple.btn-border {
  color: #b0069e;
  background-color: transparent;
  border-color: #b0069e;
}

.btn.btn-darkpurple.btn-border:hover, .btn.btn-darkpurple.btn-border:focus {
  color: #fff;
  background-color: #b0069e;
  border-color: #b0069e;
}

.btn.btn-black {
  color: #fff;
  background-color: #181818;
  border-color: #181818;
}

.btn.btn-black:hover, .btn.btn-black:focus {
  color: #fff;
  background-color: black;
  border-color: black;
}

.btn.btn-black.btn-border {
  color: #181818;
  background-color: transparent;
  border-color: #181818;
}

.btn.btn-black.btn-border:hover, .btn.btn-black.btn-border:focus {
  color: #fff;
  background-color: #181818;
  border-color: #181818;
}

.btn.btn-gray {
  color: #333;
  background-color: #e2e2e2;
  border-color: #e2e2e2;
}

.btn.btn-gray:hover, .btn.btn-gray:focus {
  color: #333;
  background-color: #c9c9c9;
  border-color: #c9c9c9;
}

.btn.btn-white {
  color: #222;
  background-color: #fff;
  border-color: #fff;
}

.btn.btn-white:hover, .btn.btn-white:focus {
  color: #222;
  background-color: #e6e6e6;
  border-color: #e6e6e6;
}

.btn.btn-white.btn-border {
  color: #fff;
  background-color: transparent;
  border-color: #fff;
}

.btn.btn-white.btn-border:hover, .btn.btn-white.btn-border:focus {
  color: #fff;
  background-color: #0069b0;
  border-color: #0069b0;
}

/* Added elements page for margin */
.elements-page-btn .btn {
  margin-bottom: 4px;
}

.elements-page-btn .btn-group .btn {
  margin-bottom: 0;
}

/* Elements Forms */
.form-control {
  height: 38px;
  font-size: 13px;
  border-radius: 0;
  margin-bottom: 20px;
  color: #5a5a5a;
  border-color: #eaeaea;
  padding: 9px 15px;
  transition: all 0.4s;
  box-shadow: none;
  text-align: left;
}

.form-control.input-sm {
  height: 32px;
  font-size: 11px;
  padding: 7px 10px;
}

.form-control.input-lg {
  height: 43px;
  font-size: 15px;
  padding: 10.5px 20px;
}

.form-control:focus {
  border-color: #0069b0;
  box-shadow: none;
}

.bg-gray .form-control {
  background-color: #e1e1e1;
  border-color: #e1e1e1;
}

.bg-gray .form-control:focus {
  background-color: #d9d9d9;
  border-color: #d9d9d9;
}

.bg-dark .form-control {
  background-color: #212121;
  border-color: #212121;
  color: #9e9e9e;
}

.bg-dark .form-control::placeholder {
  color: #9e9e9e;
}

.bg-dark .form-control:focus {
  background-color: #262626;
  border-color: #262626;
}

.input-group {
  margin-bottom: 20px;
  border-radius: 0;
}

.input-group .input-group-addon {
  font-size: 13px;
  border-radius: 0;
  border-color: #eaeaea;
  background-color: #f6f6fa;
  color: #4a4a4a;
  padding: 6px 15px;
}

.input-group.input-group-sm .input-group-addon {
  font-size: 11px;
  height: 32px;
  padding: 3px 10px;
}

.input-group.input-group-sm .form-control {
  height: 32px;
  padding: 6px 10px;
}

.input-group.input-group-lg .input-group-addon {
  height: 43px;
  font-size: 15px;
  padding: 3px 20px;
}

.input-group.input-group-lg .form-control {
  height: 43px;
  padding: 8.5px 20px;
}

.input-group .form-control {
  border-radius: 0;
}

.input-group .form-control:last-child {
  border-radius: 0;
}

label,
.input-desc {
  font-weight: 600;
  margin-bottom: 5px;
}

textarea.form-control {
  min-height: 150px;
}

.textarea-container {
  position: relative;
  width: 100%;
  padding-top: 38px;
}

.textarea-container .input-group-addon {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 11.5px 15px;
  z-index: 90;
  text-align: left;
  border-right: 1px solid #eaeaea;
}

.textarea-container .form-control {
  border-top-color: transparent;
}

.textarea-container .form-control:focus {
  border-top-color: #0069b0;
}

/* Validation error */
label.error {
  display: block;
  font-weight: 400;
  font-size: 11px;
  color: #ca1515;
  margin: -18px 0 10px;
}

.form-control.error {
  border-color: #ca1515;
}

/* Custom Radio & Checkbox */
.radio label.custom-radio-wrapper,
.checkbox label.custom-checkbox-wrapper {
  padding-left: 0;
}

.input-group-addon.custom-checkbox-wrapper,
.input-group-addon.custom-radio-wrapper {
  line-height: 0;
}

.custom-checkbox-container,
.custom-radio-container {
  display: inline-block;
  width: 14px;
  height: 14px;
  border: 1px solid #e1e1e1;
  position: relative;
  margin-right: 10px;
  border-radius: 0;
}

.custom-radio-container {
  border-radius: 50px;
}

.custom-checkbox-container input[type="checkbox"] {
  display: block;
  opacity: 0;
  filter: alpha(opacity=0);
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10;
}

.custom-checkbox-container .custom-checkbox-icon {
  position: absolute;
  z-index: 9;
  width: 10px;
  height: 10px;
  left: 50%;
  top: 50%;
  margin-top: -5px;
  margin-left: -5px;
  color: #0069b0;
  opacity: 0;
  filter: alpha(opacity=0);
  border-radius: 0;
  font: 15px/1 FontAwesome;
}

.custom-checkbox-container .custom-checkbox-icon:before {
  content: "\f00c";
  position: absolute;
  top: -3px;
  left: 0;
}

.custom-radio-container input[type="radio"] {
  display: block;
  opacity: 0;
  filter: alpha(opacity=0);
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10;
}

.custom-radio-container .custom-radio-icon {
  position: absolute;
  z-index: 9;
  display: block;
  opacity: 0;
  filter: alpha(opacity=0);
  width: 10px;
  height: 10px;
  left: 50%;
  top: 50%;
  margin-top: -5px;
  margin-left: -5px;
  background-color: #0069b0;
  border-radius: 50%;
  transition: all 0.4s;
  transform: scale(0);
}

.disabled .custom-checkbox-container .custom-checkbox-icon {
  display: none;
}

.disabled .custom-radio-container .custom-radio-icon {
  background-color: transparent;
}

.custom-checkbox-container input[type="checkbox"]:checked + .custom-checkbox-icon,
.custom-radio-container input[type="radio"]:checked + .custom-radio-icon {
  opacity: 1;
  filter: alpha(opacity=100);
}

.custom-radio-container input[type="radio"]:checked + .custom-radio-icon {
  transform: scale(1);
}

.disabled .custom-checkbox-container input[type="checkbox"]:checked + .custom-checkbox-icon {
  background-color: transparent;
}

.custom-checkbox-wrapper *,
.custom-radio-wrapper * {
  vertical-align: middle;
}

.custom-checkbox-wrapper.checkbox-inline, .custom-checkbox-wrapper.radio-inline,
.custom-radio-wrapper.checkbox-inline,
.custom-radio-wrapper.radio-inline {
  padding-left: 0;
}

/* Elements Progress Bars */
/* 2- Progress Bars */
.progress-container {
  position: relative;
  margin-bottom: 25px;
}

.progress-container .progress-title {
  font-weight: 400;
  font-size: 14px;
  line-height: 1.2;
  font-weight: 600;
  color: #0069b0;
  margin-bottom: 10px;
}

.progress-container .progress-title.dark {
  color: #222;
}

.progress-container .progress-title > span {
  float: right;
  font-weight: 400;
}

.progress {
  height: 24px;
  margin-bottom: 0;
  background-color: #e5e7f0;
  padding: 4px;
  box-shadow: none;
  border-radius: 0;
}

.progress.progress-xs {
  height: 8px;
  padding: 0;
}

.progress .progress-bar {
  box-shadow: none;
  background-color: #0069b0;
}

.progress .progress-bar .progress-bar-wrapper {
  position: relative;
  height: 100%;
}

.progress .progress-bar.gradient1 {
  background: linear-gradient(to right, #0069b0 0%, #c00f3c 100%);
  background-color: #0069b0;
}

.progress .progress-bar.gradient2 {
  background: linear-gradient(to right, #c00f3c 0%, #0069b0 100%);
  background-color: #c00f3c;
}

.progress .progress-bar.dark {
  background-color: #101010;
}

.progress .progress-bar.custom {
  background-color: #0069b0;
}

.progress .progress-bar.custom2 {
  background-color: #c00f3c;
}

.progress .progress-bar.gray {
  background-color: #c7c7c7;
}

.progress .progress-bar.darkgray {
  background-color: #9e9e9e;
}

.progress .progress-bar.white {
  background-color: #fff;
}

.progress.dark {
  background-color: #101010;
}

.progress.dark .dark {
  background-color: #4a4a4a;
}

.progress.bordered {
  background-color: transparent;
  border: 1px solid #eaeaea;
}

.progress.bordered .white {
  background-color: #f1f1f1;
}

/* Elements Sass */
.service {
  margin-bottom: 30px;
  cursor: pointer;
}

.service i {
  display: inline-block;
  font-size: 18px;
  margin-bottom: 17px;
}

.service i.custom {
  color: #0069b0;
}

.service i.custom2 {
  color: #c00f3c;
}

.service i.gray {
  color: #b7b7b7;
}

.service i.icon-bg {
  width: 46px;
  height: 46px;
  line-height: 46px;
  text-align: center;
  color: #3e3e3e;
  background-color: #e6e6e6;
  border-radius: 50%;
  transition: all 0.4s;
}

.service i.icon-bg.custom {
  color: #fff;
  background-color: #0069b0;
}

.service i.icon-bg.custom2 {
  color: #fff;
  background-color: #c00f3c;
}

.service i.icon-bg.dark {
  color: #fff;
  background-color: #171717;
}

.service img {
  display: inline-block;
  max-width: 100%;
  height: auto;
  margin-bottom: 20px;
}

.service .service-title {
  font-size: 15px;
  margin-bottom: 10px;
  font-weight: 600;
}

.service .service-title:last-child {
  margin-bottom: 0;
}

.service p:last-child {
  margin-bottom: 0;
}

.service.service-box {
  position: relative;
  background-color: #f6f6fa;
  padding: 25px 17px;
  text-align: center;
  transition: all 0.4s;
}

.service.service-box.service-icon-top {
  padding-top: 45px;
  margin-bottom: 50px;
}

.service.service-box.service-icon-top .icon-bg {
  position: absolute;
  left: 50%;
  top: -23px;
  margin-left: -23px;
}

.service.service-box:hover {
  color: #fff;
  background-color: #0069b0 !important;
}

.service.service-box:hover .icon-bg {
  color: #fff;
  background-color: #004b7d;
}

.service.service-box:hover .service-title {
  color: #fff;
}

.fullwidth-row [class*="col-"]:nth-of-type(2n) .service.service-box {
  background-color: #eee;
}

.service.service-box.custom:not(.service-border) {
  color: #fff;
  background-color: #0069b0 !important;
}

.service.service-box.custom:not(.service-border) .icon-bg {
  color: #fff;
  background-color: #004b7d;
}

.service.service-box.custom:not(.service-border) .service-title {
  color: #fff;
}

.service.service-box.custom:not(.service-border):hover {
  background-color: #004b7d !important;
}

.service.service-box.custom:not(.service-border):hover .icon-bg {
  background-color: #002c4a;
}

.service.service-box.custom2:not(.service-border) {
  color: #fff;
  background-color: #c00f3c !important;
}

.service.service-box.custom2:not(.service-border) .icon-bg {
  color: #fff;
  background-color: #910b2d;
}

.service.service-box.custom2:not(.service-border) .service-title {
  color: #fff;
}

.service.service-box.custom2:not(.service-border):hover {
  background-color: #910b2d !important;
}

.service.service-box.custom2:not(.service-border):hover .icon-bg {
  background-color: #61081e;
}

.service.service-box.dark:not(.service-border) {
  color: #fff;
  background-color: #171717 !important;
}

.service.service-box.dark:not(.service-border) .icon-bg {
  color: #fff;
  background-color: #2b2b2b;
}

.service.service-box.dark:not(.service-border) .service-title {
  color: #fff;
}

.service.service-box.dark:not(.service-border):hover {
  background-color: #101010 !important;
}

.service.service-box.dark:not(.service-border):hover .icon-bg {
  background-color: #333333;
}

.service.service-border {
  background-color: transparent;
  border: 1px solid #eee;
}

.service.service-border .icon-bg {
  background-color: #eee;
}

.service.service-border:hover {
  border-color: #004b7d;
}

.service.service-border.custom {
  background-color: transparent !important;
  border-color: #0069b0;
}

.service.service-border.custom .icon-bg {
  color: #fff;
  background-color: #0069b0;
}

.service.service-border.custom:hover {
  background-color: #0069b0 !important;
  border-color: #004b7d;
}

.service.service-border.custom:hover .icon-bg {
  background-color: #004b7d;
}

.service.service-border.custom2 {
  background-color: transparent !important;
  border-color: #c00f3c;
}

.service.service-border.custom2 .icon-bg {
  color: #fff;
  background-color: #c00f3c;
}

.service.service-border.custom2:hover {
  background-color: #c00f3c !important;
  border-color: #910b2d;
}

.service.service-border.custom2:hover .icon-bg {
  background-color: #910b2d;
}

.service.service-border.dark {
  background-color: transparent !important;
  border-color: #171717;
}

.service.service-border.dark .icon-bg {
  color: #fff;
  background-color: #171717;
}

.service.service-border.dark:hover {
  background-color: #171717 !important;
  border-color: black;
}

.service.service-border.dark:hover .icon-bg {
  background-color: #313131;
}

.service.icon-left i {
  float: left;
  min-width: 30px;
}

.service.icon-left i.icon-bg + .service-title,
.service.icon-left i.icon-bg + .service-title + p {
  margin-left: 66px;
}

.service.icon-left .service-title,
.service.icon-left p {
  margin-left: 45px;
}

.service.icon-left.reverse {
  text-align: right;
}

.service.icon-left.reverse i {
  float: right;
}

.service.icon-left.reverse i.icon-bg + .service-title,
.service.icon-left.reverse i.icon-bg + .service-title + p {
  margin-right: 66px;
  margin-left: 0;
}

.service.icon-left.reverse .service-title,
.service.icon-left.reverse p {
  margin-right: 45px;
  margin-left: 0;
}

.service.icon-left:hover .icon-bg {
  color: #fff;
  background-color: #0069b0;
}

.service.icon-left:hover .icon-bg.custom {
  color: #fff;
  background-color: #004b7d;
}

.service.icon-left:hover .icon-bg.custom2 {
  color: #fff;
  background-color: #910b2d;
}

.service.icon-left:hover .icon-bg.dark {
  color: #fff;
  background-color: #2b2b2b;
}

.services-group {
  margin-bottom: 50px;
}

.services-group .service-row {
  margin: 0;
}

.services-group .service-row:after {
  content: '';
  display: table;
  clear: both;
}

.services-group .service-row:nth-child(2n) .service.service-box {
  background-color: #f1f1fa;
}

.services-group .service-row:nth-child(2n) .service.service-box:nth-child(2n) {
  background-color: #f6f6fa;
}

.services-group .service-row:nth-child(2n+1) .service.service-box:nth-child(2n) {
  background-color: #f1f1fa;
}

.services-group .service {
  width: 100%;
  margin-bottom: 0;
}

@media (min-width: 768px) {
  .services-group .service {
    width: 50%;
    float: left;
    padding-left: 25px;
    padding-right: 25px;
  }
}

@media (min-width: 992px) {
  .services-group .service {
    width: 25%;
  }
}

.services-group.custom .service-row {
  margin: 0;
}

.services-group.custom .service-row:nth-child(2n) .service.service-box {
  background-color: #005d9c;
}

.services-group.custom .service-row:nth-child(2n) .service.service-box:nth-child(2n) {
  background-color: #0069b0;
}

.services-group.custom .service-row:nth-child(2n+1) .service.service-box {
  background-color: #0069b0;
}

.services-group.custom .service-row:nth-child(2n+1) .service.service-box:nth-child(2n) {
  background-color: #005d9c;
}

.services-group.custom .service {
  color: #eee;
}

.services-group.custom .service .service-title {
  color: #fff;
}

.services-group.custom .service .icon-bg {
  color: #fff;
  background-color: #004b7d;
}

.services-group.custom .service:hover {
  background-color: #00548c !important;
}

.services-group.dark .service-row {
  margin: 0;
}

.services-group.dark .service-row:nth-child(2n) .service.service-box {
  background-color: #1c1c1c;
}

.services-group.dark .service-row:nth-child(2n) .service.service-box:nth-child(2n) {
  background-color: #171717;
}

.services-group.dark .service-row:nth-child(2n+1) .service.service-box {
  background-color: #171717;
}

.services-group.dark .service-row:nth-child(2n+1) .service.service-box:nth-child(2n) {
  background-color: #1c1c1c;
}

.services-group.dark .service {
  color: #eee;
}

.services-group.dark .service .service-title {
  color: #fff;
}

.services-group.dark .service .icon-bg {
  color: #fff;
  background-color: black;
}

.services-group.dark .service:hover {
  background-color: #0d0d0d !important;
}

/* Elements Text Blocks */
.text-block {
  margin-bottom: 30px;
  /* Table Style */
  /* Show bg image on mouseover */
}

@media (min-width: 768px) {
  .text-block {
    margin-bottom: 40px;
  }
}

@media (min-width: 768px) {
  .text-block {
    margin-bottom: 50px;
  }
}

.text-block .block-title {
  font-weight: 400;
  margin-bottom: 9px;
  font-size: 18px;
}

.text-block .block-title a {
  color: #222;
}

.text-block .block-title a:hover, .text-block .block-title a:focus {
  color: #0069b0;
}

.text-block .block-title + .readmore {
  margin-top: 4px;
}

.text-block img {
  display: inline-block;
  max-width: 100%;
  height: auto;
  margin-bottom: 18px;
}

.text-block .block-icon {
  dispay: inline-block;
  width: 48px;
  height: 48px;
  text-align: center;
  font-size: 18px;
  line-height: 48px;
  color: #222;
  background-color: #eee;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  margin-bottom: 18px;
}

.text-block .block-icon.custom {
  color: #fff;
  background-color: #0069b0;
}

.text-block .block-icon.custom2 {
  color: #fff;
  background-color: #c00f3c;
}

.text-block .block-icon.dark {
  color: #fff;
  background-color: #171717;
}

.text-block .block-icon.circle {
  border-radius: 50%;
}

.text-block figure {
  position: relative;
  margin-bottom: 16px;
}

.text-block figure a {
  position: relative;
  display: block;
}

.text-block figure a:after {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.29);
  transition: all 0.4s;
  visibility: hidden;
  opacity: 0;
  z-index: 10;
}

.text-block figure img {
  display: block;
  max-width: 100%;
  height: auto;
  transition: all 0.4s;
}

.text-block figure .block-icon {
  position: absolute;
  right: 24px;
  bottom: -14px;
  z-index: 99;
  margin-bottom: 0;
}

.text-block figure .block-icon.top {
  right: auto;
  bottom: auto;
  top: -14px;
  left: 24px;
}

.text-block figure .block-icon.top-right {
  bottom: auto;
  top: -14px;
}

.text-block p {
  margin-bottom: 12px;
}

.text-block .fa-ul {
  margin-top: -2px;
  margin-left: 17px;
  margin-bottom: 12px;
}

.text-block .fa-ul li .fa-li {
  margin-bottom: 4px;
  left: -17px;
}

.text-block .fa-ul li a {
  color: #222;
  text-decoration: underline;
}

.text-block .fa-ul li a:hover, .text-block .fa-ul li a:focus {
  color: #0069b0;
}

.text-block .readmore {
  display: inline-block;
  font-size: 11px;
  font-weight: 600;
  padding: 5px 13px;
  background-color: #eaeaea;
  color: #222;
  transition: all 0.4s;
  border-radius: 4px;
}

.text-block .readmore:hover, .text-block .readmore:focus, .text-block .readmore.custom {
  background-color: #0069b0;
  color: #fff;
}

.text-block .readmore.custom:hover, .text-block .readmore.custom:focus {
  background-color: #005a97;
  color: #fff;
}

.text-block .readmore.custom2 {
  background-color: #c00f3c;
  color: #fff;
}

.text-block .readmore.custom2:hover, .text-block .readmore.custom2:focus {
  background-color: #a80d35;
  color: #fff;
}

.text-block .readmore.dark {
  background-color: #171717;
  color: #f1f1f1;
}

.text-block .readmore.dark:hover, .text-block .readmore.dark:focus {
  background-color: #313131;
  color: #fff;
}

.text-block:after {
  content: '';
  display: table;
  clear: both;
}

.text-block:hover figure a:after {
  opacity: 1;
  visibility: visible;
}

@media (min-width: 768px) {
  .text-block.block-table {
    display: table;
    table-layout: fixed;
    width: 100%;
  }
}

@media (min-width: 768px) {
  .text-block.block-table .block-cell {
    display: table-cell;
    vertical-align: middle;
    width: 50%;
  }
  .text-block.block-table .block-cell.cell-image {
    padding-right: 20px;
    margin-bottom: 0;
  }
}

.text-block.hover-bg {
  padding: 15px;
  background-size: cover;
  background-position: center center;
  transition: all 0.4s;
  position: relative;
}

.text-block.hover-bg * {
  position: relative;
  z-index: 5;
}

.text-block.hover-bg:after {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #0069b0;
  transition: all 0.4s;
  visibility: hidden;
  opacity: 0;
  z-index: 2;
}

.text-block.hover-bg.dark:after {
  background-color: #171717;
}

.text-block.hover-bg.custom2:after {
  background-color: #c00f3c;
}

.text-block.hover-bg:hover:after, .text-block.hover-bg:focus:after {
  visibility: visible;
  opacity: 0.5;
}

.text-block.hover-bg.light, .text-block.hover-bg:hover, .text-block.hover-bg:focus {
  color: #fff;
}

.text-block.hover-bg.light .block-title, .text-block.hover-bg:hover .block-title, .text-block.hover-bg:focus .block-title {
  color: #fff;
}

.text-block.hover-bg.light .block-title, .text-block.hover-bg:hover .block-title, .text-block.hover-bg:focus .block-title {
  color: #fff;
}

.text-block.hover-bg.light a:not(.readmore), .text-block.hover-bg:hover a:not(.readmore), .text-block.hover-bg:focus a:not(.readmore) {
  color: #fff;
}

.text-block.hover-bg.light a:not(.readmore):hover, .text-block.hover-bg.light a:not(.readmore):focus, .text-block.hover-bg:hover a:not(.readmore):hover, .text-block.hover-bg:hover a:not(.readmore):focus, .text-block.hover-bg:focus a:not(.readmore):hover, .text-block.hover-bg:focus a:not(.readmore):focus {
  color: #c00f3c;
}

/* Elements Tables */
.table {
  margin-bottom: 30px;
}

.table thead > tr {
  border-top: 1px solid #0069b0;
  border-bottom: 1px solid #0069b0;
}

.table thead > tr > th {
  border-bottom: none;
  color: #fff;
  padding: 15px 18px;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 14px;
  background-color: #0069b0;
}

.table tbody > tr {
  border-top: 1px solid #eaeaea;
  border-bottom: 1px solid #eaeaea;
}

.table tbody > tr > td {
  padding: 12px 18px;
  vertical-align: middle;
}

.table tbody > tr.active > td:first-child {
  background-color: #f6f6fa;
}

.table tbody > tr.success > td:first-child {
  background-color: #dff0d8;
}

.table tbody > tr.danger > td:first-child {
  background-color: #f2dede;
}

.table tbody > tr.info > td:first-child {
  background-color: #d9edf7;
}

.table tbody > tr.warning > td,
.table tbody > tr.warning > th,
.table tbody > tr.warning > td:first-child {
  background-color: #fddfaf;
}

.table.table-condensed > thead > tr > th,
.table.table-condensed > thead > tr > td {
  padding: 10px 15px;
}

.table.table-condensed > tbody > tr > th,
.table.table-condensed > tbody > tr > td {
  padding: 10px 15px;
}

.table.table-condensed > tfoot > tr > th,
.table.table-condensed > tfoot > tr > td {
  padding: 10px 15px;
}

.table.table-bordered > thead > tr > th,
.table.table-bordered > thead > tr > td {
  border-color: #0087e3;
}

.table.table-bordered > tbody > tr > th,
.table.table-bordered > tbody > tr > td {
  border-color: #eaeaea;
}

.table.table-bordered > tfoot > tr > th,
.table.table-bordered > tfoot > tr > td {
  border-color: #eaeaea;
}

.table.table-colored > thead > tr {
  border: none !important;
}

.table.table-colored > thead > tr > th,
.table.table-colored > thead > tr > td {
  color: #323232;
  border: none !important;
}

.table.table-colored > tbody > tr {
  border: none !important;
}

.table.table-colored > tbody > tr > td {
  border: none !important;
}

.table.table-hover tbody > tr:hover {
  background-color: #f9f9fe;
}

/* Elements Callouts */
.container > .callout {
  padding-left: 30px;
  padding-right: 30px;
}

@media (max-width: 767px) {
  .container > .callout {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.callout {
  padding: 35px 0;
  width: 100%;
  background-color: #f6f6fa;
  margin-bottom: 50px;
}

.callout .callout-title {
  line-height: 1.5;
  color: #2a2a2a;
}

.callout small {
  font-size: 85%;
  color: #323232;
}

.callout.callout-half {
  margin-bottom: 0;
}

.callout.callout-half form {
  position: relative;
  max-width: 450px;
}

.callout.callout-half form .btn {
  position: absolute;
  right: 2px;
  top: 2px;
  bottom: 2px;
}

.callout.callout-half .form-control {
  margin-bottom: 0;
  height: 44px;
  border-width: 2px;
  padding: 11px 110px 11px 20px;
  border-color: #bbb;
}

.callout.callout-half .form-control:focus {
  border-color: #9a9a9a;
}

.callout.callout-half small {
  font-size: 75%;
}

.callout.callout-half.dark .form-control {
  background-color: #191919;
  border-color: #0b0b0b;
  color: #fff;
}

.callout.callout-half.dark .form-control:focus {
  border-color: #000;
}

.callout.callout-half.custom .form-control {
  background-color: #005a97;
  border-color: #004b7d;
  color: #fff;
}

.callout.callout-half.custom .form-control:focus {
  border-color: #00416e;
}

.callout.callout-half.dark .form-control::-moz-placeholder, .callout.callout-half.custom .form-control::-moz-placeholder {
  color: #dedede;
}

.callout.callout-half.dark .form-control:-ms-input-placeholder, .callout.callout-half.custom .form-control:-ms-input-placeholder {
  color: #dedede;
}

.callout.callout-half.dark .form-control::-webkit-input-placeholder, .callout.callout-half.custom .form-control::-webkit-input-placeholder {
  color: #dedede;
}

@media (min-width: 768px) {
  .callout,
  .callout > .container {
    display: table;
  }
  .callout .left,
  .callout .right {
    display: table-cell;
    vertical-align: middle;
  }
  .callout .right {
    text-align: right;
    padding-left: 20px;
  }
  .callout .callout-title {
    margin-bottom: 0;
  }
  .callout.callout-half {
    position: relative;
    padding-top: 35px;
    padding-bottom: 35px;
  }
  .callout.callout-half > .container {
    table-layout: fixed;
    position: relative;
    z-index: 1;
  }
  .callout.callout-half > .container .left {
    width: 50%;
    padding-right: 40px;
  }
  .callout.callout-half > .container .right {
    width: 50%;
    padding-left: 40px;
  }
  .callout.callout-half form {
    margin-left: auto;
    margin-right: auto;
  }
  .callout.callout-half:before {
    content: '';
    display: block;
    position: absolute;
    left: 50%;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 0;
    background-color: #eaeaea;
  }
  .callout.callout-half.dark:before {
    background-color: #131313;
  }
  .callout.callout-half.custom:before {
    background-color: #005d9c;
  }
}

.callout.dark {
  background-color: #202020;
  color: #9e9e9e;
}

.callout.dark .callout-title {
  color: #fff;
}

.callout.dark small {
  color: #9e9e9e;
}

.callout.custom {
  background-color: #0069b0;
  color: #17a1ff;
}

.callout.custom .callout-title {
  color: #fff;
}

.callout.custom small {
  color: #17a1ff;
}

/* Elements Alerts */
.alert {
  padding: 10px 20px;
  border-radius: 0;
}

.alert.alert-dismissable {
  padding-right: 40px;
  position: relative;
}

.alert.alert-dismissable .close {
  font-size: 22px;
  display: inline-block;
  position: absolute;
  top: 50%;
  right: 20px;
  transition: all 0.4s;
  transform: translateY(-50%);
}

.alert .alert-icon {
  display: inline-block;
  font-size: 1.75em;
  vertical-align: middle;
  margin-right: 5px;
  min-width: 30px;
  text-align: center;
}

@media (max-width: 767px) {
  .alert .alert-icon {
    font-size: 1.2em;
    min-width: 0;
  }
}

.alert.alert-success {
  color: #f1f1f1;
  background-color: #43A047;
  border-color: #1B5E20;
}

.alert.alert-success .alert-link {
  color: #81C784;
}

.alert.alert-success .alert-link:hover, .alert.alert-success .alert-link:focus {
  color: #a4d7a6;
}

.alert.alert-info {
  color: #f1f1f1;
  background-color: #039BE5;
  border-color: #297db3;
}

.alert.alert-info .alert-link {
  color: #4FC3F7;
}

.alert.alert-info .alert-link:hover, .alert.alert-info .alert-link:focus {
  color: #80d4f9;
}

.alert.alert-warning {
  color: #f1f1f1;
  background-color: #f4b907;
  border-color: #d87d1b;
}

.alert.alert-warning .alert-link {
  color: #FDD835;
}

.alert.alert-warning .alert-link:hover, .alert.alert-warning .alert-link:focus {
  color: #fee268;
}

.alert.alert-danger {
  color: #f1f1f1;
  background-color: #d50000;
  border-color: #c30a0a;
}

.alert.alert-danger .alert-link {
  color: #ef5350;
}

.alert.alert-danger .alert-link:hover, .alert.alert-danger .alert-link:focus {
  color: #f3817f;
}

/* Elements Counters */
.count-title {
  font-size: 14px;
  margin-bottom: 30px;
}

.count {
  display: block;
  color: #0069b0;
  font-size: 26px;
  font-weight: 600;
  margin-bottom: 5px;
}

.count-icon {
  display: inline-block;
  height: 36px;
  width: 36px;
  border-radius: 50%;
  background-color: #f6f6fa;
  color: #0069b0;
  font-size: 15px;
  line-height: 36px;
  margin-bottom: 5px;
  text-align: center;
}

.count-icon.custom {
  background-color: #0069b0;
  color: #fff;
}

.count-icon.gray {
  background-color: #f0f0f0;
  color: #222;
}

.count-icon.dark {
  background-color: #171717;
  color: #fff;
}

@media (min-width: 481px) {
  .count-container .count-icon {
    float: left;
  }
  .count-container .count,
  .count-container .count-title {
    margin-left: 52px;
  }
  .count-container .count {
    margin-top: -6px;
  }
  .count-container:after {
    content: '';
    display: table;
    clear: both;
  }
}

@media (max-width: 479px) {
  .count-container {
    text-align: center;
  }
}

.count-box {
  padding: 15px 15px 25px;
  background-color: #eaeaea;
  margin-bottom: 30px;
  transition: background 0.4s;
  /* Custom count box */
  /* Dark count box */
}

.count-box .count-title {
  margin-bottom: 0;
}

.count-box .count-icon {
  margin-top: 5px;
}

.count-box:hover {
  background-color: #e1e1e1;
}

.count-box.custom {
  background-color: #0069b0;
  color: #fff;
}

.count-box.custom .count,
.count-box.custom .count-title {
  color: #fff;
}

.count-box.custom .count-icon {
  background-color: #004b7d;
}

.count-box.custom:hover {
  background-color: #005d9c;
}

.count-box.dark {
  background-color: #171717;
  color: #fff;
}

.count-box.dark .count {
  color: #0069b0;
}

.count-box.dark .count-title {
  color: #fff;
}

.count-box.dark .count-icon {
  color: #fff;
  background-color: #0069b0;
}

.count-box.dark:hover {
  background-color: #0e0e0e;
}

/* More Elements */
/* Bootstrap Carousel */
.carousel .item img {
  max-width: none;
  width: 100%;
}

.carousel .item .carousel-caption {
  font-size: 15px;
  text-shadow: none;
}

.carousel-control {
  top: 50%;
  bottom: auto;
  width: 38px;
  height: 48px;
  margin-top: -24px;
  font-size: 18px;
  line-height: 48px;
  color: #fff;
  opacity: 1;
  text-shadow: none;
  background-color: rgba(0, 105, 176, 0.5);
  transition: background 0.4s;
}

.carousel-control:hover, .carousel-control:focus {
  background-color: rgba(16, 16, 16, 0.5);
}

.carousel-control.left {
  border-radius: 0;
  background-image: none;
}

.carousel-control.right {
  border-radius: 0;
  background-image: none;
}

/* Bootstrap Tooltip */
.tooltip {
  font-family: "Open Sans", Arial, sans-serif;
}

.tooltip-inner {
  padding: 4px 9px;
  border-radius: 0;
}

/* Bootstrap Modal*/
@media (min-width: 768px) {
  .modal-dialog {
    width: 480px;
  }
  .modal-dialog.modal-sm {
    width: 360px;
  }
}

.modal-content {
  border: none;
  border-radius: 0;
}

.modal-content .modal-header {
  background-color: #0069b0;
  border: none;
}

.modal-content .modal-header .modal-title {
  color: #fff;
}

.modal-content .modal-header .close {
  margin-top: 2px;
}

.modal-body .form-group:last-child,
.modal-body .form-group:last-child .form-control {
  margin-bottom: 6px;
}

.modal-footer {
  padding: 10px 15px;
}

/* Paginations */
.pagination-container {
  margin: 0 0 90px;
  border-top: 1px solid #eaeaea;
  border-bottom: 1px solid #eaeaea;
  padding: 12px 0;
}

.pagination-container label,
.pagination-container .pagination {
  vertical-align: middle;
}

.pagination-container label {
  margin-bottom: 0;
  margin-top: 5px;
}

@media (max-width: 360px) {
  .pagination-container label {
    display: none;
  }
}

.pagination-container .pagination {
  float: right;
}

.pagination-container:after {
  content: '';
  clear: both;
  display: table;
}

.pagination {
  margin: 0;
}

.pagination > li > a,
.pagination > li > span {
  float: left;
  padding: 6px 13px;
  color: #337ab7;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #eaeaea;
  color: #222;
  transition: all 0.45s;
}

.pagination > li > a:hover, .pagination > li > a:focus,
.pagination > li > span:hover,
.pagination > li > span:focus {
  color: #222;
  background-color: #c7c7c7;
  border-color: #c7c7c7;
}

.pagination > li:first-child > a,
.pagination > li:first-child > span, .pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-radius: 0;
}

.pagination > li:last-child > a,
.pagination > li:last-child > span {
  min-width: 35px;
  text-align: center;
}

.pagination > li.active > a, .pagination > li.active > a:hover, .pagination > li.active > a:focus,
.pagination > li.active > span,
.pagination > li.active > span:hover,
.pagination > li.active > span:focus {
  color: #fff;
  font-weight: 600;
  background-color: #0069b0;
  border-color: #0069b0;
}

/* ----------- 4.Pricing --------------
--------------------------------------*/
.pricing-table {
  position: relative;
  text-align: center;
  color: #555;
  margin-bottom: 30px;
  max-width: 380px;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 991px) {
  .pricing-table {
    margin-bottom: 60px;
  }
}

.pricing-table header {
  background-color: #0069b0;
  color: #fff;
  padding: 21px 0 22px;
}

.pricing-table header .pricing-title {
  font-size: 18px;
  font-weight: 400;
  color: #fff;
  padding-bottom: 19px;
  border-bottom: 1px solid #0078ca;
  margin-bottom: 20px;
}

.pricing-table header .price {
  font-size: 48px;
  line-height: 1;
  font-weight: 700;
}

.pricing-table header .price span {
  font-size: 50%;
  vertical-align: super;
}

.pricing-table header .price-dur {
  display: block;
  font-size: 13px;
}

.pricing-table .pricing-body {
  border: 1px solid #eaeaea;
  border-top: none;
  background-color: #f6f6fa;
  padding: 23px 25px;
}

.pricing-table .pricing-body .desc {
  padding: 0 20px;
  font-size: 12px;
}

.pricing-table .pricing-body .desc:last-child {
  margin-bottom: 0;
}

.pricing-table .pricing-body ul {
  margin-bottom: 15px;
}

.pricing-table .pricing-body ul:last-child {
  margin-bottom: 0;
}

.pricing-table .pricing-body ul li {
  padding: 7px 0;
  border-bottom: 1px solid #eaeaea;
}

.pricing-table .pricing-body ul li:first-child {
  border-top: 1px solid #eee;
}

.pricing-table .pricing-body ul li i {
  margin-right: 8px;
  color: #6e6e6e;
}

.pricing-table .pricing-body ul li.disabled {
  text-decoration: line-through;
}

.pricing-table .pricing-body .price-action {
  margin-top: 20px;
}

.pricing-table .badge.hot {
  color: #fff;
  background-color: #c00f3c;
  width: 54px;
  height: 54px;
  font-size: 13px;
  line-height: 54px;
  text-align: center;
  position: absolute;
  font-weight: 400;
  padding: 0;
  right: 10px;
  top: 12px;
  transform: rotate(14deg);
  box-shadow: 0 0 2px rgba(255, 255, 255, 0.1);
  border-radius: 50%;
}

@media (min-width: 992px) {
  .pricing-table.active {
    margin-top: -20px;
  }
  .pricing-table.active header {
    padding-top: 30px;
    padding-bottom: 23px;
  }
  .pricing-table.active header .pricing-title {
    font-weight: 400;
    padding-bottom: 29px;
    margin-bottom: 21px;
  }
}

.pricing-table.dark header {
  background-color: #171717;
}

.pricing-table.dark header .pricing-title {
  border-bottom-color: #444;
}

.pricing-table.dark .badge.hot {
  background-color: #0069b0;
}

/* ----------- 5.Headers ---------------
--------------------------------------*/
/* Header Main */
.header {
  background-color: #fff;
  position: relative;
}

.header a {
  color: #222;
}

.header a:hover, .header a:focus {
  color: #0069b0;
}

.header .header-dropdown .dropdown-menu {
  border-radius: 0;
  border: 1px solid #eee;
  font-size: 13px;
  margin-top: 0;
  box-shadow: 2px 3px 3px 0 rgba(0, 0, 0, 0.1), -2px 3px 3px 0 rgba(0, 0, 0, 0.1);
}

.header .header-dropdown .dropdown-menu > li > a {
  padding: 4px 13px;
}

.header .header-dropdown .dropdown-menu > li > a:hover, .header .header-dropdown .dropdown-menu > li > a:focus {
  color: #fff;
  background-color: #0069b0;
}

.header .header-dropdown.open .dropdown-menu, .header .header-dropdown:hover .dropdown-menu, .header .header-dropdown:focus .dropdown-menu {
  display: block;
}

/* Header Top */
.header-top {
  border-bottom: 1px solid #eee;
  font-size: 12px;
}

.header-top .header-dropdown > a {
  display: inline-block;
  padding: 7.5px 13px;
  color: #444;
  background-color: #f0f0fa;
  border-left: 1px solid #eee;
  border-bottom: 1px solid #eee;
  margin-bottom: -1px;
}

.header-top .header-dropdown .dropdown-menu {
  padding: 0;
  min-width: 100%;
  font-size: 12px;
  border: none;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1);
}

.header-top .header-dropdown + .top-menu {
  margin-right: 25px;
}

.header-top .header-dropdown + .top-links {
  margin-right: 15px;
}

@media (min-width: 768px) {
  .header-top .header-dropdown + .top-links {
    margin-right: 25px;
  }
}

.header-top .header-dropdown + .header-dropdown {
  margin-left: -1px;
}

.header-top .header-dropdown:nth-of-type(2n) > a {
  background-color: #f6f6fa;
}

.header-top .header-dropdown.open > a, .header-top .header-dropdown:hover > a, .header-top .header-dropdown:focus > a {
  background-color: #0069b0;
  border-color: #0069b0;
  color: #fff;
}

.header-top .top-menu {
  font-size: 0;
}

@media (max-width: 767px) {
  .header-top .top-menu {
    display: none;
  }
}

.header-top .top-menu li {
  font-size: 12px;
  display: inline-block;
}

.header-top .top-menu li a {
  display: inline-block;
  padding: 7px 0;
  position: relative;
}

.header-top .top-menu li a:before {
  content: '';
  display: block;
  height: 2px;
  position: absolute;
  bottom: -1px;
  left: 50%;
  right: 50%;
  background-color: #0069b0;
  transition: all 0.3s;
}

.header-top .top-menu li + li:before {
  content: '•';
  margin: 0 7px;
  color: #6a6a6a;
}

.header-top .top-menu li.active a, .header-top .top-menu li:hover a, .header-top .top-menu li:focus a {
  color: #0069b0;
}

.header-top .top-menu li.active a:before, .header-top .top-menu li:hover a:before, .header-top .top-menu li:focus a:before {
  left: 0;
  right: 0;
}

.header-top .top-text {
  display: none;
  margin: 7px 0;
  color: #666;
}

@media (min-width: 992px) {
  .header-top .top-text {
    display: block;
  }
  .header-top .top-text + .top-links {
    margin-left: 15px;
  }
}

.header-top .top-links {
  margin: 7px 0;
  font-size: 0;
}

.header-top .top-links a {
  color: #0069b0;
  display: inline-block;
  font-size: 12px;
}

.header-top .top-links a:hover, .header-top .top-links a:focus {
  color: #323232;
}

.header-top .top-links a + a:before {
  content: '/';
  margin: 0 5px;
  color: #6a6a6a;
}

.header-top .social-icons .social-icon {
  display: inline-block;
  line-height: 35px;
  min-width: 36px;
  text-align: center;
  color: #aeaeae;
  border-right: 1px solid #eee;
  margin: 0;
  transition: all 0.4s;
}

.header-top .social-icons .social-icon:first-child {
  border-left: 1px solid #eee;
}

.header-top .social-icons .social-icon:hover, .header-top .social-icons .social-icon:focus {
  background-color: #0069b0;
  color: #fff;
  border-color: #0069b0;
}

@media (min-width: 768px) {
  .header-top .social-icons + .top-links {
    margin-left: 20px;
  }
}

.fixed {
  /* Site Logo */
}

.fixed .site-logo {
  display: block;
  float: left;
  margin: 8px 0;
}

.fixed .site-logo img {
  display: block;
  max-width: 100%;
  height: 50px;
}

.fixed .navbar-nav {
  float: none;
  margin: 10px 0;
}

/* Site Logo */
.site-logo {
  display: block;
  float: left;
  margin: 8px 0;
}

.site-logo img {
  display: block;
  max-width: 100%;
  height: 80px;
}

.header-info-boxes {
  font-size: 0;
  float: right;
  margin-top: 35px;
}

.cart-dropdown + .header-info-boxes {
  margin-right: 15px;
}

@media (max-width: 991px) {
  .header-info-boxes {
    display: none;
  }
}

.header-info-boxes .header-info-box {
  display: inline-block;
  position: relative;
  padding-left: 50px;
  font-size: 12px;
  line-height: 1;
}

.header-info-boxes .header-info-box i {
  font-size: 16px;
  line-height: 37px;
  width: 36px;
  height: 36px;
  margin-top: -18px;
  text-align: center;
  position: absolute;
  left: 0;
  top: 50%;
  color: #5e5e5e;
  background-color: #f5f5f5;
  border-radius: 50%;
}

.header-info-boxes .header-info-box i.custom {
  background-color: #0069b0;
  color: #fff;
}

.header-info-boxes .header-info-box i.dark {
  background-color: #3b3b3b;
  color: #fff;
}

.header-info-boxes .header-info-box .info-box-title {
  font-size: 13px;
  text-transform: uppercase;
  color: #333;
  margin-top: 2px;
  margin-bottom: 4px;
  font-weight: 600;
}

.header-info-boxes .header-info-box p {
  margin-bottom: 0;
}

.header-info-boxes .header-info-box + .header-info-box {
  margin-left: 49px;
}

.header-info-boxes .header-info-box + .header-info-box:before {
  content: '';
  display: block;
  position: absolute;
  left: -25px;
  top: 50%;
  width: 1px;
  height: 24px;
  margin-top: -12px;
  background-color: #eaeaea;
}

/* Header Bottom */
.header-bottom {
  background-color: #f6f6fa;
}

/* Search Form Dropdown */
.dropdown.search-dropdown .dropdown-menu {
  min-width: 260px;
  padding: 11px 12px;
}

.dropdown.search-dropdown .dropdown-menu form {
  position: relative;
}

.dropdown.search-dropdown .dropdown-menu .form-control {
  margin-bottom: 0;
  padding-right: 53px;
  border-color: #e1e1e1;
}

.dropdown.search-dropdown .dropdown-menu .btn {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 14px;
  padding: 8px 5px;
  text-align: center;
  min-width: 38px;
}

.dropdown.search-dropdown .dropdown-menu .btn i {
  margin: 0;
}

/* Cart Dropdown */
.dropdown.cart-dropdown {
  float: right;
  /* Gray Cart Icon */
  /* Dark Cart Icon */
}

@media (max-width: 767px) {
  .dropdown.cart-dropdown {
    position: static;
  }
  .navbar-nav .dropdown.cart-dropdown {
    float: none;
    position: relative;
  }
}

.dropdown.cart-dropdown > a {
  position: relative;
  display: inline-block;
  font-size: 36px;
  line-height: 1;
  color: #0069b0;
  padding-top: 30px;
  padding-bottom: 35px;
  padding-left: 30px;
}

.navbar-nav .dropdown.cart-dropdown > a {
  font-size: 28px;
  padding: 7px 15px;
}

@media (max-width: 767px) {
  .navbar-nav .dropdown.cart-dropdown > a {
    display: block;
  }
}

.dropdown.cart-dropdown > a > span {
  position: relative;
  display: inline-block;
}

.dropdown.cart-dropdown > a .dcart-total-count {
  display: inline-block;
  transition: all 0.4s;
  min-width: 14px;
  position: absolute;
  left: 50%;
  bottom: 5px;
  transform: translateX(-50%);
  border-radius: 50%;
  background-color: #fff;
  color: #0069b0;
  text-align: center;
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
}

.navbar-nav .dropdown.cart-dropdown > a .dcart-total-count {
  bottom: 2px;
}

.dropdown.cart-dropdown.open > a, .dropdown.cart-dropdown:hover > a, .dropdown.cart-dropdown:focus > a {
  color: #005d9c;
}

.dropdown.cart-dropdown.gray > a {
  color: #c7c7c7;
}

.dropdown.cart-dropdown.gray > a .dcart-total-count {
  background-color: #0069b0;
  color: #fff;
}

.dropdown.cart-dropdown.gray.open > a, .dropdown.cart-dropdown.gray:hover > a, .dropdown.cart-dropdown.gray:focus > a {
  color: #0069b0;
}

.dropdown.cart-dropdown.gray.open > a .dcart-total-count, .dropdown.cart-dropdown.gray:hover > a .dcart-total-count, .dropdown.cart-dropdown.gray:focus > a .dcart-total-count {
  background-color: #fff;
  color: #0069b0;
}

.dropdown.cart-dropdown.dark > a {
  color: #333;
}

.dropdown.cart-dropdown.dark > a .dcart-total-count {
  background-color: #fff;
  color: #444;
}

.dropdown.cart-dropdown.dark.open > a, .dropdown.cart-dropdown.dark:hover > a, .dropdown.cart-dropdown.dark:focus > a {
  color: #202020;
}

.dropdown.cart-dropdown .dropdown-menu {
  left: 0;
  right: 0;
  padding: 15px 18px 18px;
  width: auto;
}

@media (min-width: 768px) {
  .dropdown.cart-dropdown .dropdown-menu {
    left: auto;
    right: 0;
    width: 320px;
  }
}

.dropdown.cart-dropdown .dropdown-menu > p {
  font-size: 13px;
  margin-bottom: 13px;
}

.dropdown.cart-dropdown .dropdown-menu > p span {
  font-weight: 600;
  color: #323232;
  font-size: 15px;
}

.dropdown.cart-dropdown .dcart-products {
  padding: 20px 0 2px;
  margin-bottom: 18px;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
}

.dropdown.cart-dropdown .product {
  position: relative;
  padding: 0;
  margin-bottom: 18px;
  border: none;
}

.dropdown.cart-dropdown .product:after {
  content: '';
  display: table;
  clear: both;
}

.dropdown.cart-dropdown .product figure {
  float: left;
}

.dropdown.cart-dropdown .product figure img {
  width: 80px !important;
  height: auto !important;
}

.dropdown.cart-dropdown .product .product-meta {
  padding-left: 100px;
}

.dropdown.cart-dropdown .product .product-title {
  font-size: 13px;
  max-width: 150px;
  margin-bottom: 6px;
}

.dropdown.cart-dropdown .product .product-quantity {
  margin-bottom: 0;
  font-size: 12px;
  color: #6a6a6a;
}

.dropdown.cart-dropdown .product .product-price-container {
  margin-bottom: 0;
  position: static;
  left: auto;
  bottom: auto;
  line-height: 1.1;
}

.dropdown.cart-dropdown .product .product-price-container .product-old-price,
.dropdown.cart-dropdown .product .product-price-container .product-price {
  font-size: 14px;
}

.dropdown.cart-dropdown .product .product-price-container .product-old-price + .product-price {
  margin-top: 4px;
}

.dropdown.cart-dropdown .product .delete-btn {
  position: absolute;
  right: 0;
  top: 50%;
  margin-top: -13px;
}

.dropdown.cart-dropdown .product.last-child {
  margin-bottom: 0;
}

.dropdown.cart-dropdown .dcart-action-container:after {
  content: '';
  display: table;
  clear: both;
}

.dropdown.cart-dropdown .dcart-action-info {
  padding-top: 0;
  float: left;
  font-size: 13px;
  font-weight: 400;
}

.dropdown.cart-dropdown .dcart-action-info p {
  margin-bottom: 0;
  line-height: 1.6;
  letter-spacing: 0.01em;
}

.dropdown.cart-dropdown .dcart-action-info p span {
  font-weight: 600;
  font-family: "Open Sans", Arial, sans-serif;
  color: #323232;
  font-size: 15px;
}

.dropdown.cart-dropdown .dcart-action-info p:last-child {
  margin-bottom: 0;
}

.dropdown.cart-dropdown .dcart-action-info p:last-child span {
  font-weight: 700;
  color: #f41d1d;
}

.dropdown.cart-dropdown .dcart-action {
  float: right;
  width: 110px;
}

.dropdown.cart-dropdown .dcart-action .btn {
  display: block;
  font-size: 12px;
}

.dropdown.cart-dropdown .dcart-action .btn + .btn {
  margin-top: 4px;
}

/* Menu Styles */
/* Navbar Nav - Menu */
@media (min-width: 768px) {
  #main-nav-container {
    position: relative;
  }
}

@media (max-width: 767px) {
  #main-nav-container {
    width: 100%;
    margin: 0 !important;
  }
  #main-nav-container.max-height {
    max-height: 380px;
  }
}

#main-nav-container .container {
  padding: 0;
}

@media (min-width: 768px) {
  #main-nav-container .container {
    width: 720px;
    padding: 0;
  }
  #main-nav-container .container [class*="col-"] {
    padding: 0;
  }
}

@media (min-width: 992px) {
  #main-nav-container .container {
    width: 940px;
  }
}

@media (min-width: 1200px) {
  #main-nav-container .container {
    width: 1140px;
  }
}

#main-nav-container .container-fluid {
  padding: 0;
}

@media (min-width: 768px) {
  #main-nav-container .container-fluid [class*="col-"] {
    padding: 0;
  }
}

/* Menu - Navbar Nav */
.navbar-nav {
  margin: 0 0 15px;
}

.navbar-nav .dropdown-menu > li.active a {
  color: #0069b0 !important;
  background-color: #f0f0f6 !important;
}

.navbar-nav a {
  color: #222;
}

.navbar-nav > li > a {
  padding-top: 10px;
  padding-bottom: 10px;
  line-height: 20px;
}

.navbar-nav > li li a > span {
  position: relative;
}

@media (max-width: 767px) {
  .navbar-nav {
    background-color: #e6e6e6;
  }
  .navbar-nav > li.pull-right {
    float: none !important;
  }
  .navbar-nav > li .visible-inline-xs {
    margin-right: 7px;
  }
  .navbar-nav > li.open > a, .navbar-nav > li.open > a:hover, .navbar-nav > li.open > a:focus {
    background-color: #f1f1f1;
  }
  .navbar-nav > li > a:hover, .navbar-nav > li > a:focus {
    background-color: #f1f1f1;
  }
  .navbar-nav .open .dropdown-menu {
    position: static;
    float: none;
    width: auto;
    margin-top: 0;
    background-color: transparent;
    border: 0;
    box-shadow: none;
  }
  .navbar-nav .open .dropdown-menu > li > a,
  .navbar-nav .open .dropdown-menu .dropdown-header {
    padding: 5px 15px 5px 25px;
  }
  .navbar-nav .open .dropdown-menu > li > a {
    line-height: 20px;
  }
  .navbar-nav .open .dropdown-menu > li > a:hover, .navbar-nav .open .dropdown-menu > li > a:focus {
    background-image: none;
  }
  .navbar-nav .open .dropdown-menu li li a {
    padding-left: 35px;
  }
  .navbar-nav .open .dropdown-menu li li li a {
    padding-left: 45px;
  }
}

@media (min-width: 768px) {
  .navbar-nav {
    float: none;
    margin: 0;
  }
  .navbar-nav > li {
    float: left;
    /* Reverse Dropdown Menu - To Prevernt Overflow */
  }
  .navbar-nav > li .visible-inline-xs {
    display: none;
  }
  .navbar-nav > li + li {
    margin-left: 2px;
  }
  .navbar-nav > li.reverse .dropdown-menu {
    left: auto;
    right: 0;
  }
  .navbar-nav > li.reverse .dropdown-menu .dropdown-menu {
    left: auto;
    right: 100%;
  }
  .navbar-nav > li > a {
    position: relative;
    padding: 12px 15px;
    font-weight: 600;
  }
  .navbar-nav > li > a:hover, .navbar-nav > li > a:focus {
    background: inherit;
    color: inherit;
  }
  .navbar-nav > li > a:before {
    content: '';
    display: block;
    height: 2px;
    position: absolute;
    top: 0;
    left: 50%;
    right: 50%;
    background-color: #0069b0;
    transition: all 0.3s;
  }
  .navbar-nav > li.open > a, .navbar-nav > li.open > a:hover, .navbar-nav > li.open > a:focus, .navbar-nav > li.active > a, .navbar-nav > li.active > a:hover, .navbar-nav > li.active > a:focus {
    background: #fff;
    color: #0069b0;
  }
  .navbar-nav > li.open > a:before, .navbar-nav > li.open > a:hover:before, .navbar-nav > li.open > a:focus:before, .navbar-nav > li.active > a:before, .navbar-nav > li.active > a:hover:before, .navbar-nav > li.active > a:focus:before {
    left: 0;
    right: 0;
  }
  .navbar-nav > li.megamenu-container {
    position: static;
  }
  .container-fluid .navbar-nav > li.megamenu-container .megamenu {
    right: 15px;
  }
  .navbar-nav > li .dropdown-menu {
    box-shadow: 2px 3px 3px 0 rgba(0, 0, 0, 0.1), -2px 3px 3px 0 rgba(0, 0, 0, 0.1);
  }
  .navbar-nav > li .dropdown-menu.megamenu {
    box-shadow: 3px 3px 3px 0 rgba(0, 0, 0, 0.1), -3px 3px 3px 0 rgba(0, 0, 0, 0.1);
  }
  .navbar-nav > li .dropdown-menu .dropdown-menu {
    top: -4px;
    left: 100%;
    box-shadow: 2px 3px 3px 0 rgba(0, 0, 0, 0.1), -1px 1px 3px 0 rgba(0, 0, 0, 0.1);
  }
}

.navbar-nav .angle {
  margin: 0 0 0 3px;
  display: inline-block;
  font: normal normal normal 13px/18px FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translate(0, 0);
}

@media (max-width: 767px) {
  .navbar-nav .angle {
    float: right;
  }
}

.navbar-nav .angle:before {
  content: "\f107";
}

.navbar-nav .dropdown-menu {
  float: left;
  min-width: 180px;
  padding: 4px 0;
  margin: 0;
  font-size: 13px;
  text-align: left;
  list-style: none;
  min-width: 190px;
  border: none;
  border-radius: 0;
  background: linear-gradient(to bottom, #fff 0%, #eaeaea 100%);
  background-color: #fff;
}

.navbar-nav .dropdown-menu > li > a {
  color: #222;
  padding: 6px 15px;
  transition: all 0.1s;
  white-space: normal;
}

.navbar-nav .dropdown-menu > li > a:hover, .navbar-nav .dropdown-menu > li > a:focus {
  color: #fff;
  background-color: #0069b0;
}

.navbar-nav .dropdown-menu > li.open > a, .navbar-nav .dropdown-menu > li.open > a:hover, .navbar-nav .dropdown-menu > li.open > a:focus {
  color: #fff;
  background-color: #0069b0;
}

.navbar-nav .dropdown-menu.megamenu {
  background-position: bottom right !important;
  background-repeat: no-repeat !important;
}

.navbar-nav .dropdown-menu.megamenu .row {
  margin: 0;
}

@media (max-width: 767px) {
  .navbar-nav .dropdown-menu.megamenu [class*="col-"] {
    padding-left: 0;
    padding-right: 0;
  }
  .navbar-nav .dropdown-menu.megamenu [class*="col-"]:not(:last-child) {
    padding-bottom: 8px;
    margin-bottom: 8px;
    border-bottom: 1px solid #eee;
  }
}

.navbar-nav .dropdown-menu.megamenu a {
  position: relative;
  display: block;
  padding: 4px 15px;
  transition: all 0.1s;
}

.navbar-nav .dropdown-menu.megamenu a > span {
  position: relative;
}

.navbar-nav .dropdown-menu.megamenu a:hover, .navbar-nav .dropdown-menu.megamenu a:focus {
  color: #fff;
  background-color: #0069b0;
}

.navbar-nav .dropdown-menu.megamenu .megamenu-title {
  position: relative;
  font-weight: 600;
  padding-left: 30px;
}

.navbar-nav .dropdown-menu.megamenu .megamenu-title:before {
  content: '';
  position: absolute;
  left: 15px;
  top: 50%;
  display: block;
  height: 1px;
  margin-top: -0.5px;
  width: 7px;
  background-color: #222;
}

.navbar-nav .dropdown-menu.megamenu .megamenu-title:hover:before, .navbar-nav .dropdown-menu.megamenu .megamenu-title:focus:before {
  background-color: #fff;
}

.navbar-nav .dropdown-menu .angle {
  float: right;
}

@media (min-width: 768px) {
  .navbar-nav .dropdown-menu .angle:before {
    content: '\f105';
  }
}

.navbar-collapse {
  padding: 0;
}

/* Mobile Menu Toggle Button */
.navbar-toggle,
.menu-toggle,
.sidemenu-toggle {
  float: left;
  padding: 5px 8px;
  margin-top: 8px;
  margin-right: 0;
  margin-bottom: 8px;
  background-color: #e6e6e6;
  border: 1px solid #e1e1e1;
  border-radius: 0;
  font-size: 0;
  color: #222;
  transition: all 0.4s;
}

.navbar-toggle:after,
.menu-toggle:after,
.sidemenu-toggle:after {
  content: '';
  display: table;
  clear: both;
}

.navbar-toggle .toggle-text,
.navbar-toggle .toggle-wrapper,
.menu-toggle .toggle-text,
.menu-toggle .toggle-wrapper,
.sidemenu-toggle .toggle-text,
.sidemenu-toggle .toggle-wrapper {
  display: inline-block;
  vertical-align: middle;
}

.navbar-toggle .toggle-text,
.menu-toggle .toggle-text,
.sidemenu-toggle .toggle-text {
  text-transform: uppercase;
  font-weight: 600;
  margin-right: 7px;
  font-size: 13px;
}

.navbar-toggle .icon-bar,
.menu-toggle .icon-bar,
.sidemenu-toggle .icon-bar {
  display: block;
  width: 22px;
  height: 2px;
  border-radius: 0;
  background-color: #222;
}

.navbar-toggle .icon-bar + .icon-bar,
.menu-toggle .icon-bar + .icon-bar,
.sidemenu-toggle .icon-bar + .icon-bar {
  margin-top: 3px;
}

.navbar-toggle.dark,
.menu-toggle.dark,
.sidemenu-toggle.dark {
  background-color: #3d3d3d;
  border-color: #3d3d3d;
  color: #fff;
}

.navbar-toggle.dark .icon-bar,
.menu-toggle.dark .icon-bar,
.sidemenu-toggle.dark .icon-bar {
  background-color: #fff;
}

.navbar-toggle:hover, .navbar-toggle:focus,
.menu-toggle:hover,
.menu-toggle:focus,
.sidemenu-toggle:hover,
.sidemenu-toggle:focus {
  color: #fff;
  background-color: #0069b0;
  border-color: #0069b0;
}

.navbar-toggle:hover .icon-bar, .navbar-toggle:focus .icon-bar,
.menu-toggle:hover .icon-bar,
.menu-toggle:focus .icon-bar,
.sidemenu-toggle:hover .icon-bar,
.sidemenu-toggle:focus .icon-bar {
  background-color: #fff;
}

/* Menu-Navbar Tip - Hot - New - Popular */
.tip {
  display: inline-block;
  position: absolute;
  right: 50%;
  margin-right: -28px;
  top: -8px;
  font-size: 11px;
  font-weight: 400;
  line-height: 0.9;
  letter-spacing: 0.03em;
  padding: 3px 5px 4px;
  color: #fff;
  background-color: #0069b0;
}

@media (max-width: 767px) {
  .tip {
    right: auto;
    left: 48px;
    margin-right: 0;
    top: -5px;
    /* Hide tips for first li elems on mobile */
  }
  .navbar-nav > li > a > .tip {
    display: none;
  }
}

.tip:after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -4px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 5px 0 0;
  border-color: #0069b0 transparent transparent transparent;
}

.tip.hot {
  background-color: #E91E63;
}

.tip.hot:after {
  border-top-color: #E91E63;
}

.tip.new {
  background-color: #4CAF50;
}

.tip.new:after {
  border-top-color: #4CAF50;
}

.tip.soon {
  background-color: #FBC02D;
}

.tip.soon:after {
  border-top-color: #FBC02D;
}

.navbar-nav li li .tip,
.side-menu .tip {
  right: auto;
  left: 100%;
  margin-left: 10px;
  margin-right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.navbar-nav li li .tip:after,
.side-menu .tip:after {
  top: 50%;
  left: auto;
  right: 100%;
  margin-left: 0;
  margin-top: -3px;
  border-width: 0 0 5px 5px;
  border-color: transparent transparent #0069b0 transparent;
}

.navbar-nav li li .tip.hot:after,
.side-menu .tip.hot:after {
  border-bottom-color: #E91E63;
}

.navbar-nav li li .tip.new:after,
.side-menu .tip.new:after {
  border-bottom-color: #4CAF50;
}

.navbar-nav li li .tip.soon:after,
.side-menu .tip.soon:after {
  border-bottom-color: #FBC02D;
}

/* Header Version 2 */
.header.header2:after {
  content: '';
  display: table;
  clear: both;
}

@media (min-width: 768px) {
  .header.header2 .header-inner .container {
    position: relative;
  }
}

@media (min-width: 768px) {
  .header.header2 .navbar-collapse {
    float: right;
    position: static !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .header.header2 .navbar-collapse {
    display: block;
    float: left;
    width: 100%;
  }
}

.header.header2 .dropdown.header-dropdown .dropdown-menu {
  border-color: #f0f0f6;
}

@media (max-width: 767px) {
  .header.header2 .dropdown.cart-dropdown > a {
    padding-left: 15px;
  }
}

@media (min-width: 768px) {
  .header.header2 .navbar-nav > li {
    padding-bottom: 8px;
  }
}

@media (min-width: 992px) {
  .header.header2 .navbar-nav > li {
    padding-top: 33px;
    padding-bottom: 33px;
  }
}

@media (min-width: 768px) {
  .header.header2 .navbar-nav > li > a {
    border-radius: 20px;
    padding-top: 8px;
    padding-bottom: 8px;
    position: relative;
  }
  .header.header2 .navbar-nav > li > a:before {
    display: none;
  }
}

.header.header2 .navbar-nav > li.open > a, .header.header2 .navbar-nav > li.open > a:hover, .header.header2 .navbar-nav > li.open > a:focus, .header.header2 .navbar-nav > li.active > a, .header.header2 .navbar-nav > li.active > a:hover, .header.header2 .navbar-nav > li.active > a:focus {
  background: #f0f0f6;
}

@media (min-width: 768px) {
  .header.header2 .navbar-nav .dropdown-menu {
    border-top: 1px solid #f0f0f6;
  }
}

@media (min-width: 768px) {
  .header.header2 .navbar-nav .dropdown-menu.megamenu {
    left: 15px;
  }
}

.header.header2 .navbar-nav .dropdown.cart-dropdown > a {
  font-size: 20px;
  color: inherit;
}

.header.header2 .navbar-nav .dropdown.cart-dropdown > a .dcart-total-count {
  bottom: -2px;
  left: auto;
  right: -6px;
  transform: none;
  background-color: #0069b0;
  color: #fff;
}

.header.header2 .navbar-toggle {
  margin-top: 35px;
  margin-bottom: 35px;
  float: right;
  padding: 4px 5px;
}

.header.header2 .navbar-toggle .toggle-text {
  margin-right: 5px;
}

.header.header2 .navbar-toggle .icon-bar {
  width: 20px;
}

@media (max-width: 991px) and (min-width: 768px) {
  .header.header2.header-nocollapse-sm .navbar-collapse {
    float: right !important;
    width: auto;
  }
  .header.header2.header-nocollapse-sm .navbar-collapse .navbar-nav > li {
    padding-top: 33px;
    padding-bottom: 33px;
  }
}

.header.header2.transparent {
  background-color: transparent;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  z-index: 999;
  border-bottom: 1px solid rgba(0, 0, 0, 0.13);
}

.header.header2.transparent.light {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

@media (min-width: 768px) {
  .header.header2.transparent.light .navbar-nav > li > a {
    color: #fff;
  }
}

@media (min-width: 768px) {
  .header.header2.transparent.light .navbar-nav > li.open > a, .header.header2.transparent.light .navbar-nav > li.open > a:hover, .header.header2.transparent.light .navbar-nav > li.open > a:focus, .header.header2.transparent.light .navbar-nav > li.active > a, .header.header2.transparent.light .navbar-nav > li.active > a:hover, .header.header2.transparent.light .navbar-nav > li.active > a:focus {
    background: #fff;
    color: #0069b0;
  }
}

/* Header Boxed Absolute position */
.header-container > .container {
  position: relative;
}

.header.header2.header-boxed {
  position: absolute;
  left: 15px;
  top: 25px;
  right: 15px;
  z-index: 999;
  padding-left: 15px;
  padding-right: 15px;
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.2);
}

@media (min-width: 768px) {
  .header.header2.header-boxed {
    top: 40px;
  }
}

.header.header2.header-boxed.header-inner {
  content: '';
  display: table;
  clear: both;
}

@media (min-width: 768px) {
  .header.header2.header-boxed .navbar-nav .dropdown-menu.megamenu {
    left: 0;
  }
}

.header.header2.header-boxed.radius {
  border-radius: 60px;
}

@media (min-width: 768px) {
  .header.header2.header-boxed.radius {
    padding-left: 20px;
    padding-right: 20px;
  }
}

/* Header Version 3 */
.header.header3 .header-inner .container {
  position: relative;
  display: table;
  table-layout: fixed;
}

@media (max-width: 767px) {
  .header.header3 .header-inner .container {
    width: 100%;
  }
}

.header.header3 .header-left,
.header.header3 .header-center,
.header.header3 .header-right {
  display: table-cell;
  vertical-align: middle;
  width: 33.3%;
}

.header.header3 .header-left:after,
.header.header3 .header-center:after,
.header.header3 .header-right:after {
  content: '';
  display: table;
  clear: both;
}

.header.header3 .social-icons .social-icon {
  font-size: 13px;
  color: #7e7e7e;
  margin: 0 4px;
  min-width: 28px;
  line-height: 28px;
  border-radius: 50%;
  background-color: #f2f2fa;
  text-align: center;
  transition: all 0.4s;
}

.header.header3 .social-icons .social-icon:hover, .header.header3 .social-icons .social-icon:focus {
  color: #fff;
  background-color: #0069b0;
}

.header.header3 .social-icons.pull-left .social-icon:first-child {
  margin-left: 0;
}

.header.header3 .social-icons.pull-right .social-icon:last-child {
  margin-right: 0;
}

.header.header3 .header-center {
  text-align: center;
}

@media (max-width: 767px) {
  .header.header3 .header-center {
    width: 100%;
    padding: 33px 0;
  }
}

.header.header3 .site-logo {
  display: inline-block;
  float: none;
  line-height: 0;
  margin: 0;
}

.header.header3 .header-left .cart-dropdown {
  float: left;
}

.header.header3 .header-left .cart-dropdown > a {
  padding-left: 0;
  padding-right: 20px;
}

.header.header3 .header-left .cart-dropdown .dropdown-menu {
  right: auto;
  left: 0;
}

@media (max-width: 767px) {
  .header.header3 .header-left,
  .header.header3 .header-right {
    width: 1%;
  }
}

@media (max-width: 767px) {
  .header.header3 .header-left .cart-dropdown,
  .header.header3 .header-right .cart-dropdown {
    position: absolute;
    top: 100%;
    right: 15px;
  }
  .header.header3 .header-left .cart-dropdown > a,
  .header.header3 .header-right .cart-dropdown > a {
    padding: 7px 0 0;
  }
  .header.header3 .header-left .cart-dropdown .dropdown-menu,
  .header.header3 .header-right .cart-dropdown .dropdown-menu {
    left: auto;
    right: 0;
    width: 280px;
  }
}

.header.header3 .navbar-nav {
  font-size: 0;
}

@media (min-width: 768px) {
  .header.header3 .navbar-nav {
    text-align: center;
  }
}

.header.header3 .navbar-nav li {
  font-size: 13px;
}

@media (min-width: 768px) {
  .header.header3 .navbar-nav > li {
    float: none;
    display: inline-block;
  }
}

/* Header Version 4 */
.header4 .header-inner .menu-toggle {
  float: right;
  margin: 34px 0;
  margin-left: 40px;
}

@media (max-width: 767px) {
  .header4 .header-inner .menu-toggle {
    padding-top: 8px;
    padding-bottom: 8px;
    margin: 36px 0 36px 20px;
  }
}

@media (max-width: 767px) {
  .header4 .header-inner .menu-toggle .toggle-text {
    display: none;
  }
}

.header4 .header-inner .menu-toggle .icon-bar {
  width: 20px;
}

.header4 .header-inner .menu-toggle.jicon {
  padding-top: 8px;
  padding-bottom: 8px;
  margin-top: 36px;
  margin-bottom: 36px;
}

.header4 .header-inner .menu-toggle.jicon .toggle-text {
  display: none;
}

.header4 .header-inner .menu-toggle.pull-left {
  margin-left: 0;
  margin-right: 25px;
}

@media (max-width: 767px) {
  .header4 .header-inner .menu-toggle.pull-left {
    margin-right: 20px;
  }
}

/* Navbar Nav - Overlay Menu */
.navbar-container {
  position: fixed;
  z-index: 9999;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: rgba(248, 248, 248, 0.82);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  padding: 40px 15px;
  text-align: center;
  overflow-y: auto;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.7s, visibility 0s 0.6s;
}

@media (min-width: 768px) {
  .navbar-container {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

.navbar-container.nav-open {
  opacity: 1;
  visibility: visible;
  transition: opacity 0.7s;
  -webkit-transition: opacity 0.7s;
}

.navbar-container .social-icons {
  display: block;
  margin-top: 40px;
  text-align: center;
}

.navbar-container .social-icons label {
  font-size: 13px;
  text-transform: uppercase;
  display: block;
  margin: 0 0 7px;
  font-weight: 700;
  color: #777;
}

.navbar-container .social-icons .social-icon {
  color: #222;
  background-color: #e3e3e6;
  min-width: 30px;
  line-height: 30px;
  margin: 0 2px;
  transition: all 0.4s;
}

.navbar-container .social-icons .social-icon:hover, .navbar-container .social-icons .social-icon:focus {
  color: #fff;
  background-color: #0069b0;
}

.navbar-container .menu-toggle {
  float: none !important;
  margin: 0 0 20px !important;
}

.navbar-container .menu-toggle .toggle-text {
  margin-right: 5px;
}

.navbar-container .menu-toggle i {
  font-size: 13px;
  vertical-align: middle;
}

/* Overlay Menu */
.navbar-nav.nav-overlay {
  max-width: 360px;
  margin-left: auto;
  margin-right: auto;
  background-color: transparent !important;
}

.header .navbar-nav.nav-overlay a {
  color: #222;
}

.navbar-nav.nav-overlay + .social-icons {
  margin-top: 20px;
}

.navbar-nav.nav-overlay > li {
  float: none !important;
  margin-bottom: 8px;
}

.navbar-nav.nav-overlay > li > a {
  padding-top: 9px;
  padding-bottom: 9px;
  font-weight: 700;
  font-size: 18px;
  text-transform: uppercase;
}

.navbar-nav.nav-overlay > li > a:before {
  display: none;
}

.navbar-nav.nav-overlay > li > a .tip {
  top: -11px;
  margin-right: -42px;
}

.navbar-nav.nav-overlay li i {
  font-size: 90%;
}

.navbar-nav.nav-overlay li a {
  display: inline-block;
  padding: 6px 0;
}

.navbar-nav.nav-overlay li li > a {
  font-size: 16px;
  font-weight: 600;
}

.navbar-nav.nav-overlay li li .tip {
  margin-left: 20px;
}

.navbar-nav.nav-overlay li li li a {
  font-size: 14px;
  font-weight: 400;
}

.navbar-nav.nav-overlay li:hover > a,
.navbar-nav.nav-overlay li.open > a,
.navbar-nav.nav-overlay li.active > a {
  color: #0069b0 !important;
  background-color: transparent !important;
}

.navbar-nav.nav-overlay li:hover > a:hover, .navbar-nav.nav-overlay li:hover > a:focus,
.navbar-nav.nav-overlay li.open > a:hover,
.navbar-nav.nav-overlay li.open > a:focus,
.navbar-nav.nav-overlay li.active > a:hover,
.navbar-nav.nav-overlay li.active > a:focus {
  color: #0069b0 !important;
  background-color: transparent !important;
}

.navbar-nav.nav-overlay .dropdown-menu > li > a:hover, .navbar-nav.nav-overlay .dropdown-menu > li > a:focus {
  color: inherit;
  background-color: inherit;
}

.navbar-nav.nav-overlay .angle {
  float: none;
  font-size: 90%;
}

.navbar-nav.nav-overlay .angle:before {
  content: "\f107";
}

.navbar-nav.nav-overlay .open > a > .angle:before {
  content: "\f106";
}

.navbar-nav.nav-overlay .dropdown-menu {
  padding-top: 3px;
  padding-bottom: 8px;
  float: none;
  background: transparent !important;
  box-shadow: none !important;
  text-align: center;
}

.navbar-nav.nav-overlay .dropdown-menu .dropdown-menu {
  display: none;
}

.navbar-nav.nav-overlay .dropdown-menu .open > .dropdown-menu {
  display: block;
}

.navbar-nav.nav-overlay .open .dropdown-menu {
  position: relative !important;
  top: auto;
  bottom: auto;
  left: auto;
  right: auto;
}

.navbar-nav.nav-overlay .search-dropdown > a > span {
  vertical-align: middle;
}

.navbar-nav.nav-overlay .search-dropdown > a > span + i {
  margin-left: 6px;
  vertical-align: middle;
}

/* Add animation to first level elements */
.navbar-nav.nav-overlay.each-anim > li {
  visibility: hidden;
  opacity: 0;
  transform: translateY(20px);
  transition: all 0.4s;
}

.nav-open .navbar-nav.nav-overlay.each-anim > li {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.nav-open .navbar-nav.nav-overlay.each-anim > li:nth-child(1) {
  transition-delay: 0.05s;
}

.nav-open .navbar-nav.nav-overlay.each-anim > li:nth-child(2) {
  transition-delay: 0.1s;
}

.nav-open .navbar-nav.nav-overlay.each-anim > li:nth-child(3) {
  transition-delay: 0.15s;
}

.nav-open .navbar-nav.nav-overlay.each-anim > li:nth-child(4) {
  transition-delay: 0.2s;
}

.nav-open .navbar-nav.nav-overlay.each-anim > li:nth-child(5) {
  transition-delay: 0.25s;
}

.nav-open .navbar-nav.nav-overlay.each-anim > li:nth-child(6) {
  transition-delay: 0.3s;
}

.nav-open .navbar-nav.nav-overlay.each-anim > li:nth-child(7) {
  transition-delay: 0.35s;
}

.nav-open .navbar-nav.nav-overlay.each-anim > li:nth-child(8) {
  transition-delay: 0.4s;
}

.nav-open .navbar-nav.nav-overlay.each-anim > li:nth-child(9) {
  transition-delay: 0.45s;
}

.nav-open .navbar-nav.nav-overlay.each-anim > li:nth-child(10) {
  transition-delay: 0.5s;
}

/* Navbar Nav - Overlay Menu Dark */
.navbar-container.dark {
  background-color: rgba(0, 0, 0, 0.82);
}

.header .navbar-container.dark a {
  color: #fff;
}

.navbar-container.dark .social-icons label {
  color: #b7b7b7;
}

.navbar-container.dark .social-icons .social-icon {
  color: #eee;
  background-color: #101010;
}

.navbar-container.dark .social-icons .social-icon:hover, .navbar-container.dark .social-icons .social-icon:focus {
  color: #fff;
  background-color: #0069b0;
}

.navbar-container.dark .menu-toggle {
  background-color: #101010;
  border-color: #101010;
  color: #fff;
}

.navbar-container.dark .menu-toggle:hover, .navbar-container.dark .menu-toggle:focus {
  color: #fff;
  background-color: #0069b0;
  border-color: #0069b0;
}

.navbar-container.dark .navbar-nav.nav-overlay li a {
  color: #fafafa;
}

.navbar-container.dark .navbar-nav.nav-overlay li:hover > a, .navbar-container.dark .navbar-nav.nav-overlay li.open > a, .navbar-container.dark .navbar-nav.nav-overlay li.active > a {
  color: #0069b0 !important;
  background-color: transparent !important;
}

.navbar-container.dark .navbar-nav.nav-overlay li:hover > a:hover, .navbar-container.dark .navbar-nav.nav-overlay li:hover > a:focus, .navbar-container.dark .navbar-nav.nav-overlay li.open > a:hover, .navbar-container.dark .navbar-nav.nav-overlay li.open > a:focus, .navbar-container.dark .navbar-nav.nav-overlay li.active > a:hover, .navbar-container.dark .navbar-nav.nav-overlay li.active > a:focus {
  color: #0069b0 !important;
  background-color: transparent !important;
}

.navbar-container.dark .navbar-nav.nav-overlay .search-dropdown .dropdown-menu .form-control {
  border-color: #171717;
  background-color: #171717;
  color: #777;
}

.navbar-container.dark .navbar-nav.nav-overlay .search-dropdown .dropdown-menu .form-control::placeholder {
  color: #777;
}

/* Header Version 5 */
@media (min-width: 992px) {
  body.side-menu-body {
    padding-left: 280px;
  }
  body.side-menu-body.reverse {
    padding-left: 0;
    padding-right: 280px;
  }
}

.header.header5 {
  position: relative;
  padding: 0 15px;
  background-color: #fff;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  z-index: 999;
}

@media (min-width: 992px) {
  .header.header5 {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    width: 280px;
    padding: 0;
    box-shadow: 2px 0 2px rgba(0, 0, 0, 0.1);
  }
  .reverse .header.header5 {
    left: auto;
    right: 0;
    box-shadow: -2px 0 2px rgba(0, 0, 0, 0.1);
  }
}

.header.header5 .header-wrapper:after {
  content: '';
  display: table;
  clear: both;
}

.header.header5 .header-top {
  float: left;
  border-bottom: none;
  background-color: transparent;
}

@media (min-width: 992px) {
  .header.header5 .header-top {
    float: none;
    padding: 0 20px;
    background-color: #f1f1f6;
    border-bottom: 1px solid #eaeaea;
    width: 100%;
    height: 140px;
  }
  .header.header5 .header-top.dark {
    background-color: #222;
    border-color: #111;
  }
  .header.header5 .header-top.custom {
    background-color: #0069b0;
    border-color: #0069b0;
  }
}

.header.header5 .sidemenu-toggle {
  float: right;
  margin: 34px 0;
}

@media (min-width: 992px) {
  .header.header5 .sidemenu-toggle {
    display: none;
  }
}

.header.header5 .site-logo {
  float: none;
}

@media (min-width: 992px) {
  .header.header5 .site-logo {
    display: inline-block;
    vertical-align: middle;
    margin: 0;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }
}

.header.header5 .header-inside {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 9999;
  padding: 0 20px 0 15px;
  display: none;
  background-color: rgba(255, 255, 255, 0.96);
  overflow-y: auto;
}

.header.header5 .header-inside .sidemenu-toggle {
  float: none;
  margin: 20px 0 10px;
}

.header.header5 .header-inside .sidemenu-toggle .toggle-text {
  margin-right: 4px;
}

.header.header5 .header-inside .sidemenu-toggle i {
  font-size: 13px;
  vertical-align: middle;
}

.header.header5 .header-inside.open {
  display: block;
}

@media (min-width: 992px) {
  .header.header5 .header-inside {
    position: relative;
    left: auto;
    right: auto;
    top: auto;
    bottom: auto;
    padding: 0 25px 0 20px;
    height: calc(100vh - 140px);
    display: block;
    background-color: transparent;
  }
  .header.header5 .header-inside .sidemenu-toggle {
    display: none;
  }
  .header.header5 .header-inside.full {
    height: 100vh;
  }
}

.header.header5 .header-inside-wrapper {
  padding-top: 10px;
  padding-bottom: 20px;
}

.header.header5 .header-inside-wrapper:before {
  content: '';
  display: table;
  clear: both;
}

@media (min-width: 992px) {
  .header.header5 .header-inside-wrapper {
    padding-top: 20px;
  }
}

.header.header5.dark {
  background-color: #171717;
}

@media (min-width: 992px) {
  .header.header5.dark .header-top:not(.custom) {
    background-color: #101010;
    border-color: #101010;
  }
}

@media (max-width: 991px) {
  .header.header5.dark .header-inside {
    background-color: rgba(0, 0, 0, 0.88);
  }
}

.header.header5.dark .sidemenu-toggle {
  color: #fff;
  background-color: #333;
  border-color: #333;
}

.header.header5.dark .sidemenu-toggle:hover, .header.header5.dark .sidemenu-toggle:focus {
  background-color: #0069b0;
  border-color: #0069b0;
}

.header.header5.dark .sidemenu-toggle .icon-bar {
  background-color: #fff;
}

/* Side Menu  - Metis menu */
.side-menu li a {
  position: relative;
  display: block;
  color: #222;
  padding: 3px 0;
  font-size: 13px;
}

.side-menu li a > span {
  position: relative;
}

.side-menu li.active > a,
.side-menu li.active > a:hover,
.side-menu li.active > a:focus, .side-menu li:hover > a,
.side-menu li:hover > a:hover,
.side-menu li:hover > a:focus, .side-menu li:focus > a,
.side-menu li:focus > a:hover,
.side-menu li:focus > a:focus {
  color: #0069b0;
}

.side-menu li ul {
  padding-left: 13px;
}

.side-menu li ul li:first-child {
  padding-top: 5px;
}

.side-menu li ul li:last-child {
  padding-bottom: 9px;
}

.side-menu > li > a {
  font-weight: 600;
  padding: 6px 0;
}

.side-menu .collapse {
  display: none;
}

.side-menu .collapse.in {
  display: block;
}

.side-menu .collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition-timing-function: ease;
  transition-duration: .35s;
  transition-property: height, visibility;
}

.side-menu .arrow {
  float: right;
  line-height: 1.42857;
}

*[dir="rtl"] .side-menu .arrow {
  float: left;
}

.side-menu .fa.arrow:before {
  content: "\f105";
}

.side-menu .active > a > .fa.arrow:before {
  content: "\f107";
}

/*Side Menu - Dark */
.header.dark .side-menu li a {
  color: #eee;
}

.header.dark .side-menu li.active > a,
.header.dark .side-menu li.active > a:hover,
.header.dark .side-menu li.active > a:focus, .header.dark .side-menu li:hover > a,
.header.dark .side-menu li:hover > a:hover,
.header.dark .side-menu li:hover > a:focus, .header.dark .side-menu li:focus > a,
.header.dark .side-menu li:focus > a:hover,
.header.dark .side-menu li:focus > a:focus {
  color: #0069b0;
}

/*Header Color Variants */
/* Header Top Gray*/
.header-top.gray {
  background-color: #f6f6fa;
}

/* Header Top - Custom*/
.header-top.custom {
  background-color: #0069b0;
  border-color: #0069b0;
}

.header-top.custom .header-dropdown > a {
  color: #f1f1f1;
  background-color: #006fba;
  border-color: #0078ca;
}

.header-top.custom .header-dropdown:nth-of-type(2n) > a {
  background-color: #0063a6;
}

.header-top.custom .header-dropdown.open > a, .header-top.custom .header-dropdown:hover > a, .header-top.custom .header-dropdown:focus > a {
  color: #fff;
  background-color: #005a97;
  border-color: #0063a6;
}

.header-top.custom .top-menu li a {
  color: #f1f1f1;
}

.header-top.custom .top-menu li a:before {
  background-color: #0094f7;
}

.header-top.custom .top-menu li + li:before {
  color: #005187;
}

.header-top.custom .top-menu li.active a, .header-top.custom .top-menu li:hover a, .header-top.custom .top-menu li:focus a {
  color: #17a1ff;
}

.header-top.custom .top-text {
  color: #f1f1f1;
}

.header-top.custom .top-links a {
  color: #eee;
}

.header-top.custom .top-links a:hover, .header-top.custom .top-links a:focus {
  color: #17a1ff;
}

.header-top.custom .top-links a + a:before {
  color: #eaeaea;
}

.header-top.custom .social-icons .social-icon {
  color: #fff;
  border-color: #007bcf;
}

.header-top.custom .social-icons .social-icon:hover, .header-top.custom .social-icons .social-icon:focus {
  background-color: #005a97;
  border-color: #005a97;
}

/* Header Top Dark */
.header-top.dark {
  background-color: #171717;
  border-color: #171717;
}

.header-top.dark .header-dropdown > a {
  color: #f1f1f1;
  background-color: #0069b0;
  border-color: #005d9c;
}

.header-top.dark .header-dropdown:nth-of-type(2n) > a {
  background-color: #006fba;
}

.header-top.dark .header-dropdown.open > a, .header-top.dark .header-dropdown:hover > a, .header-top.dark .header-dropdown:focus > a {
  color: #fff;
  background-color: #005a97;
  border-color: #0063a6;
}

.header-top.dark .top-menu li a {
  color: #f1f1f1;
}

.header-top.dark .top-menu li.active a, .header-top.dark .top-menu li:hover a, .header-top.dark .top-menu li:focus a {
  color: #0069b0;
}

.header-top.dark .top-text {
  color: #eee;
}

.header-top.dark .top-links a {
  color: #eee;
}

.header-top.dark .top-links a:hover, .header-top.dark .top-links a:focus {
  color: #0069b0;
}

.header-top.dark .top-links a + a:before {
  color: #eaeaea;
}

.header-top.dark .social-icons .social-icon {
  color: #fff;
  border-color: #4a4a4a;
}

.header-top.dark .social-icons .social-icon:hover, .header-top.dark .social-icons .social-icon:focus {
  border-color: #0069b0;
}

/* Header Bottom - Custom */
.header-bottom.custom {
  background-color: #0069b0;
}

.header-bottom.custom .navbar-toggle:hover, .header-bottom.custom .navbar-toggle:focus {
  background-color: #c00f3c;
  border-color: #c00f3c;
}

@media (min-width: 768px) {
  .header-bottom.custom .navbar-nav > li > a {
    color: #fff;
  }
  .header-bottom.custom .navbar-nav > li.open > a, .header-bottom.custom .navbar-nav > li.open > a:hover, .header-bottom.custom .navbar-nav > li.open > a:focus, .header-bottom.custom .navbar-nav > li.active > a, .header-bottom.custom .navbar-nav > li.active > a:hover, .header-bottom.custom .navbar-nav > li.active > a:focus {
    background: #fff;
    color: #0069b0;
  }
}

@media (min-width: 768px) {
  .header-bottom.custom .navbar-nav .cart-dropdown > a .dcart-total-count {
    background-color: #0069b0;
    color: #fff;
  }
}

.header-bottom.custom .navbar-nav .cart-dropdown.open > a .dcart-total-count, .header-bottom.custom .navbar-nav .cart-dropdown:hover > a .dcart-total-count, .header-bottom.custom .navbar-nav .cart-dropdown:focus > a .dcart-total-count {
  background-color: #fff;
  color: #0069b0;
}

/* Header Bottom - Dark */
.header-bottom.dark {
  background-color: #171717;
}

@media (min-width: 768px) {
  .header-bottom.dark .navbar-nav > li > a {
    color: #fff;
  }
  .header-bottom.dark .navbar-nav > li.open > a, .header-bottom.dark .navbar-nav > li.open > a:hover, .header-bottom.dark .navbar-nav > li.open > a:focus, .header-bottom.dark .navbar-nav > li.active > a, .header-bottom.dark .navbar-nav > li.active > a:hover, .header-bottom.dark .navbar-nav > li.active > a:focus {
    background: #fff;
    color: #222;
  }
}

@media (min-width: 768px) {
  .header-bottom.dark .navbar-nav .cart-dropdown > a .dcart-total-count {
    background-color: #0069b0;
    color: #fff;
  }
}

/* Header Gray */
.header.gray {
  background-color: #f8f8fa;
}

.header.gray .header-info-boxes .header-info-box i:not(.dark) {
  background-color: #ebebea;
}

/* Header Custom */
.header.custom {
  background-color: #0069b0;
  color: #eee;
  /* Side Menu  - Metis menu */
}

.header.custom a {
  color: #fff;
}

.header.custom .dropdown.header-dropdown .dropdown-menu {
  border-color: #005791;
  background-color: #0069b0;
  box-shadow: 2px 3px 3px 0 rgba(0, 0, 0, 0.1), -2px 3px 3px 0 rgba(0, 0, 0, 0.1);
}

.header.custom .dropdown.header-dropdown .dropdown-menu > li > a:hover, .header.custom .dropdown.header-dropdown .dropdown-menu > li > a:focus {
  color: #fff;
  background-color: #004b7d;
}

.header.custom .header-top:not(.dark) {
  border-color: #005a97;
  color: #fff;
}

.header.custom .header-top:not(.dark) .header-dropdown > a {
  color: #f1f1f1;
  background-color: #006fba;
  border-color: #0078ca;
}

.header.custom .header-top:not(.dark) .header-dropdown:nth-of-type(2n) > a {
  background-color: #0063a6;
}

.header.custom .header-top:not(.dark) .header-dropdown.open > a, .header.custom .header-top:not(.dark) .header-dropdown:hover > a, .header.custom .header-top:not(.dark) .header-dropdown:focus > a {
  color: #fff;
  background-color: #005a97;
  border-color: #0063a6;
}

.header.custom .header-top:not(.dark) .top-menu li a {
  color: #f1f1f1;
}

.header.custom .header-top:not(.dark) .top-menu li a:before {
  background-color: #0094f7;
}

.header.custom .header-top:not(.dark) .top-menu li + li:before {
  color: #005187;
}

.header.custom .header-top:not(.dark) .top-menu li.active a, .header.custom .header-top:not(.dark) .top-menu li:hover a, .header.custom .header-top:not(.dark) .top-menu li:focus a {
  color: #17a1ff;
}

.header.custom .header-top:not(.dark) .top-text {
  color: #f1f1f1;
}

.header.custom .header-top:not(.dark) .top-links a {
  color: #eee;
}

.header.custom .header-top:not(.dark) .top-links a:hover, .header.custom .header-top:not(.dark) .top-links a:focus {
  color: #17a1ff;
}

.header.custom .header-top:not(.dark) .top-links a + a:before {
  color: #eaeaea;
}

.header.custom .header-top:not(.dark) .social-icons .social-icon {
  color: #fff;
  border-color: #005a97;
}

.header.custom .header-top:not(.dark) .social-icons .social-icon:hover, .header.custom .header-top:not(.dark) .social-icons .social-icon:focus {
  background-color: #004b7d;
  border-color: #004b7d;
}

.header.custom .header-info-boxes .header-info-box .info-box-title {
  color: #eee;
}

.header.custom .header-info-boxes .header-info-box i:not(.dark) {
  background-color: #fafafa;
  color: #0069b0;
}

.header.custom .header-info-boxes .header-info-box + .header-info-box:before {
  background-color: #00548c;
}

.header.custom .dropdown.cart-dropdown > a {
  color: #fafafa;
}

.header.custom .dropdown.cart-dropdown > a .dcart-total-count {
  background-color: #0069b0;
  color: #fff;
}

.header.custom .dropdown.cart-dropdown.open > a, .header.custom .dropdown.cart-dropdown:hover > a, .header.custom .dropdown.cart-dropdown:focus > a {
  color: #005187;
}

.header.custom .dropdown.cart-dropdown.open > a .dcart-total-count, .header.custom .dropdown.cart-dropdown:hover > a .dcart-total-count, .header.custom .dropdown.cart-dropdown:focus > a .dcart-total-count {
  background-color: #fff;
  color: #0069b0;
}

.header.custom .dropdown.cart-dropdown .dropdown-menu > p span {
  color: #eee;
}

.header.custom .dropdown.cart-dropdown .dropdown-menu .product-title a {
  color: #eaeaea;
}

.header.custom .dropdown.cart-dropdown .dropdown-menu .product-title a:hover, .header.custom .dropdown.cart-dropdown .dropdown-menu .product-title a:focus {
  color: #fff;
}

.header.custom .dropdown.cart-dropdown .dropdown-menu .product .product-quantity {
  color: #ccc;
}

.header.custom .dropdown.cart-dropdown .dropdown-menu .product .product-price-container .old-price {
  color: #eee;
}

.header.custom .dropdown.cart-dropdown .dropdown-menu .product .delete-btn {
  color: #fff;
  background-color: #005d9c;
}

.header.custom .dropdown.cart-dropdown .dropdown-menu .product .delete-btn:hover, .header.custom .dropdown.cart-dropdown .dropdown-menu .product .delete-btn:focus {
  background-color: #005187;
}

.header.custom .dropdown.cart-dropdown .dropdown-menu .dcart-action-info p span {
  color: #f1f1f1;
}

.header.custom .dropdown.cart-dropdown .dcart-products {
  border-color: #005187;
}

.header.custom .header-bottom:not(.dark) {
  background-color: #004b7d;
}

.header.custom .navbar-nav:not(.nav-overlay) a,
.header.custom .header-bottom .navbar-nav a {
  color: #fff;
}

@media (min-width: 768px) {
  .header.custom .navbar-nav:not(.nav-overlay) > li > a,
  .header.custom .header-bottom .navbar-nav > li > a {
    color: #fff;
  }
  .header.custom .navbar-nav:not(.nav-overlay) > li > a:before,
  .header.custom .header-bottom .navbar-nav > li > a:before {
    background-color: #004b7d;
  }
  .header.custom .navbar-nav:not(.nav-overlay) > li.open > a, .header.custom .navbar-nav:not(.nav-overlay) > li.open > a:hover, .header.custom .navbar-nav:not(.nav-overlay) > li.open > a:focus, .header.custom .navbar-nav:not(.nav-overlay) > li.active > a, .header.custom .navbar-nav:not(.nav-overlay) > li.active > a:hover, .header.custom .navbar-nav:not(.nav-overlay) > li.active > a:focus,
  .header.custom .header-bottom .navbar-nav > li.open > a,
  .header.custom .header-bottom .navbar-nav > li.open > a:hover,
  .header.custom .header-bottom .navbar-nav > li.open > a:focus,
  .header.custom .header-bottom .navbar-nav > li.active > a,
  .header.custom .header-bottom .navbar-nav > li.active > a:hover,
  .header.custom .header-bottom .navbar-nav > li.active > a:focus {
    background: #0069b0;
    color: #fff;
  }
}

@media (min-width: 768px) {
  .header.custom .navbar-nav:not(.nav-overlay) .cart-dropdown > a .dcart-total-count,
  .header.custom .header-bottom .navbar-nav .cart-dropdown > a .dcart-total-count {
    background-color: #0069b0;
    color: #fff;
  }
}

.header.custom .navbar-nav:not(.nav-overlay) .cart-dropdown.open > a .dcart-total-count, .header.custom .navbar-nav:not(.nav-overlay) .cart-dropdown:hover > a .dcart-total-count, .header.custom .navbar-nav:not(.nav-overlay) .cart-dropdown:focus > a .dcart-total-count,
.header.custom .header-bottom .navbar-nav .cart-dropdown.open > a .dcart-total-count,
.header.custom .header-bottom .navbar-nav .cart-dropdown:hover > a .dcart-total-count,
.header.custom .header-bottom .navbar-nav .cart-dropdown:focus > a .dcart-total-count {
  background-color: #fff;
  color: #0069b0;
}

.header.custom .navbar-nav:not(.nav-overlay) .dropdown-menu,
.header.custom .header-bottom .navbar-nav .dropdown-menu {
  background: #0069b0;
}

.header.custom .navbar-nav:not(.nav-overlay) .dropdown-menu > li > a,
.header.custom .header-bottom .navbar-nav .dropdown-menu > li > a {
  color: #fff;
}

.header.custom .navbar-nav:not(.nav-overlay) .dropdown-menu > li > a:hover, .header.custom .navbar-nav:not(.nav-overlay) .dropdown-menu > li > a:focus,
.header.custom .header-bottom .navbar-nav .dropdown-menu > li > a:hover,
.header.custom .header-bottom .navbar-nav .dropdown-menu > li > a:focus {
  color: #fff;
  background-color: #004b7d;
}

.header.custom .navbar-nav:not(.nav-overlay) .dropdown-menu > li.open > a, .header.custom .navbar-nav:not(.nav-overlay) .dropdown-menu > li.open > a:hover, .header.custom .navbar-nav:not(.nav-overlay) .dropdown-menu > li.open > a:focus,
.header.custom .header-bottom .navbar-nav .dropdown-menu > li.open > a,
.header.custom .header-bottom .navbar-nav .dropdown-menu > li.open > a:hover,
.header.custom .header-bottom .navbar-nav .dropdown-menu > li.open > a:focus {
  color: #fff;
  background-color: #004b7d;
}

@media (max-width: 767px) {
  .header.custom .navbar-nav:not(.nav-overlay) .dropdown-menu.megamenu [class*="col-"]:not(:last-child),
  .header.custom .header-bottom .navbar-nav .dropdown-menu.megamenu [class*="col-"]:not(:last-child) {
    border-color: #004b7d;
  }
}

.header.custom .navbar-nav:not(.nav-overlay) .dropdown-menu.megamenu a:hover, .header.custom .navbar-nav:not(.nav-overlay) .dropdown-menu.megamenu a:focus,
.header.custom .header-bottom .navbar-nav .dropdown-menu.megamenu a:hover,
.header.custom .header-bottom .navbar-nav .dropdown-menu.megamenu a:focus {
  color: #fff;
  background-color: #004b7d;
}

.header.custom .navbar-nav:not(.nav-overlay) .dropdown-menu.megamenu .megamenu-title:before,
.header.custom .header-bottom .navbar-nav .dropdown-menu.megamenu .megamenu-title:before {
  background-color: #fff;
}

.header.custom .navbar-nav:not(.nav-overlay) .dropdown-menu.megamenu .megamenu-title:hover:before, .header.custom .navbar-nav:not(.nav-overlay) .dropdown-menu.megamenu .megamenu-title:focus:before,
.header.custom .header-bottom .navbar-nav .dropdown-menu.megamenu .megamenu-title:hover:before,
.header.custom .header-bottom .navbar-nav .dropdown-menu.megamenu .megamenu-title:focus:before {
  background-color: #fff;
}

@media (max-width: 767px) {
  .header.custom .navbar-nav {
    background-color: #0069b0;
  }
  .header.custom .navbar-nav > li.open > a, .header.custom .navbar-nav > li.open > a:hover, .header.custom .navbar-nav > li.open > a:focus, .header.custom .navbar-nav > li.active > a, .header.custom .navbar-nav > li.active > a:hover, .header.custom .navbar-nav > li.active > a:focus {
    background: #005187;
    color: #fff;
  }
  .header.custom .navbar-nav > li > a:hover, .header.custom .navbar-nav > li > a:focus {
    background: #004b7d;
    color: #fff;
  }
}

.header.custom .navbar-toggle,
.header.custom .menu-toggle,
.header.custom .sidemenu-toggle {
  background-color: #0069b0;
  border-color: #0069b0;
  color: #f1f1f1;
}

.header.custom .navbar-toggle .icon-bar,
.header.custom .menu-toggle .icon-bar,
.header.custom .sidemenu-toggle .icon-bar {
  background-color: #f1f1f1;
}

.header.custom .navbar-toggle:hover, .header.custom .navbar-toggle:focus,
.header.custom .menu-toggle:hover,
.header.custom .menu-toggle:focus,
.header.custom .sidemenu-toggle:hover,
.header.custom .sidemenu-toggle:focus {
  color: #fff;
  background-color: #004473;
  border-color: #004473;
}

.header.custom .navbar-toggle:hover .icon-bar, .header.custom .navbar-toggle:focus .icon-bar,
.header.custom .menu-toggle:hover .icon-bar,
.header.custom .menu-toggle:focus .icon-bar,
.header.custom .sidemenu-toggle:hover .icon-bar,
.header.custom .sidemenu-toggle:focus .icon-bar {
  background-color: #fff;
}

.header.custom .side-menu li a {
  color: #222;
}

@media (min-width: 992px) {
  .header.custom .side-menu li a {
    color: #f1f1f1;
  }
}

.header.custom .side-menu li.active > a,
.header.custom .side-menu li.active > a:hover,
.header.custom .side-menu li.active > a:focus, .header.custom .side-menu li:hover > a,
.header.custom .side-menu li:hover > a:hover,
.header.custom .side-menu li:hover > a:focus, .header.custom .side-menu li:focus > a,
.header.custom .side-menu li:focus > a:hover,
.header.custom .side-menu li:focus > a:focus {
  color: #0d9dff;
}

/* Header2 Custom */
@media (max-width: 767px) {
  .header.header2.custom .navbar-nav {
    background-color: #005a97;
  }
}

.header.header2.custom .navbar-nav > li.open > a, .header.header2.custom .navbar-nav > li.open > a:hover, .header.header2.custom .navbar-nav > li.open > a:focus, .header.header2.custom .navbar-nav > li.active > a, .header.header2.custom .navbar-nav > li.active > a:hover, .header.header2.custom .navbar-nav > li.active > a:focus {
  background: #005187;
  color: #fff;
}

@media (min-width: 768px) {
  .header.header2.custom .navbar-nav .dropdown-menu {
    border-top-color: #005d9c;
  }
}

.header.header2.custom .navbar-nav .dropdown.cart-dropdown > a .dcart-total-count {
  background-color: #0069b0;
  color: #fff;
}

.header.header2.custom .navbar-toggle {
  background-color: #005791;
  border-color: #005791;
  color: #f1f1f1;
}

.header.header2.custom .navbar-toggle .icon-bar {
  background-color: #f1f1f1;
}

.header.header2.custom .navbar-toggle:hover, .header.header2.custom .navbar-toggle:focus {
  color: #fff;
  background-color: #004473;
  border-color: #004473;
}

.header.header2.custom .navbar-toggle:hover .icon-bar, .header.header2.custom .navbar-toggle:focus .icon-bar {
  background-color: #fff;
}

/* Header3 Custom */
.header.header3.custom .social-icons .social-icon {
  color: #f1f1f1;
  background-color: #005791;
}

.header.header3.custom .social-icons .social-icon:hover, .header.header3.custom .social-icons .social-icon:focus {
  color: #fff;
  background-color: #004473;
}

/* Header5 Custom */
@media (min-width: 992px) {
  .header.header5.custom .header-top:not(.dark) {
    background-color: #005187;
  }
}

.header.header5 .header-top.transparent {
  background-color: transparent !important;
}

/* Header Dark */
.header.dark {
  background-color: #1b1b1b;
  color: #eee;
}

.header.dark a {
  color: #f1f1f1;
}

.header.dark .dropdown.header-dropdown .dropdown-menu {
  border-color: #101010;
  background-color: #262626;
  box-shadow: 2px 3px 3px 0 rgba(0, 0, 0, 0.1), -2px 3px 3px 0 rgba(0, 0, 0, 0.1);
}

.header.dark .header-top:not(.custom) {
  border-color: #363636;
  color: #eee;
}

.header.dark .header-top:not(.custom).transparent {
  border-color: #404040;
}

.header.dark .header-top:not(.custom) .header-dropdown > a {
  color: #f1f1f1;
  background-color: #313131;
  border-color: #313131;
}

.header.dark .header-top:not(.custom) .header-dropdown:nth-of-type(2n) > a {
  background-color: #404040;
}

.header.dark .header-top:not(.custom) .header-dropdown.open > a, .header.dark .header-top:not(.custom) .header-dropdown:hover > a, .header.dark .header-top:not(.custom) .header-dropdown:focus > a {
  color: #fff;
  background-color: #0069b0;
  border-color: #0069b0;
}

.header.dark .header-top:not(.custom) .top-menu li a {
  color: #eee;
}

.header.dark .header-top:not(.custom) .top-menu li a:before {
  background-color: #0069b0;
}

.header.dark .header-top:not(.custom) .top-menu li + li:before {
  color: #646464;
}

.header.dark .header-top:not(.custom) .top-menu li.active a, .header.dark .header-top:not(.custom) .top-menu li:hover a, .header.dark .header-top:not(.custom) .top-menu li:focus a {
  color: #0069b0;
}

.header.dark .header-top:not(.custom) .top-text {
  color: #c7c7c7;
}

.header.dark .header-top:not(.custom) .top-links a {
  color: #eaeaea;
}

.header.dark .header-top:not(.custom) .top-links a:hover, .header.dark .header-top:not(.custom) .top-links a:focus {
  color: #17a1ff;
}

.header.dark .header-top:not(.custom) .top-links a + a:before {
  color: #eaeaea;
}

.header.dark .header-top:not(.custom) .social-icons .social-icon {
  color: #fff;
  border-color: #383838;
}

.header.dark .header-top:not(.custom) .social-icons .social-icon:hover, .header.dark .header-top:not(.custom) .social-icons .social-icon:focus {
  background-color: #0069b0;
  border-color: #0069b0;
}

.header.dark .header-info-boxes .header-info-box .info-box-title {
  color: #d6d6d6;
}

.header.dark .header-info-boxes .header-info-box i:not(.custom) {
  background-color: #3b3b3b;
  color: #fff;
}

.header.dark .header-info-boxes .header-info-box + .header-info-box:before {
  background-color: #363636;
}

.header.dark .dropdown.cart-dropdown > a {
  color: #0069b0;
}

.header.dark .dropdown.cart-dropdown > a .dcart-total-count {
  background-color: #fff;
  color: #0069b0;
}

.header.dark .dropdown.cart-dropdown.open > a, .header.dark .dropdown.cart-dropdown:hover > a, .header.dark .dropdown.cart-dropdown:focus > a {
  color: #005187;
}

.header.dark .dropdown.cart-dropdown.open > a .dcart-total-count, .header.dark .dropdown.cart-dropdown:hover > a .dcart-total-count, .header.dark .dropdown.cart-dropdown:focus > a .dcart-total-count {
  background-color: #fff;
  color: #0069b0;
}

.header.dark .dropdown.cart-dropdown .dropdown-menu > p {
  color: #c7c7c7;
}

.header.dark .dropdown.cart-dropdown .dropdown-menu > p span {
  color: #eee;
}

.header.dark .dropdown.cart-dropdown .dropdown-menu .product-title a {
  color: #d6d6d6;
}

.header.dark .dropdown.cart-dropdown .dropdown-menu .product-title a:hover, .header.dark .dropdown.cart-dropdown .dropdown-menu .product-title a:focus {
  color: #fff;
}

.header.dark .dropdown.cart-dropdown .dropdown-menu .product .product-quantity {
  color: #d7d7d7;
}

.header.dark .dropdown.cart-dropdown .dropdown-menu .product .product-price-container .old-price {
  color: #eee;
}

.header.dark .dropdown.cart-dropdown .dropdown-menu .product .delete-btn {
  color: #f1f1f1;
  background-color: #0069b0;
}

.header.dark .dropdown.cart-dropdown .dropdown-menu .product .delete-btn:hover, .header.dark .dropdown.cart-dropdown .dropdown-menu .product .delete-btn:focus {
  color: #fff;
  background-color: #005a97;
}

.header.dark .dropdown.cart-dropdown .dropdown-menu .dcart-action-info p span {
  color: #eee;
}

.header.dark .dropdown.cart-dropdown .dcart-products {
  border-color: #404040;
}

.header.dark .header-bottom:not(.custom) {
  background-color: #101010;
}

.header.dark .navbar-nav:not(.nav-overlay) a,
.header.dark .header-bottom .navbar-nav a {
  color: #fff;
}

@media (min-width: 768px) {
  .header.dark .navbar-nav:not(.nav-overlay) > li > a,
  .header.dark .header-bottom .navbar-nav > li > a {
    color: #f1f1f1;
  }
  .header.dark .navbar-nav:not(.nav-overlay) > li > a:before,
  .header.dark .header-bottom .navbar-nav > li > a:before {
    background-color: #0069b0;
  }
  .header.dark .navbar-nav:not(.nav-overlay) > li.open > a, .header.dark .navbar-nav:not(.nav-overlay) > li.open > a:hover, .header.dark .navbar-nav:not(.nav-overlay) > li.open > a:focus, .header.dark .navbar-nav:not(.nav-overlay) > li.active > a, .header.dark .navbar-nav:not(.nav-overlay) > li.active > a:hover, .header.dark .navbar-nav:not(.nav-overlay) > li.active > a:focus,
  .header.dark .header-bottom .navbar-nav > li.open > a,
  .header.dark .header-bottom .navbar-nav > li.open > a:hover,
  .header.dark .header-bottom .navbar-nav > li.open > a:focus,
  .header.dark .header-bottom .navbar-nav > li.active > a,
  .header.dark .header-bottom .navbar-nav > li.active > a:hover,
  .header.dark .header-bottom .navbar-nav > li.active > a:focus {
    background: #292929;
    color: #fff;
  }
}

@media (min-width: 768px) {
  .header.dark .navbar-nav:not(.nav-overlay) .cart-dropdown > a .dcart-total-count,
  .header.dark .header-bottom .navbar-nav .cart-dropdown > a .dcart-total-count {
    background: #292929;
    color: #f1f1f1;
  }
}

.header.dark .navbar-nav:not(.nav-overlay) .cart-dropdown.open > a .dcart-total-count, .header.dark .navbar-nav:not(.nav-overlay) .cart-dropdown:hover > a .dcart-total-count, .header.dark .navbar-nav:not(.nav-overlay) .cart-dropdown:focus > a .dcart-total-count,
.header.dark .header-bottom .navbar-nav .cart-dropdown.open > a .dcart-total-count,
.header.dark .header-bottom .navbar-nav .cart-dropdown:hover > a .dcart-total-count,
.header.dark .header-bottom .navbar-nav .cart-dropdown:focus > a .dcart-total-count {
  background-color: #fff;
  color: #0069b0;
}

.header.dark .navbar-nav:not(.nav-overlay) .dropdown-menu,
.header.dark .header-bottom .navbar-nav .dropdown-menu {
  background: #292929;
}

.header.dark .navbar-nav:not(.nav-overlay) .dropdown-menu > li > a,
.header.dark .header-bottom .navbar-nav .dropdown-menu > li > a {
  color: #eee;
}

.header.dark .navbar-nav:not(.nav-overlay) .dropdown-menu > li > a:hover, .header.dark .navbar-nav:not(.nav-overlay) .dropdown-menu > li > a:focus,
.header.dark .header-bottom .navbar-nav .dropdown-menu > li > a:hover,
.header.dark .header-bottom .navbar-nav .dropdown-menu > li > a:focus {
  color: #fff;
  background-color: #0069b0;
}

.header.dark .navbar-nav:not(.nav-overlay) .dropdown-menu > li.open > a, .header.dark .navbar-nav:not(.nav-overlay) .dropdown-menu > li.open > a:hover, .header.dark .navbar-nav:not(.nav-overlay) .dropdown-menu > li.open > a:focus,
.header.dark .header-bottom .navbar-nav .dropdown-menu > li.open > a,
.header.dark .header-bottom .navbar-nav .dropdown-menu > li.open > a:hover,
.header.dark .header-bottom .navbar-nav .dropdown-menu > li.open > a:focus {
  color: #fff;
  background-color: #0069b0;
}

.header.dark .navbar-nav:not(.nav-overlay) .dropdown-menu.megamenu a:hover, .header.dark .navbar-nav:not(.nav-overlay) .dropdown-menu.megamenu a:focus,
.header.dark .header-bottom .navbar-nav .dropdown-menu.megamenu a:hover,
.header.dark .header-bottom .navbar-nav .dropdown-menu.megamenu a:focus {
  color: #fff;
  background-color: #0069b0;
}

.header.dark .navbar-nav:not(.nav-overlay) .dropdown-menu.megamenu .megamenu-title:before,
.header.dark .header-bottom .navbar-nav .dropdown-menu.megamenu .megamenu-title:before {
  background-color: #fff;
}

.header.dark .navbar-nav:not(.nav-overlay) .dropdown-menu.megamenu .megamenu-title:hover:before, .header.dark .navbar-nav:not(.nav-overlay) .dropdown-menu.megamenu .megamenu-title:focus:before,
.header.dark .header-bottom .navbar-nav .dropdown-menu.megamenu .megamenu-title:hover:before,
.header.dark .header-bottom .navbar-nav .dropdown-menu.megamenu .megamenu-title:focus:before {
  background-color: #fff;
}

@media (max-width: 767px) {
  .header.dark .navbar-nav {
    background-color: #1f1f1f;
  }
  .header.dark .navbar-nav > li.open > a, .header.dark .navbar-nav > li.open > a:hover, .header.dark .navbar-nav > li.open > a:focus, .header.dark .navbar-nav > li.active > a, .header.dark .navbar-nav > li.active > a:hover, .header.dark .navbar-nav > li.active > a:focus {
    background: #0069b0;
    color: #fff;
  }
  .header.dark .navbar-nav > li > a:hover, .header.dark .navbar-nav > li > a:focus {
    background: #0069b0;
    color: #fff;
  }
}

.header.dark .search-dropdown .dropdown-menu .form-control {
  background-color: #171717;
  border-color: #313131;
  color: #999;
}

.header.dark .search-dropdown .dropdown-menu .form-control::placeholder {
  color: #999;
}

.header.dark .navbar-toggle,
.header.dark .menu-toggle,
.header.dark .sidemenu-toggle {
  background-color: #363636;
  border-color: #363636;
  color: #f1f1f1;
}

.header.dark .navbar-toggle .icon-bar,
.header.dark .menu-toggle .icon-bar,
.header.dark .sidemenu-toggle .icon-bar {
  background-color: #f1f1f1;
}

.header.dark .navbar-toggle:hover, .header.dark .navbar-toggle:focus,
.header.dark .menu-toggle:hover,
.header.dark .menu-toggle:focus,
.header.dark .sidemenu-toggle:hover,
.header.dark .sidemenu-toggle:focus {
  color: #fff;
  background-color: #292929;
  border-color: #292929;
}

.header.dark .navbar-toggle:hover .icon-bar, .header.dark .navbar-toggle:focus .icon-bar,
.header.dark .menu-toggle:hover .icon-bar,
.header.dark .menu-toggle:focus .icon-bar,
.header.dark .sidemenu-toggle:hover .icon-bar,
.header.dark .sidemenu-toggle:focus .icon-bar {
  background-color: #fff;
}

/* Header2 Dark */
@media (max-width: 767px) {
  .header.header2.dark .navbar-nav {
    background-color: #242424;
  }
}

.header.header2.dark .navbar-nav > li.open > a, .header.header2.dark .navbar-nav > li.open > a:hover, .header.header2.dark .navbar-nav > li.open > a:focus, .header.header2.dark .navbar-nav > li.active > a, .header.header2.dark .navbar-nav > li.active > a:hover, .header.header2.dark .navbar-nav > li.active > a:focus {
  background: #0069b0;
  color: #fff;
}

@media (min-width: 768px) {
  .header.header2.dark .navbar-nav .dropdown-menu {
    border-top-color: #2b2b2b;
  }
}

.header.header2.dark .navbar-nav .dropdown.cart-dropdown > a .dcart-total-count {
  background-color: #0069b0;
  color: #fff;
}

.header.header2.dark .navbar-toggle {
  background-color: #363636;
  border-color: #363636;
  color: #f1f1f1;
}

.header.header2.dark .navbar-toggle .icon-bar {
  background-color: #f1f1f1;
}

.header.header2.dark .navbar-toggle:hover, .header.header2.dark .navbar-toggle:focus {
  color: #fff;
  background-color: #292929;
  border-color: #292929;
}

.header.header2.dark .navbar-toggle:hover .icon-bar, .header.header2.dark .navbar-toggle:focus .icon-bar {
  background-color: #fff;
}

/* Header3 Dark */
.header.header3.dark .social-icons .social-icon {
  color: #f1f1f1;
  background-color: #404040;
}

.header.header3.dark .social-icons .social-icon:hover, .header.header3.dark .social-icons .social-icon:focus {
  color: #fff;
  background-color: #0069b0;
}

/* Sticky Header */
@keyframes fixedHeader {
  0% {
    opacity: 0;
    transform: translateY(-60px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (min-width: 992px) {
  .header.sticky-header.fixed {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1040;
    animation-name: fixedHeader;
    animation-duration: .6s;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  }
  .header.sticky-header.fixed .header-top {
    display: none;
  }
  .header.sticky-header.fixed .header-inner {
    display: none;
  }
  .header.sticky-header.fixed .header-bottom .navbar-nav > li > a .tip {
    top: -3px;
  }
  .boxed .header.sticky-header.fixed,
  .boxed-long .header.sticky-header.fixed {
    left: auto;
    right: auto;
  }
}

/* Header 2*/
@media (min-width: 992px) {
  .header2.sticky-header.fixed .header-inner {
    display: block;
  }
  .header2.sticky-header.fixed .site-logo {
    margin-top: 13px;
    margin-bottom: 13px;
  }
  .header2.sticky-header.fixed .navbar-nav > li {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .header2.sticky-header.fixed .navbar-nav > li.cart-dropdown {
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .header2.sticky-header.fixed .dropdown.cart-dropdown > a {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .header2.sticky-header.fixed.transparent {
    background-color: #fff;
  }
  .header2.sticky-header.fixed.transparent.light, .header2.sticky-header.fixed.transparent.dark {
    background-color: #1b1b1b;
  }
  .header2.sticky-header.fixed.transparent.custom {
    background-color: #0069b0;
  }
  .header2.sticky-header.fixed.header-boxed {
    left: auto;
    right: auto;
    top: 20px;
    box-shadow: 3px 2px 6px rgba(0, 0, 0, 0.1), -2px -3px 6px rgba(0, 0, 0, 0.1);
  }
}

@media (min-width: 992px) and (min-width: 992px) {
  .header2.sticky-header.fixed.header-boxed {
    width: 940px;
  }
}

@media (min-width: 992px) and (min-width: 1200px) {
  .header2.sticky-header.fixed.header-boxed {
    width: 1140px;
  }
}

/* Fix For jump */
@media (min-width: 992px) {
  .transparent-sticky-header .sticky-wrapper {
    height: 0 !important;
  }
}

/* Header 4 */
@media (min-width: 992px) {
  .header4.sticky-header.fixed .header-inner {
    display: block;
  }
  .header4.sticky-header.fixed .site-logo {
    margin-top: 13px;
    margin-bottom: 13px;
  }
  .header4.sticky-header.fixed .header-info-boxes {
    margin-top: 12.5px;
  }
  .header4.sticky-header.fixed .dropdown.cart-dropdown > a {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .header4.sticky-header.fixed .menu-toggle {
    margin-top: 11px;
    margin-bottom: 11px;
  }
  .header4.sticky-header.fixed .menu-toggle.jicon {
    margin-top: 13px;
    margin-bottom: 13px;
  }
}

/* ----------- 6.Homepages -------------
--------------------------------------*/
/* Homepages - Index */
.slider-container-index {
  background-color: #e1e1e1;
}

@media (min-width: 1200px) {
  .slider-container-index {
    height: 520px;
  }
}

.slider-container-index .tp-caption strong {
  font-weight: 700 !important;
}

.slider-container-index .hebe .tp-bullet-image {
  border-radius: 0;
}

.tparrows.custom {
  width: 54px;
  height: 54px;
  color: #121212;
  background-color: rgba(255, 255, 255, 0.85);
  transition: background-color 0.4s;
}

.tparrows.custom:before {
  color: #121212;
  font-size: 16px;
  line-height: 54px;
}

.tparrows.custom:hover, .tparrows.custom:focus {
  color: #101010;
  background-color: #fff;
}

.tparrows.custom:hover:before, .tparrows.custom:focus:before {
  color: #101010;
}

/* Homepages - Index2 */
.slider-container-index2 {
  background-color: #e1e1e1;
}

@media (min-width: 1200px) {
  .slider-container-index2 {
    height: 600px;
  }
}

.slider-container-index2 .tp-caption strong {
  font-weight: 700 !important;
}

.slider-container-index2 .tp-caption iframe {
  outline: none;
  border: none;
}

.boxed-news-container {
  padding: 15px;
}

@media (min-width: 992px) {
  .boxed-news-container {
    max-width: 80%;
    margin: 0 auto;
  }
}

.boxed-news-container .entry {
  margin-bottom: 0 !important;
}

/* Homepages - Index3*/
.slider-container-index3 {
  background-color: #171717;
  height: 100vh;
}

.slider-container-index3 .tp-caption strong {
  font-weight: 700 !important;
}

.services-section-edge {
  padding-top: 70px;
  padding-bottom: 110px;
  position: relative;
}

.services-section-edge::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='150' width='2800' fill='rgb(255,255,255)' fill-opacity='1'%3E%3Cpolygon points='0,150 2800,150 0,0'%3E%3C/polygon%3E%3C/svg%3E");
  background-position: center center;
  background-repeat: no-repeat;
  content: '';
  height: 150px;
  left: 0;
  position: absolute;
  right: 0;
  width: 100%;
  z-index: 1;
  bottom: 0;
}

.about-section-edge {
  padding-bottom: 130px;
  position: relative;
}

.about-section-edge::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='150' width='2800' fill='rgb(240,240,240)' fill-opacity='1'%3E%3Cpolygon points='0,150 2800,150 2800,0'%3E%3C/polygon%3E%3C/svg%3E");
  background-position: center center;
  background-repeat: no-repeat;
  content: '';
  height: 150px;
  left: 0;
  position: absolute;
  right: 0;
  width: 100%;
  z-index: 1;
  bottom: 0;
}

.about-section-edge .container {
  position: relative;
}

.more-about-section-edge {
  padding-bottom: 130px;
  position: relative;
}

.more-about-section-edge::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='150' width='2800' fill='rgb(255,255,255)' fill-opacity='1'%3E%3Cpolygon points='0,150 2800,150 0,0'%3E%3C/polygon%3E%3C/svg%3E");
  background-position: center center;
  background-repeat: no-repeat;
  content: '';
  height: 150px;
  left: 0;
  position: absolute;
  right: 0;
  width: 100%;
  z-index: 1;
  bottom: 0;
}

.more-about-section-edge .money-bag-img {
  position: relative;
  display: block;
  max-width: 100%;
  height: auto;
  margin-top: -150px;
  z-index: 900;
}

.team-section-edge {
  padding-bottom: 135px;
  position: relative;
}

.team-section-edge::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='150' width='2800' fill='rgb(240,240,240)' fill-opacity='1'%3E%3Cpolygon points='0,150 2800,150 2800,0'%3E%3C/polygon%3E%3C/svg%3E");
  background-position: center center;
  background-repeat: no-repeat;
  content: '';
  height: 150px;
  left: 0;
  position: absolute;
  right: 0;
  width: 100%;
  z-index: 1;
  bottom: 0;
}

.team-section-edge .member {
  margin-bottom: 0;
}

.portfolio-section-edge {
  padding-bottom: 130px;
  position: relative;
}

.portfolio-section-edge::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='150' width='2800' fill='rgb(255,255,255)' fill-opacity='1'%3E%3Cpolygon points='0,150 2800,150 0,0'%3E%3C/polygon%3E%3C/svg%3E");
  background-position: center center;
  background-repeat: no-repeat;
  content: '';
  height: 150px;
  left: 0;
  position: absolute;
  right: 0;
  width: 100%;
  z-index: 1;
  bottom: 0;
}

.portfolio-section-edge .portfolio-item {
  margin-bottom: 0;
}

.extra-info-section-edge {
  padding-bottom: 130px;
  position: relative;
}

.extra-info-section-edge::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='150' width='2800' fill='rgb(240,240,240)' fill-opacity='1'%3E%3Cpolygon points='0,150 2800,150 2800,0'%3E%3C/polygon%3E%3C/svg%3E");
  background-position: center center;
  background-repeat: no-repeat;
  content: '';
  height: 150px;
  left: 0;
  position: absolute;
  right: 0;
  width: 100%;
  z-index: 1;
  bottom: 0;
}

.extra-info-section-edge .extra-img {
  position: relative;
  z-index: 900;
  display: block;
  max-width: 100%;
  height: auto;
  margin-top: -90px;
}

.news-section-edge {
  padding-bottom: 60px;
}

/* Homepages - Index 4*/
.page-header.header-top-page {
  padding: 165px 0 0;
}

@media (min-width: 768px) {
  .page-header.header-top-page {
    padding-top: 235px;
  }
}

@media (min-width: 992px) {
  .page-header.header-top-page {
    padding-top: 200px;
  }
}

.typed-header {
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.3;
}

@media (min-width: 481px) {
  .typed-header {
    font-size: 18px;
  }
}

@media (min-width: 768px) {
  .typed-header {
    font-size: 22px;
  }
}

@media (min-width: 992px) {
  .typed-header {
    font-size: 26px;
  }
}

@media (min-width: 1200px) {
  .typed-header {
    font-size: 30px;
  }
}

/* Homepages - Index5 */
.slider-container5 {
  background-color: #c7c7c7;
}

@media (min-width: 1200px) {
  .slider-container5 {
    height: 540px;
  }
}

/* Homepages - Index6 */
.slider-container6 {
  background-color: #e1e1e1;
}

@media (min-width: 1200px) {
  .slider-container6 {
    height: 520px;
  }
}

.slider-container6 .tp-caption strong {
  font-weight: 700 !important;
}

/* Homepages - Index7 */
.slider-container-index7 {
  background-color: #e1e1e1;
}

@media (min-width: 1200px) {
  .slider-container-index7 {
    height: 600px;
  }
}

.slider-container-index7 .tp-caption strong {
  font-weight: 700 !important;
}

#portfolio-filter.vertical-filter {
  margin-bottom: 30px;
}

@media (min-width: 992px) {
  #portfolio-filter.vertical-filter {
    border: none;
  }
  #portfolio-filter.vertical-filter > li {
    float: none;
    display: block;
    text-align: left;
    margin: 6px 0;
  }
  #portfolio-filter.vertical-filter > li > a {
    display: block;
    padding: 0 0 0 10px;
  }
  #portfolio-filter.vertical-filter > li:before {
    left: auto;
    right: auto;
    top: 50%;
    bottom: 50%;
    width: 2px;
    height: auto;
  }
  #portfolio-filter.vertical-filter > li.active:before {
    top: 0;
    bottom: 0;
  }
}

.video-box-section-wrapper {
  max-width: 630px;
  border: 12px solid rgba(255, 255, 255, 0.4);
  margin: 0 auto;
}

/* Homepages - Index8 */
.slider-container8 {
  background-color: #e1e1e1;
}

@media (min-width: 1200px) {
  .slider-container8 {
    height: 600px;
  }
}

.contact-info-area {
  text-align: center;
  margin-bottom: 25px;
}

.contact-info-area i {
  width: 46px;
  height: 46px;
  font-size: 18px;
  line-height: 46px;
  border-radius: 50%;
  text-align: center;
  background-color: #0069b0;
  color: #fff;
  margin-bottom: 12px;
}

.contact-info-area strong {
  color: #555;
}

.contact-info-area address {
  line-height: 1.6;
}

.contact-info-area address,
.contact-info-area p {
  line-height: 1.6;
  margin-bottom: 3px;
}

/* Homepages - Index9 */
.home-info-form-section {
  padding-top: 50px;
  padding-bottom: 55px;
}

@media (min-width: 768px) {
  .home-info-form-section {
    padding-top: 80px;
    padding-bottom: 50px;
  }
}

@media (min-width: 992px) {
  .home-info-form-section {
    padding-top: 100px;
    padding-bottom: 0;
  }
}

.home-info-form-section .overlay {
  opacity: 0.6;
}

.home-info-form-section .home-info-form-content {
  padding-bottom: 30px;
}

@media (min-width: 992px) {
  .home-info-form-section .home-info-form-content {
    padding-left: 25px;
  }
}

@media (min-width: 1600px) {
  .home-info-form-section .home-info-form-content {
    margin-top: 50px;
  }
}

.home-info-form-section .service {
  color: #eee;
  max-width: 460px;
  font-size: 14px;
}

.home-info-form-section .service .service-title {
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 0.03em;
}

.home-info-form-section .home-contact-form {
  padding: 20px 15px;
  background-color: #101010;
  max-width: 380px;
  outline: 8px solid rgba(255, 255, 255, 0.2);
}

@media (min-width: 992px) {
  .home-info-form-section .home-contact-form {
    margin-bottom: -120px;
    padding: 30px 25px;
  }
}

.home-info-form-section .home-contact-form h2, .home-info-form-section .home-contact-form h3 {
  color: #f1f1f1;
  font-weight: 400;
  text-transform: uppercase;
}

.home-info-form-section .home-contact-form label {
  color: #bebebe;
}

.home-info-form-section .home-contact-form .form-control {
  background-color: #313131;
  border-color: #3b3b3b;
  color: #eee;
}

.home-info-form-section .home-contact-form .form-control:focus {
  background-color: #262626;
}

.home-info-form-section .home-contact-form textarea.form-control {
  min-height: 100px;
}

@media (min-width: 768px) {
  .man-standing-img {
    margin-top: -40px;
  }
}

@media (min-width: 992px) {
  .man-standing-img {
    margin-top: -90px;
  }
}

.entry-list-col .entry.entry-list-sm:last-child {
  margin-bottom: 0;
}

.portfolio-item-col .portfolio-item:last-child {
  margin-bottom: 0;
}

/* Homepages - Index10 */
.slider-container10 {
  background-color: #121212;
}

@media (min-width: 1200px) {
  .slider-container10 {
    height: 600px;
  }
}

@media (min-width: 1200px) {
  .vertial-phone-img {
    margin-top: -40px;
  }
}

/* Homepages - Index Shop */
.slider-container-shop {
  background-color: #e1e1e1;
}

@media (min-width: 1200px) {
  .slider-container-shop {
    height: 560px;
  }
}

.slider-container-shop .tp-caption strong {
  font-weight: 700 !important;
}

.banner {
  position: relative;
}

.banner a {
  display: block;
}

.banner img {
  display: block;
  width: 100%;
  height: auto;
}

.shop-text p {
  margin-bottom: 8px;
  text-indent: 20px;
}

.shop-text .learn-more {
  float: right;
  font-weight: 600;
}

@media (min-width: 768px) {
  .shop-text .learn-more {
    margin-right: 25px;
  }
}

@media (min-width: 992px) {
  .shop-text .learn-more {
    margin-right: 35px;
  }
}

/* Clear product and entry margins for carousel */
.owl-carousel .product {
  margin-bottom: 6px;
}

.owl-carousel .entry,
.owl-carousel .entry.entry-grid {
  margin-bottom: 0;
}

.sidebar.shop-sidebar.home-shop-sidebar {
  padding-top: 12px;
}

.sidebar.shop-sidebar .widget .widget-title {
  border-bottom: 1px solid #eee;
  padding-bottom: 10px;
}

.sidebar.shop-sidebar .widget.category-widget ul li {
  margin-bottom: 10px;
}

.sidebar.shop-sidebar .widget.category-widget ul li a {
  font-weight: 600;
  display: block;
}

.sidebar.shop-sidebar .widget.category-widget ul li ul {
  padding-left: 10px;
  padding-top: 6px;
}

.sidebar.shop-sidebar .widget.category-widget ul li ul li {
  margin-bottom: 4px;
}

.sidebar.shop-sidebar .widget.category-widget ul li ul li a {
  font-weight: 400;
}

.sidebar.shop-sidebar .widget.category-widget ul li ul li a i {
  float: none;
  margin-right: 4px;
  margin-top: 0;
  font-size: 90%;
  color: #9e9e9e;
}

.sidebar.shop-sidebar .widget.brands-widget ul li {
  margin-bottom: 4px;
}

.sidebar.shop-sidebar .widget.brands-widget ul li a {
  font-weight: 400;
}

.sidebar.shop-sidebar .widget.brands-widget ul li a i {
  float: none;
  margin-right: 4px;
  margin-top: 0;
  font-size: 90%;
  color: #9e9e9e;
}

.sidebar.shop-sidebar .widget .product.product-sm {
  padding-bottom: 0;
  margin-bottom: 16px;
}

.sidebar.shop-sidebar .widget .banner-widget-slider .banner {
  border: 1px solid #eee;
}

.sidebar.shop-sidebar .widget .banner-widget-slider .owl-dots {
  margin: 0;
  line-height: 0;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 18px;
  z-index: 99;
}

.sidebar.shop-sidebar .widget .banner-widget-slider .owl-dots .owl-dot:not(.active) {
  background-color: #fff;
  border-color: #fff;
}

/* Index Shop 2*/
.slider-container-shop2 {
  background-color: #171717;
  height: 100vh;
}

.slider-container-shop2 .tp-caption strong {
  font-weight: 700 !important;
}

.nav.nav-tabs.style2, .nav.nav-pills.style2 {
  background-color: #e4e4ea;
  border-bottom: 1px solid #e0e0ea;
}

@media (min-width: 992px) {
  .nav.nav-tabs.style2 > li > a, .nav.nav-pills.style2 > li > a {
    font-size: 14px;
    padding-top: 11px;
    padding-bottom: 11px;
  }
}

.nav.nav-tabs.style2.nav-justified > li, .nav.nav-pills.style2.nav-justified > li {
  border-bottom: 1px solid #fff;
}

.nav.nav-tabs.style2.nav-justified > li:last-child, .nav.nav-pills.style2.nav-justified > li:last-child {
  border-bottom: none;
}

@media (min-width: 768px) {
  .nav.nav-tabs.style2.nav-justified > li, .nav.nav-pills.style2.nav-justified > li {
    border-bottom: none;
    border-right: 1px solid #fff;
  }
  .nav.nav-tabs.style2.nav-justified > li:last-child, .nav.nav-pills.style2.nav-justified > li:last-child {
    border-right: none;
  }
}

.shop-newsletter {
  color: #fff;
}

.shop-newsletter h2 {
  font-family: "Montserrat", Arial, sans-serif;
  color: #fff;
  text-transform: uppercase;
  font-size: 24px;
}

@media (min-width: 768px) {
  .shop-newsletter h2 {
    font-size: 28px;
  }
}

@media (min-width: 992px) {
  .shop-newsletter h2 {
    font-size: 32px;
  }
}

.shop-newsletter form {
  margin-left: auto;
  margin-right: auto;
  max-width: 420px;
}

.shop-newsletter form:after {
  content: '';
  clear: both;
  display: table;
}

.shop-newsletter p {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
  max-width: 540px;
}

@media (min-width: 768px) {
  .shop-newsletter p {
    margin-bottom: 36px;
  }
}

.shop-newsletter .form-control {
  margin-bottom: 0;
  float: left;
  width: calc(100% - 104px);
}

.shop-newsletter .btn {
  width: 100px;
  margin-left: 4px;
}

/* Info Boxes */
.info-boxes-container {
  width: 100%;
  background-color: #f1f1fa;
  border-top: 1px solid #e3e3ea;
  border-bottom: 1px solid #e3e3ea;
  margin-bottom: 30px;
  /* Dark Color */
  /* Custom Color */
}

@media (min-width: 768px) {
  .info-boxes-container > .container,
  .info-boxes-container > .container-fluid {
    display: table;
    table-layout: fixed;
    border-collapse: collapse;
  }
}

@media (max-width: 767px) {
  .info-boxes-container > .container,
  .info-boxes-container > .container-fluid {
    padding: 0;
  }
}

.info-boxes-container > .container-fluid {
  width: 100%;
}

.info-boxes-container .info-box {
  display: block;
  font-size: 0;
  padding: 12px 15px 9px;
  border-bottom: 1px solid #f0f0fa;
  position: relative;
}

.info-boxes-container .info-box:nth-child(2n) {
  background-color: #f8f8fa;
}

.info-boxes-container .info-box:last-child {
  border-bottom: none;
}

.info-boxes-container .info-box .info-box-wrapper {
  position: relative;
  display: inline-block;
  padding-left: 38px;
}

@media (min-width: 768px) {
  .info-boxes-container .info-box {
    display: table-cell;
    width: 33%;
    border-bottom: none;
    border-right: 1px solid #e3e3ea;
  }
  .info-boxes-container .info-box:last-child {
    border-right: none;
  }
}

@media (max-width: 767px) {
  .info-boxes-container .info-box.text-center {
    text-align: left !important;
  }
}

.info-boxes-container .info-box.text-center .info-box-content {
  text-align: left;
}

.info-boxes-container .info-box-icon {
  display: inline-block;
  font-size: 25px;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  vertical-align: middle;
  color: #3a3a3a;
}

.info-boxes-container .info-box-content {
  display: inline-block;
  font-size: 12px;
  color: #7e7e7e;
  line-height: 1.2;
  vertical-align: middle;
}

.info-boxes-container .info-box-title {
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 2px;
  text-transform: uppercase;
  line-height: 1;
  color: #333;
  letter-spacing: 0.01em;
}

.info-boxes-container p {
  margin-bottom: 0;
}

.info-boxes-container.dark {
  background-color: #101010;
  border-color: #292929;
}

.info-boxes-container.dark .info-box {
  border-color: #292929;
}

@media (min-width: 768px) {
  .info-boxes-container.dark .info-box {
    border-color: #292929;
  }
}

.info-boxes-container.dark .info-box:nth-child(2n) {
  background-color: #1b1b1b;
}

.info-boxes-container.dark .info-box-icon {
  color: #eee;
}

.info-boxes-container.dark .info-box-content {
  color: #8e8e8e;
}

.info-boxes-container.dark .info-box-title {
  color: #f0f0f0;
}

.info-boxes-container.custom {
  background-color: #0069b0;
  border-color: #007ed4;
}

.info-boxes-container.custom .info-box {
  border-color: #007ed4;
}

@media (min-width: 768px) {
  .info-boxes-container.custom .info-box {
    border-color: #007ed4;
  }
}

.info-boxes-container.custom .info-box:nth-child(2n) {
  background-color: #005d9c;
}

.info-boxes-container.custom .info-box-icon {
  color: #f0f0f0;
}

.info-boxes-container.custom .info-box-content {
  color: #2baaff;
}

.info-boxes-container.custom .info-box-title {
  color: #f0f0f0;
}

/* Index Shop 3*/
.slider-container-shop3 {
  background-color: #171717;
}

@media (min-width: 1200px) {
  .slider-container-shop3 {
    height: 520px;
  }
}

.slider-container-shop3 .tp-caption strong {
  font-weight: 700 !important;
}

/* Homepages - Index Portfolio */
.portfolio-header {
  padding: 40px 0 45px;
  font: 400 20px/1.2 "Open Sans", Arial, sans-serif;
}

@media (min-width: 768px) {
  .portfolio-header {
    padding-top: 60px;
    padding-bottom: 70px;
    font-size: 28px;
  }
}

@media (min-width: 992px) {
  .portfolio-header {
    font-size: 36px;
    padding-bottom: 75px;
    padding-top: 65px;
  }
}

.portfolio-header.as-block {
  padding-bottom: 30px;
}

.typed-cursor {
  opacity: 1;
  animation: blink 0.7s infinite;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.morphext > .animated {
  display: inline-block;
}

/* Homepages - Index Photography */
.fotorama__thumb-border {
  border-color: #0069b0;
}

/* Fotorama Navigation Arrows */
.fotorama__arr {
  font-family: 'FontAwesome';
  background-image: none;
  font-size: 18px;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.3);
  text-align: center;
  width: 34px;
  height: 36px;
  line-height: 36px;
  margin-top: -18px;
  transition: opacity 0.4s;
}

@media (min-width: 768px) {
  .fotorama__arr {
    font-size: 22px;
    width: 40px;
    height: 42px;
    line-height: 42px;
    margin-top: -21px;
  }
}

@media (min-width: 992px) {
  .fotorama__arr {
    font-size: 26px;
    width: 48px;
    height: 52px;
    line-height: 52px;
    margin-top: -26px;
  }
}

.fotorama__arr:hover, .fotorama__arr:focus {
  background-color: rgba(0, 0, 0, 0.6);
}

.fotorama__arr--prev {
  left: 0;
}

.fotorama__arr--prev:before {
  content: "\f104";
}

.fotorama__arr--next {
  right: 0;
}

.fotorama__arr--next:before {
  content: "\f105";
}

/* Thumbnail over */
.fotorama-nav-over .fotorama__nav-wrap {
  position: absolute;
  left: 0;
  bottom: 20px;
  z-index: 99;
}

/* Circle Thumbnails */
.fotorama-circle-thumbs .fotorama__thumb,
.fotorama-circle-thumbs .fotorama__thumb img,
.fotorama-circle-thumbs .fotorama__thumb-border,
.fotorama-circle-thumbs .fotorama__thumb-border img {
  border-radius: 50%;
}

/* Caption */
.fotorama__caption {
  left: 40px;
  right: 40px;
  font: 300 26px/1 "Open Sans", Arial, sans-serif;
  bottom: auto;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
  text-shadow: 1px 0 3px rgba(0, 0, 0, 0.2);
  text-transform: uppercase;
}

@media (min-width: 768px) {
  .fotorama__caption {
    font-size: 34px;
    left: 50px;
    right: 50px;
  }
}

@media (min-width: 992px) {
  .fotorama__caption {
    font-size: 42px;
    left: 60px;
    right: 60px;
  }
}

.fotorama__caption .fotorama__caption__wrap {
  background-color: transparent;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.56);
  padding: 0.3em 0.75em;
}

/* Homepages - Index Blog */
.featured-entry-carousel {
  margin-bottom: 40px;
}

@media (min-width: 768px) {
  .featured-entry-carousel {
    margin-bottom: 50px;
  }
}

@media (min-width: 992px) {
  .featured-entry-carousel {
    margin-bottom: 60px;
  }
}

.featured-entry {
  position: relative;
  overflow: hidden;
}

.featured-entry figure a {
  display: block;
}

.featured-entry figure a:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background-color: #101010;
  transition: all 0.4s;
  opacity: 0;
  visibility: hidden;
  z-index: 10;
}

.featured-entry figure img {
  display: block;
  height: auto;
  width: 100%;
}

.featured-entry .fentry-content {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 10px 15px 18px;
  opacity: 0.8;
  transition: all 0.4s;
  z-index: 20;
}

@media (min-width: 992px) {
  .featured-entry .fentry-content {
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 22px;
  }
}

.featured-entry a {
  color: #fff;
}

.featured-entry a:hover, .featured-entry a:focus {
  color: #0069b0;
}

.featured-entry h2 {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.3;
  font-family: "Montserrat", Arial, sans-serif;
  color: #fff;
  letter-spacing: 0.01em;
  text-shadow: 1px 0 1px rgba(0, 0, 0, 0.1);
  max-width: 220px;
  margin-bottom: 14px;
}

@media (min-width: 768px) {
  .featured-entry h2 {
    font-size: 17px;
    max-width: 260px;
  }
}

.featured-entry .fentry-meta {
  font-size: 0;
  color: #eee;
}

.featured-entry .fentry-meta > span {
  display: inline-block;
  font-size: 13px;
  margin-right: 10px;
}

.featured-entry .fentry-meta > span i {
  margin-right: 4px;
}

.featured-entry:hover .fentry-content {
  opacity: 1;
}

.featured-entry:hover figure a:after {
  opacity: 0.55;
  visibility: visible;
}

/* ----------- 7.About -----------------
--------------------------------------*/
/* About Us */
.member {
  margin-bottom: 30px;
}

.member .member-top {
  position: relative;
  background-color: #0069b0;
  margin-bottom: 20px;
}

.member .member-top figure {
  position: relative;
  z-index: 1;
  transition: all 0.4s;
}

.member .member-top figure img {
  display: block;
  width: 100%;
  height: auto;
}

.member .member-top .social-icons {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 0;
  padding: 7px 15px;
}

.member .member-top .social-icons label {
  color: #f2f2f2;
  font-weight: 400;
  margin-right: 4px;
  font-size: 12px;
}

.member .member-top .social-icons .social-icon {
  color: #eee;
  padding: 3px 2px;
}

.member .member-top .social-icons .social-icon:hover, .member .member-top .social-icons .social-icon:focus {
  color: #c00f3c;
}

.member .member-title {
  font-size: 17px;
  margin-bottom: 15px;
}

.member .member-title small {
  float: right;
  display: inline-block;
  font-size: 70%;
  background-color: #f4f4fa;
  color: #0069b0;
  padding: 2px 3px 3px;
  font-weight: 600;
}

.member .member-title:after {
  content: '';
  clear: both;
  display: table;
}

.member.text-center .member-top .social-icons {
  right: 0;
}

.member.text-center .member-title {
  margin-bottom: 10px;
}

.member.text-center .member-title small {
  float: none;
  display: block;
  margin-top: 8px;
  background-color: transparent;
}

.member span {
  color: #0069b0;
}

.member:hover .member-top figure {
  transform: translateY(-44px);
}

.owl-carousel .member {
  margin-bottom: 0;
}

/* Fix for member image animation */
.team-carousel-wrapper {
  overflow: hidden;
}

.team-carousel-wrapper .owl-stage-outer {
  overflow: visible;
}

.testimonials-inline .testimonial {
  position: relative;
}

.testimonials-inline .testimonial blockquote {
  background-color: #f0f0f0;
  border-color: #ebebeb;
  padding: 22px 18px 18px;
}

.bg-gray .testimonials-inline .testimonial blockquote {
  background-color: #e6e6e6;
  border-color: #dedede;
}

.testimonials-inline .testimonial blockquote figure {
  float: left;
  display: inline-block;
  max-width: 66px;
  margin-bottom: 10px;
  border-radius: 50%;
  border: 3px solid rgba(0, 0, 0, 0.15);
  margin-right: 19px;
}

@media (max-width: 767px) {
  .testimonials-inline .testimonial blockquote figure {
    float: none;
  }
}

.testimonials-inline .testimonial blockquote figure img {
  display: block;
  width: 100;
  height: auto;
  border-radius: 50%;
}

.testimonials-inline .testimonial blockquote p,
.testimonials-inline .testimonial blockquote cite {
  margin-left: 85px;
}

@media (max-width: 767px) {
  .testimonials-inline .testimonial blockquote p,
  .testimonials-inline .testimonial blockquote cite {
    margin-left: 0;
  }
}

.testimonials-inline .testimonial blockquote:after {
  content: '';
  clear: both;
  display: table;
}

.testimonials-inline .testimonial.transparent {
  background-color: transparent !important;
  border: none !important;
  color: inherit;
}

.testimonials-inline .testimonial.transparent blockquote {
  padding: 0;
  background-color: transparent !important;
  border: none !important;
  color: inherit;
}

.testimonials-inline .testimonial.transparent blockquote cite {
  color: inherit;
}

.testimonials-inline .owl-nav {
  margin-top: 15px;
}

.sync-carousel.owl-carousel .owl-item img {
  border: 1px solid transparent;
}

.sync-carousel.owl-carousel .owl-item.center img {
  border-color: #0069b0;
}

/*About Me*/
.aboutme-section-container {
  overflow: hidden;
}

.aboutme-section {
  margin-bottom: 35px;
}

@media (min-width: 768px) {
  .aboutme-section {
    margin-bottom: 45px;
  }
}

.aboutme-section p:last-child {
  margin-bottom: 0;
}

.aboutme-img {
  display: block;
  max-width: 100%;
  height: auto;
  margin: -10px auto 0;
}

/* -------- 8.Services Pages -----------
--------------------------------------*/
.testimonials-wrapper {
  max-width: 680px;
  margin: 0 auto;
}

.testimonial {
  max-width: 680px;
  margin: 0;
  color: #101010;
  background-color: rgba(255, 255, 255, 0.3);
  transition: background 0.5s ease;
}

.text-white .testimonial {
  color: inherit;
}

.testimonial.transparent {
  background-color: transparent !important;
}

.testimonial.dark {
  background-color: rgba(0, 0, 0, 0.4) !important;
}

.testimonial blockquote {
  background-color: transparent;
  margin: 0;
}

.testimonial figure {
  display: inline-block;
  max-width: 66px;
  margin-bottom: 10px;
  border-radius: 50%;
  border: 3px solid rgba(0, 0, 0, 0.15);
}

.bg-image .testimonial figure {
  border-color: rgba(255, 255, 255, 0.4);
}

.testimonial figure img {
  display: block;
  width: 100;
  height: auto;
  border-radius: 50%;
}

.testimonial cite {
  color: #222;
  font-weight: 600;
}

.text-white .testimonial cite {
  color: inherit;
}

.testimonial:hover {
  background-color: rgba(255, 255, 255, 0.55);
}

.testimonials-container {
  color: #fff;
}

.testimonials-container .owl-dot {
  background-color: #fff;
  border: 1px solid #fff;
  opacity: 0.45;
}

.testimonials-container .owl-dot:hover, .testimonials-container .owl-dot:focus {
  opacity: 1;
}

.testimonials-container .owl-dot.active {
  background-color: #0069b0 !important;
  border-color: #0069b0 !important;
  opacity: 1;
}

.testimonials-container.bg-gray .owl-dot {
  background-color: #b7b7b7;
  border-color: #b7b7b7;
}

.testimonials-container.bg-dark .testimonial, .testimonials-container.bg-darker .testimonial, .testimonials-container.bg-custom .testimonial, .testimonials-container.bg-custom2 .testimonial {
  background-color: transparent;
  color: #b7b7b7;
}

.testimonials-container.bg-dark .testimonial figure, .testimonials-container.bg-darker .testimonial figure, .testimonials-container.bg-custom .testimonial figure, .testimonials-container.bg-custom2 .testimonial figure {
  border-color: rgba(255, 255, 255, 0.3);
}

.testimonials-container.bg-dark .testimonial blockquote cite, .testimonials-container.bg-darker .testimonial blockquote cite, .testimonials-container.bg-custom .testimonial blockquote cite, .testimonials-container.bg-custom2 .testimonial blockquote cite {
  color: #fff;
}

.testimonials-container.bg-custom .testimonial {
  color: #31acff;
}

.testimonials-container.bg-custom .owl-dot.active {
  background-color: #004b7d !important;
  border-color: #004b7d !important;
}

.testimonials-container.bg-custom2 .testimonial {
  color: #f25c82;
}

.testimonials-container.bg-custom2 .owl-dot.active {
  background-color: #910b2d !important;
  border-color: #910b2d !important;
}

.client {
  display: block;
  border: 1px solid #f2f2f2;
  transition: border 0.4s;
}

.client img {
  display: block;
  width: 100%;
  height: auto;
}

.client:hover, .client:focus {
  border-color: #eaeaea;
}

.client.dark {
  border-color: #6e6e6e;
}

.client.dark:hover, .client.dark:focus {
  border-color: #222;
}

.client.no-border {
  border: none !important;
}

.bg-image .client:not(.dark),
.parallax .client:not(.dark),
.bg-custom .client:not(.dark),
.bg-custom2 .client:not(.dark),
.bg-dark .client:not(.dark),
.bg-darker .client:not(.dark) {
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.bg-image .client:not(.dark):hover, .bg-image .client:not(.dark):focus,
.parallax .client:not(.dark):hover,
.parallax .client:not(.dark):focus,
.bg-custom .client:not(.dark):hover,
.bg-custom .client:not(.dark):focus,
.bg-custom2 .client:not(.dark):hover,
.bg-custom2 .client:not(.dark):focus,
.bg-dark .client:not(.dark):hover,
.bg-dark .client:not(.dark):focus,
.bg-darker .client:not(.dark):hover,
.bg-darker .client:not(.dark):focus {
  border-color: #fff;
}

/* ----------- 9.Blog -----------------
--------------------------------------*/
.entry {
  margin-bottom: 70px;
  /* Entry Grid */
  /* Entry List */
  /* Entry list smaller*/
}

@media (max-width: 767px) {
  .entry {
    margin-bottom: 60px;
  }
}

.entry .entry-media {
  position: relative;
  margin-bottom: 25px;
  background-color: #0069b0;
}

.entry .entry-media figure {
  position: relative;
  z-index: 1;
  transition: all 0.45s;
}

.entry .entry-media figure a {
  display: block;
}

.entry .entry-media figure img {
  display: block;
  width: 100%;
  height: auto;
}

.entry .entry-media .entry-meta {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 10px 20px;
  color: #eaeaea;
}

.entry .entry-media .entry-meta a,
.entry .entry-media .entry-meta span {
  display: inline;
  color: #eaeaea;
  transition: color 0.4s;
}

.entry .entry-media .entry-meta a i,
.entry .entry-media .entry-meta span i {
  font-size: 90%;
  margin-right: 5px;
}

.entry .entry-media .entry-meta a:last-child,
.entry .entry-media .entry-meta span:last-child {
  margin-right: 0;
}

.entry .entry-media .entry-meta a + a:before,
.entry .entry-media .entry-meta a + span:before,
.entry .entry-media .entry-meta span + a:before,
.entry .entry-media .entry-meta span + span:before {
  content: '/';
  margin: 0 12px 0 10px;
}

.entry .entry-media .entry-meta a:hover, .entry .entry-media .entry-meta a:focus,
.entry .entry-media .entry-meta span:hover,
.entry .entry-media .entry-meta span:focus {
  color: #fff;
}

@media (max-width: 767px) {
  .entry .entry-media .entry-meta {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.entry .entry-title {
  color: #222;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.2;
  margin-bottom: 19px;
  position: relative;
}

.entry .entry-title i {
  color: #fff;
  background-color: #0069b0;
  width: 34px;
  height: 34px;
  font-size: 14px;
  line-height: 34px;
  text-align: center;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -17px;
}

.entry .entry-title a {
  display: block;
  color: #222;
  margin-left: 46px;
}

.entry .entry-title a:hover, .entry .entry-title a:focus {
  color: #0069b0;
}

.entry .entry-content > p:last-of-type {
  margin-bottom: 7px;
}

.entry .readmore {
  font-weight: 600;
  font-size: 13px;
  text-transform: uppercase;
  text-decoration: underline;
}

.entry .readmore i {
  margin-left: 5px;
}

.entry blockquote {
  padding-left: 25px;
  padding-right: 25px;
  margin-bottom: 0;
}

@media (max-width: 767px) {
  .entry blockquote {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.entry:hover .entry-media figure {
  transform: translateY(-42px);
}

.entry:hover.entry-grid .entry-media figure {
  transform: translateY(-32px);
}

.entry.entry-grid {
  margin-bottom: 30px;
}

.entry.entry-grid .entry-media {
  margin-bottom: 18px;
}

.entry.entry-grid .entry-title {
  font-size: 16px;
  margin-bottom: 15px;
}

.entry.entry-grid .entry-title i {
  width: 30px;
  height: 30px;
  font-size: 12px;
  line-height: 30px;
  margin-top: -15px;
}

.entry.entry-grid .entry-title a {
  margin-left: 40px;
}

.entry.entry-grid .entry-meta {
  font-size: 11px;
  padding: 7px 15px;
}

.entry.entry-grid .entry-meta a + a:before,
.entry.entry-grid .entry-meta a + span:before,
.entry.entry-grid .entry-meta span + a:before,
.entry.entry-grid .entry-meta span + span:before {
  content: '/';
  margin: 0 7px 0 5px;
}

.entry.entry-grid blockquote {
  font-size: 14px;
}

.entry.entry-grid .carousel-control {
  width: 30px;
  height: 40px;
  font-size: 15px;
  line-height: 40px;
  margin-top: -20px;
}

.entry.entry-grid .readmore {
  font-size: 11px;
}

.entry.entry-list {
  margin-bottom: 60px;
}

@media (min-width: 992px) {
  .entry.entry-list .entry-media {
    margin-bottom: 0;
  }
}

.entry.entry-list-sm {
  margin-bottom: 25px;
}

@media (min-width: 768px) {
  .entry.entry-list-sm {
    margin-bottom: 35px;
  }
}

.entry.entry-list-sm .entry-media {
  margin-bottom: 14px;
}

@media (min-width: 481px) {
  .entry.entry-list-sm .entry-media {
    margin-bottom: 0;
  }
}

.entry.entry-list-sm .row {
  margin-left: -10px;
  margin-right: -10px;
}

.entry.entry-list-sm .row [class*="col-"] {
  padding-left: 10px;
  padding-right: 10px;
}

.entry.entry-list-sm .entry-title {
  font-weight: 600;
  font-size: 14px;
  line-height: 1.35;
  margin-bottom: 7px;
  margin-top: -2px;
}

@media (min-width: 768px) {
  .entry.entry-list-sm .entry-title {
    font-size: 15px;
  }
}

.entry.entry-list-sm .entry-title a {
  margin-left: 0;
}

.entry.entry-list-sm .entry-meta {
  font-size: 13px;
}

.entry.entry-list-sm .entry-meta > span,
.entry.entry-list-sm .entry-meta > a {
  display: block;
  color: #444;
  margin-bottom: 2px;
}

.entry.entry-list-sm .entry-meta > span i,
.entry.entry-list-sm .entry-meta > a i {
  margin-right: 5px;
  color: #666;
}

.entry.entry-list-sm .entry-meta a {
  color: #444;
}

.entry.entry-list-sm .entry-meta a:hover, .entry.entry-list-sm .entry-meta a:focus {
  color: #0069b0;
}

.entry.entry-list-sm .readmore {
  font-size: 12px;
}

.entry.entry-list-sm:hover .entry-media figure, .entry.entry-list-sm:focus .entry-media figure {
  transform: translateY(0);
}

.touch .entry .entry-media .entry-meta,
.touch .entry.entry-grid .entry-media .entry-meta {
  position: static;
  left: auto;
  right: auto;
  bottom: auto;
}

.touch .entry:hover .entry-media figure,
.touch .entry.entry-grid:hover .entry-media figure {
  transform: translateY(0);
}

#blog-item-container {
  margin-bottom: 40px;
}

@media (max-width: 767px) {
  #blog-item-container {
    margin-bottom: 25px;
  }
}

#blog-item-container > .entry-grid {
  float: left;
}

#blog-item-container:after {
  content: '';
  display: table;
  clear: both;
}

.blog-row {
  margin-left: -15px;
  margin-right: -15px;
}

.blog-row:after {
  content: '';
  display: table;
  clear: both;
}

.row > #blog-item-container > .entry-grid,
.blog-row > #blog-item-container > .entry-grid {
  padding: 0 15px;
}

@media (min-width: 768px) {
  .max-col-6 .entry-grid,
  .max-col-5 .entry-grid,
  .max-col-4 .entry-grid,
  .max-col-3 .entry-grid,
  .max-col-2 .entry-grid {
    width: 50%;
  }
  .max-col-6 .entry-grid.wide,
  .max-col-5 .entry-grid.wide,
  .max-col-4 .entry-grid.wide,
  .max-col-3 .entry-grid.wide,
  .max-col-2 .entry-grid.wide {
    width: 100%;
  }
}

@media (min-width: 992px) {
  .max-col-4 .entry-grid,
  .max-col-3 .entry-grid {
    width: 33.3%;
  }
  .max-col-4 .entry-grid.wide,
  .max-col-3 .entry-grid.wide {
    width: 66.6%;
  }
}

@media (min-width: 992px) {
  .max-col-6 .entry-grid,
  .max-col-5 .entry-grid {
    width: 25%;
  }
  .max-col-6 .entry-grid.wide,
  .max-col-5 .entry-grid.wide {
    width: 50%;
  }
}

@media (min-width: 1200px) {
  .max-col-4 .entry-grid {
    width: 25%;
  }
  .max-col-4 .entry-grid.wide {
    width: 50%;
  }
}

@media (min-width: 1200px) {
  .max-col-5 .entry-grid {
    width: 20%;
  }
  .max-col-5 .entry-grid.wide {
    width: 40%;
  }
}

@media (min-width: 1200px) {
  .max-col-6 .entry-grid {
    width: 16.66%;
  }
  .max-col-6 .entry-grid.wide {
    width: 33.3%;
  }
}

/* Sidebar */
@media (max-width: 991px) {
  .sidebar {
    margin-bottom: 90px;
  }
}

.sidebar .widget {
  margin-bottom: 50px;
}

.sidebar .widget a {
  color: #323232;
}

.sidebar .widget a:hover, .sidebar .widget a:focus {
  color: #0069b0;
}

.sidebar .widget .widget-title {
  font-size: 15px;
  margin: 0 0 25px;
  text-transform: uppercase;
  color: #323232;
}

.sidebar .widget .widget-title > span {
  display: inline-block;
  background-color: #fff;
  padding-right: 35px;
}

.sidebar .widget.search-widget {
  position: relative;
}

.sidebar .widget.search-widget .form-control {
  padding-right: 90px;
  background-color: #f6f6fa;
  border-color: #eee;
  margin-bottom: 0;
}

.sidebar .widget.search-widget .btn {
  position: absolute;
  right: 0;
  top: 0;
  border-radius: 0;
  text-align: center;
  width: 38px;
  font-size: 16px;
  padding: 7px 5px;
  color: #a7a7a7;
}

.sidebar .widget.search-widget .btn:hover, .sidebar .widget.search-widget .btn:focus {
  color: #0069b0;
  outline: none;
}

.sidebar .widget .posts-list li {
  margin-bottom: 12px;
  padding: 6px;
  background-color: #f6f6fa;
  transition: all 0.4s;
}

.sidebar .widget .posts-list li figure {
  max-width: 52px;
  float: left;
}

.sidebar .widget .posts-list li figure a {
  display: block;
}

.sidebar .widget .posts-list li figure img {
  display: block;
  width: 100%;
  height: auto;
}

.sidebar .widget .posts-list li h5, .sidebar .widget .posts-list li span {
  display: block;
  margin-left: 62px;
}

.sidebar .widget .posts-list li h5 {
  font-size: 13px;
  font-weight: 600;
  margin-top: 6px;
  margin-bottom: 4px;
}

.sidebar .widget .posts-list li span {
  font-style: italic;
  font-size: 12px;
}

.sidebar .widget .posts-list li:after {
  content: '';
  display: table;
  clear: both;
}

.sidebar .widget .posts-list li:hover {
  background-color: #eee;
}

.sidebar .widget .fa-ul li {
  position: relative;
  margin-bottom: 9px;
  padding-bottom: 4px;
}

.sidebar .widget .fa-ul li:last-child {
  margin-bottom: 0;
}

.sidebar .widget .fa-ul li .fa-li {
  font-size: 85%;
}

.sidebar .widget .fa-ul li:before, .sidebar .widget .fa-ul li:after {
  content: '';
  position: absolute;
  bottom: 0;
  display: block;
  height: 1px;
}

.sidebar .widget .fa-ul li:before {
  left: 0;
  right: 0;
  background-color: #eee;
}

.sidebar .widget .fa-ul li:after {
  transition: all 0.45s;
  left: 0;
  right: 100%;
  background-color: #0069b0;
}

.sidebar .widget .fa-ul li:hover:after {
  left: 0;
  right: 0;
}

.sidebar .widget .tagcloud a {
  display: block;
  float: left;
  padding: 4px 9px;
  background-color: #f6f6fa;
  color: #333;
  margin: 0 6px 6px 0;
  transition: background 0.45s;
}

.sidebar .widget .tagcloud a:hover, .sidebar .widget .tagcloud a:focus {
  background-color: #0069b0;
  color: #fff;
}

.sidebar .widget .tagcloud:after {
  content: '';
  display: table;
  clear: both;
}

.sidebar .widget.flickr-widget .row {
  margin-left: -4px;
  margin-right: -4px;
}

.sidebar .widget.flickr-widget .flickr-widget-list {
  list-style: none;
}

.sidebar .widget.flickr-widget .flickr-widget-list li {
  display: block;
  float: left;
  margin: 0 3px 6px;
  padding: 0;
  width: calc( 25% - 6px);
  max-width: 60px;
  overflow: hidden;
}

.sidebar .widget.flickr-widget .flickr-widget-list li a {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
}

.sidebar .widget.flickr-widget .flickr-widget-list li a img {
  display: block;
  width: 100%;
  height: auto;
}

.sidebar .widget .tweet_list li {
  padding: 7px 10px 7px 10px;
  position: relative;
  margin-bottom: 14px;
  background-color: #f6f6fa;
  transition: all 0.4s;
}

.sidebar .widget .tweet_list li .twitter-icon {
  position: absolute;
  right: 12px;
  bottom: 6px;
  font-size: 20px;
  line-height: 1;
  color: #c7c7c7;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.06);
}

.sidebar .widget .tweet_list li .tweet_time {
  display: block;
  position: relative;
  font-size: 12px;
  font-style: italic;
  color: #6a6a6a;
  margin-top: 9px;
  padding-top: 3px;
}

.sidebar .widget .tweet_list li .tweet_time:after {
  content: '';
  display: block;
  width: 28px;
  height: 1px;
  background-color: #eee;
  position: absolute;
  top: 0;
  left: 0;
}

.sidebar .widget .tweet_list li:hover {
  background-color: #eaeaea;
}

.sidebar .widget .tweet_list li:last-child {
  margin-bottom: 0;
}

/* Single Post */
.single .entry:not(.entry-grid) {
  margin-bottom: 50px;
}

.single .entry:not(.entry-grid):hover figure {
  transform: translateY(0);
}

.single .entry:not(.entry-grid) .entry-media {
  margin-bottom: 30px;
}

.single .entry:not(.entry-grid) > .entry-title {
  font-size: 20px;
  margin-bottom: 20px;
}

.single .entry:not(.entry-grid) > .entry-meta {
  margin-bottom: 18px;
}

.single .entry:not(.entry-grid) > .entry-meta a, .single .entry:not(.entry-grid) > .entry-meta span {
  color: #6a6a6a;
}

.single .entry:not(.entry-grid) > .entry-meta a i, .single .entry:not(.entry-grid) > .entry-meta span i {
  margin-right: 10px;
  font-size: 90%;
}

.single .entry:not(.entry-grid) > .entry-meta a + a:before,
.single .entry:not(.entry-grid) > .entry-meta a + span:before, .single .entry:not(.entry-grid) > .entry-meta span + a:before,
.single .entry:not(.entry-grid) > .entry-meta span + span:before {
  content: '/';
  margin: 0 12px 0 10px;
}

.single .entry:not(.entry-grid) > .entry-meta a:hover, .single .entry:not(.entry-grid) > .entry-meta a:focus {
  color: #0069b0;
}

.single .entry:not(.entry-grid) > .entry-content {
  margin-bottom: 40px;
}

.single .entry:not(.entry-grid) > .entry-content blockquote {
  margin: 30px 0;
}

.single .entry:not(.entry-grid) > .entry-content h2, .single .entry:not(.entry-grid) > .entry-content h3, .single .entry:not(.entry-grid) > .entry-content h4 {
  line-height: 1.35;
  margin-top: 25px;
}

.single .entry-author {
  padding: 25px 25px 20px;
  color: #333;
  background-color: #f6f6fa;
}

.single .entry-author figure {
  float: left;
}

@media (max-width: 480px) {
  .single .entry-author figure {
    max-width: 50px;
    margin-right: 15px;
  }
}

.single .entry-author a {
  color: #222;
}

.single .entry-author a:hover, .single .entry-author a:focus {
  color: #0069b0;
}

.single .entry-author .author-content {
  margin-left: 115px;
}

@media (max-width: 480px) {
  .single .entry-author .author-content {
    margin-left: 0;
  }
}

.single .entry-author .author-content h4 {
  font-weight: 600;
  font-size: 15px;
  margin-bottom: 8px;
}

.single .entry-author .author-content p:last-of-type {
  margin-bottom: 5px;
}

.single .entry-author .author-content .social-icon {
  color: #4e4e4e;
}

.single .entry-author .author-content .social-icon:hover, .single .entry-author .author-content .social-icon:focus {
  color: #0069b0;
}

.single .entry-author:after {
  content: '';
  display: table;
  clear: both;
}

.single .single-related-posts {
  margin-bottom: 35px;
}

.single .single-related-posts .entry {
  margin-bottom: 0;
}

.single .comments {
  margin-bottom: 30px;
}

.single .comments .media {
  margin-top: 0;
}

.single .comments .media .media-object, .single .comments .media .media-object img {
  border-radius: 0;
}

.single .comments .media .media-left {
  padding: 0;
}

@media (max-width: 480px) {
  .single .comments .media .media-left {
    display: none;
  }
}

.single .comments .media .media-body {
  padding-left: 25px;
}

@media (max-width: 480px) {
  .single .comments .media .media-body {
    padding-left: 0;
  }
}

.single .comments .media ul {
  margin-left: 60px;
}

@media (max-width: 767px) {
  .single .comments .media ul {
    margin-left: 40px;
  }
}

@media (max-width: 480px) {
  .single .comments .media ul {
    margin-left: 20px;
  }
}

.single .comments .comment {
  padding: 20px;
  background-color: #f6f6fa;
  margin-bottom: 20px;
}

.single .comments .comment h4 {
  font-size: 14px;
  color: #222;
  margin-bottom: 9px;
  font-weight: 600;
}

.single .comments .comment h4 span {
  float: right;
  color: #d7d7d7;
  font-size: 90%;
  font-weight: 400;
}

.single .comments .comment p:last-child {
  margin-bottom: 0;
}

.single .comment-respond {
  margin-bottom: 70px;
}

@media (min-width: 992px) {
  .single .comment-respond {
    margin-bottom: 90px;
  }
}

/* ----------- 10.Portfolio -------------
--------------------------------------*/
#portfolio-filter {
  border-top: 1px solid #eaeaea;
  border-bottom: 1px solid #eaeaea;
  margin: 0 0 60px;
  font-size: 0;
}

.bg-gray #portfolio-filter {
  border-color: #e1e1e1;
}

#portfolio-filter li {
  position: relative;
  display: inline-block;
}

#portfolio-filter li:before {
  content: '';
  position: absolute;
  top: -1px;
  left: 50%;
  right: 50%;
  display: block;
  height: 2px;
  background-color: #0069b0;
  transition: all 0.35s;
}

#portfolio-filter li a {
  display: inline-block;
  color: #323232;
  font-size: 11px;
  font-weight: 600;
  text-transform: uppercase;
  padding: 10px;
  transition: all 0.4s;
}

@media (min-width: 768px) {
  #portfolio-filter li a {
    font-size: 12px;
    padding: 15px 10px;
  }
}

@media (min-width: 992px) {
  #portfolio-filter li a {
    font-size: 13px;
  }
}

#portfolio-filter li a:hover, #portfolio-filter li a:focus {
  color: #0069b0;
}

#portfolio-filter li.active:before {
  left: 0;
  right: 0;
}

#portfolio-filter li.active a {
  color: #0069b0;
}

#portfolio-item-container {
  transition: all 0.45s;
  margin-bottom: 70px;
}

#portfolio-item-container:after {
  content: '';
  display: table;
  clear: both;
}

.portfolio-item {
  position: relative;
  float: left;
  margin-bottom: 30px;
  width: 100%;
  /* Portfolio No gap */
}

.portfolio-item a {
  color: #222;
}

.portfolio-item a:hover, .portfolio-item a:focus {
  color: #0069b0;
}

.portfolio-item figure {
  position: relative;
}

.portfolio-item figure img {
  display: block;
  width: 100%;
  height: auto;
}

.portfolio-item figure:after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #101010;
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s;
}

.portfolio-item .zoom-btn,
.portfolio-item .link-btn {
  display: inline-block;
  position: absolute;
  top: 50%;
  margin-top: -19px;
  width: 38px;
  height: 38px;
  font-size: 15px;
  line-height: 38px;
  text-align: center;
  color: #fff;
  border-radius: 50%;
  opacity: 0;
  visibility: hidden;
  transition: all 0.45s;
}

.portfolio-item .zoom-btn {
  background-color: rgba(0, 105, 176, 0.6);
  transform: translateX(-25px);
  left: 50%;
  margin-left: -36px;
  z-index: 100;
}

.portfolio-item .zoom-btn:hover, .portfolio-item .zoom-btn:focus {
  color: #fff;
  background-color: rgba(0, 105, 176, 0.88);
}

.portfolio-item .link-btn {
  background-color: rgba(16, 16, 16, 0.6);
  transform: translateX(25px);
  right: 50%;
  margin-right: -36px;
  z-index: 99;
}

.portfolio-item .link-btn:hover, .portfolio-item .link-btn:focus {
  color: #fff;
  background-color: rgba(16, 16, 16, 0.88);
  z-index: 101;
}

.portfolio-item .portfolio-meta {
  position: relative;
  margin-top: 14px;
}

.portfolio-item .portfolio-title {
  color: #222;
  font-size: 14px;
  line-height: 1.25;
  font-weight: 400;
  margin: 0 0 3px;
}

.portfolio-item .portfolio-tags {
  font-size: 12px;
}

.portfolio-item .portfolio-tags a {
  color: #9e9e9e;
}

.portfolio-item .portfolio-tags a:hover, .portfolio-item .portfolio-tags a:focus {
  color: #0069b0;
}

.portfolio-item:hover figure:after {
  visibility: visible;
  opacity: 0.5;
}

.portfolio-item:hover .zoom-btn,
.portfolio-item:hover .link-btn {
  visibility: visible;
  opacity: 1;
  transform: translateX(0);
}

.portfolio-item.portfolio-anim {
  margin-bottom: 0;
  background-color: #0069b0;
}

.portfolio-item.portfolio-anim figure {
  position: relative;
  z-index: 1;
  transition: all 0.5s;
}

.portfolio-item.portfolio-anim .portfolio-meta {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  color: #fff;
  margin: 0;
  z-index: 0;
  padding: 14px 15px 13px;
}

.portfolio-item.portfolio-anim .portfolio-title a {
  color: #eee;
}

.portfolio-item.portfolio-anim .portfolio-title a:hover, .portfolio-item.portfolio-anim .portfolio-title a:focus {
  color: #fff;
}

.portfolio-item.portfolio-anim .portfolio-tags {
  color: #b7b7b7;
}

.portfolio-item.portfolio-anim .portfolio-tags a {
  color: #0097fd;
}

.portfolio-item.portfolio-anim .portfolio-tags a:hover, .portfolio-item.portfolio-anim .portfolio-tags a:focus {
  color: #fff;
}

.portfolio-item.portfolio-anim:hover figure {
  transform: translateY(-72px);
}

.portfolio-row {
  margin-left: -15px;
  margin-right: -15px;
}

.portfolio-row:after {
  content: '';
  display: table;
  clear: both;
}

.row > #portfolio-item-container,
.portfolio-row > #portfolio-item-container {
  margin-bottom: 40px;
}

.row > #portfolio-item-container > .portfolio-item,
.portfolio-row > #portfolio-item-container > .portfolio-item {
  padding: 0 15px;
}

@media (min-width: 768px) {
  .max-col-6 .portfolio-item,
  .max-col-5 .portfolio-item,
  .max-col-4 .portfolio-item,
  .max-col-3 .portfolio-item,
  .max-col-2 .portfolio-item {
    width: 50%;
  }
}

@media (min-width: 992px) {
  .max-col-4 .portfolio-item,
  .max-col-3 .portfolio-item {
    width: 33.3%;
  }
}

@media (min-width: 992px) {
  .max-col-6 .portfolio-item,
  .max-col-5 .portfolio-item {
    width: 25%;
  }
}

@media (min-width: 1200px) {
  .max-col-4 .portfolio-item {
    width: 25%;
  }
  .max-col-4 .portfolio-item.wide {
    width: 50%;
  }
}

@media (min-width: 1200px) {
  .max-col-5 .portfolio-item {
    width: 20%;
  }
  .max-col-5 .portfolio-item.wide {
    width: 40%;
  }
}

@media (min-width: 1200px) {
  .max-col-6 .portfolio-item {
    width: 16.66%;
  }
  .max-col-6 .portfolio-item.wide {
    width: 33.3%;
  }
}

/* Light gallery lightbox custom css */
.lg-outer .lg-thumb {
  padding: 8px 0;
}

.lg-outer .lg-thumb-item {
  border-radius: 0;
}

/* Single Portfolio */
.portfolio-post-media {
  margin-bottom: 40px;
}

.portfolio-post-media img {
  display: block;
  width: 100%;
  height: auto;
}

.portfolio-post-content {
  margin-bottom: 40px;
}

.portfolio-post-content .portfolio-title {
  font-size: 20px;
  line-height: 1.2;
}

.portfolio-post-content ul {
  padding: 13px 15px 10px;
  background-color: #f6f6fa;
}

.portfolio-post-content ul li {
  padding: 5px 0;
  border-bottom: 1px solid #eee;
}

.portfolio-post-content ul li > span {
  display: inline-block;
  font-weight: 600;
  color: #222;
  margin-right: 5px;
  min-width: 70px;
}

.portfolio-post-content ul li i {
  margin-right: 5px;
}

.portfolio-post-content ul li:last-child {
  border-bottom: none;
}

.portfolio-related-carousel .portfolio-item {
  margin-bottom: 0;
}

/* --------- 11.Category(Shop) - Product
--------------------------------------*/
/* Products (Shop) */
.product {
  position: relative;
  margin-bottom: 30px;
  width: 100%;
  padding-bottom: 26px;
  /* Product List */
  /*Product-small */
}

.product .product-top {
  position: relative;
  margin-bottom: 13px;
  overflow: hidden;
}

.product .product-top figure a {
  display: block;
}

.product .product-top img {
  display: block;
  width: 100%;
  height: auto;
}

.product .image-hover {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  visibility: hidden;
  opacity: 0;
  transition: all 0.2s;
}

.product:hover .image-hover {
  visibility: visible;
  opacity: 1;
}

.product .product-title {
  color: #222;
  font-size: 14px;
  line-height: 1.3;
  margin-bottom: 2px;
}

.product .product-title a {
  color: #222;
}

.product .product-title a:hover, .product .product-title a:focus {
  color: #0069b0;
}

.product .product-price-container {
  font-size: 16px;
  color: #f41d1d;
  position: absolute;
  left: 0;
  bottom: 0;
}

.product .product-price-container .old-price {
  color: #9e9e9e;
  font-size: 13px;
  text-decoration: line-through;
}

.product .product-price-container span {
  vertical-align: baseline;
}

.product .product-price-container .product-price {
  font-weight: 600;
}

.product .btn.product-add-btn {
  color: #fff;
  background-color: #0069b0;
  border: none;
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0.9;
  z-index: 10;
  padding: 6px 10px;
  font-size: 13px;
  transition: all 0.45s;
  transform: translateY(32px);
}

.product .btn.product-add-btn:hover, .product .btn.product-add-btn:focus {
  color: #fff;
  opacity: 1;
  background-color: #004b7d;
}

.product:hover .btn.product-add-btn, .product.focus .btn.product-add-btn {
  transform: translateY(0);
}

.product .label {
  position: absolute;
  top: 0;
  z-index: 20;
  font-size: 12px;
  font-weight: 400;
  padding: 6px 11px;
  border-radius: 0;
  opacity: 0.8;
}

.product .label.popular {
  left: 0;
  color: #fff;
  background-color: #49ba17;
}

.product .label.discount {
  right: 0;
  color: #fff;
  background-color: #d10707;
}

.product.product-list {
  padding-bottom: 0;
}

@media (min-width: 768px) {
  .product.product-list .product-title {
    font-size: 16px;
    margin-bottom: 12px;
  }
  .product.product-list .product-top {
    margin-bottom: 0;
  }
}

@media (min-width: 992px) {
  .product.product-list {
    margin-bottom: 40px;
  }
}

.product.product-list .row {
  margin-left: -10px;
  margin-right: -10px;
}

.product.product-list .row > [class*="col-"] {
  padding-left: 10px;
  padding-right: 10px;
}

.product.product-list .product-content {
  display: none;
}

@media (min-width: 768px) {
  .product.product-list .product-content {
    display: block;
  }
}

.product.product-list .product-price-container {
  position: static;
  left: auto;
  right: auto;
  bottom: 0;
}

.product.product-sm {
  margin-bottom: 16px;
  padding-bottom: 0;
}

.product.product-sm:after {
  content: '';
  display: table;
  clear: both;
}

.product.product-sm .product-top {
  width: 90px;
  float: left;
  border: 1px solid #eee;
  margin-bottom: 0;
}

.product.product-sm .product-title,
.product.product-sm .product-price-container {
  padding-left: 105px;
}

.product.product-sm .product-title {
  max-width: 240px;
}

.product.product-sm .product-price-container {
  position: static;
  left: auto;
  right: auto;
  bottom: auto;
  margin: 0;
}

.products-container {
  margin-bottom: 20px;
}

.products-container > .row,
.products-container > .shop-row {
  margin-left: -10px;
  margin-right: -10px;
}

.products-container > .row > .product,
.products-container > .shop-row > .product {
  float: left;
  padding-left: 10px;
  padding-right: 10px;
}

.products-container > .row > .product .product-price-container,
.products-container > .shop-row > .product .product-price-container {
  left: 10px;
}

.shop-row:after {
  content: '';
  display: table;
  clear: both;
}

@media (min-width: 768px) {
  .max-col-5 .product,
  .max-col-4 .product,
  .max-col-3 .product,
  .max-col-2 .product {
    width: 50%;
  }
  .max-col-5 .product.wide,
  .max-col-4 .product.wide,
  .max-col-3 .product.wide,
  .max-col-2 .product.wide {
    width: 100%;
  }
}

@media (min-width: 992px) {
  .max-col-4 .product,
  .max-col-3 .product {
    width: 33.3%;
  }
  .max-col-4 .product.wide,
  .max-col-3 .product.wide {
    width: 66.6%;
  }
}

@media (min-width: 992px) {
  .max-col-6 .product,
  .max-col-5 .product {
    width: 25%;
  }
  .max-col-6 .product.wide,
  .max-col-5 .product.wide {
    width: 50%;
  }
}

@media (min-width: 1200px) {
  .max-col-4 .product {
    width: 25%;
  }
  .max-col-4 .product.wide {
    width: 50%;
  }
}

@media (min-width: 1200px) {
  .max-col-5 .product {
    width: 20%;
  }
  .max-col-5 .product.wide {
    width: 40%;
  }
}

/* Product Single - product.html */
.product-gallery-container {
  margin-bottom: 40px;
}

@media (min-width: 992px) {
  .product-gallery-container {
    margin-bottom: 60px;
  }
}

.product-zoom-wrapper {
  padding: 2px;
  background-color: #eee;
}

.product-gallery-container img {
  display: block;
  width: 100%;
  height: auto;
}

.product-gallery a {
  display: block;
  padding: 2px;
  background-color: #eee;
  transition: background 0.4s;
}

.product-gallery a:hover, .product-gallery a:focus {
  background-color: #0069b0;
}

.product-gallery-wrapper {
  margin-top: 4px;
}

.product-details {
  margin-bottom: 40px;
}

@media (min-width: 992px) {
  .product-details {
    margin-bottom: 0;
  }
}

.product-details .product-title {
  color: #222;
  line-height: 1.2;
  margin-bottom: 12px;
}

.product-details .review-container {
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  padding: 10px 0;
  margin-bottom: 5px;
}

.product-details .review-count {
  margin-left: 14px;
}

.product-details .review-action:before {
  content: '|';
  padding: 0 5px;
  color: #777;
}

.product-details .product-price-container {
  font-size: 26px;
  color: #f41d1d;
  margin-bottom: 9px;
}

.product-details .product-price-container .old-price {
  color: #9e9e9e;
  font-size: 18px;
  text-decoration: line-through;
}

.product-details .product-price-container span {
  vertical-align: baseline;
}

.product-details .product-price-container .product-price {
  font-weight: 600;
}

.product-details .product-desc-box {
  position: relative;
  max-width: 300px;
  padding: 13px 16px;
  background-color: #f6f6fa;
  border: 1px solid #f0f0fa;
  margin-bottom: 15px;
}

.product-details .product-desc-box li {
  margin-bottom: 1px;
}

.product-details .product-desc-box .shipping-box {
  text-align: center;
  border-radius: 50%;
  position: absolute;
  right: -15px;
  top: -15px;
  min-width: 70px;
  height: 70px;
  font-size: 10px;
  line-height: 1.2;
  background-color: #222;
  color: #fafafa;
  padding-top: 9px;
  font-weight: 600;
  transform: rotate(15deg);
}

.product-details .product-desc-box .shipping-box i {
  font-size: 14px;
  margin-bottom: 5px;
}

.product-details label {
  font-size: 13px;
  color: #323232;
}

.product-details .product-action {
  font-size: 0;
  margin-top: 12px;
  padding: 14px 0;
  border-top: 1px solid #eee;
}

.product-details .product-action .product-quantity {
  display: inline-block;
  max-width: 120px;
  vertical-align: middle;
}

.product-details .product-action .product-quantity .form-control {
  margin-bottom: 0;
}

.product-details .product-action .product-quantity + .btn {
  margin-left: 15px;
}

.product-details .product-action .product-quantity .btn {
  vertical-align: middle;
}

@media (min-width: 768px) {
  .product-details .panel .panel-body {
    padding: 15px 13px;
  }
}

.product-details .panel p {
  line-height: 1.55;
  text-indent: 10px;
}

.product-details .panel p:last-child {
  margin-bottom: 0;
}

.product-details .panel .fa-ul {
  margin-bottom: 0;
}

.product-details .panel .fa-ul li {
  margin-bottom: 5px;
  line-height: 1.55;
}

.product-details .panel .fa-ul li:last-child {
  margin-bottom: 0;
}

.sidebar.product-sidebar .widget .widget-title.smaller {
  padding: 8px 12px;
  background-color: #f6f6fa;
  border: none;
}

@media (min-width: 768px) {
  .sidebar.product-sidebar .widget .widget-title.smaller {
    font-size: 12px;
    padding: 9px 12px 7px;
  }
}

.sidebar.product-sidebar .widget .product {
  margin-bottom: 25px;
}

@media (min-width: 768px) {
  .sidebar.product-sidebar .widget .product {
    padding-bottom: 23px;
  }
}

.sidebar.product-sidebar .widget .product:last-child {
  margin-bottom: 0;
}

@media (min-width: 768px) {
  .sidebar.product-sidebar .widget .product .product-title {
    font-size: 13px;
  }
}

@media (min-width: 768px) {
  .sidebar.product-sidebar .widget .product .product-price-container {
    font-size: 14px;
  }
}

/* Category(Shop) Pages */
.category-filter-row {
  margin-bottom: 25px;
  padding: 6px;
  background-color: #f6f6fa;
}

@media (min-width: 768px) {
  .category-filter-row {
    padding: 8px 15px;
  }
}

.category-filter-row .left {
  white-space: nowrap;
}

.category-filter-row .left .filter-container {
  float: left;
}

.category-filter-row .left .filter-container.filter-show {
  float: right;
}

@media (min-width: 768px) {
  .category-filter-row .left {
    float: left;
    margin-bottom: 0;
  }
  .category-filter-row .left .filter-container {
    float: left;
  }
  .category-filter-row .left .filter-container.filter-show {
    float: left;
  }
  .category-filter-row .left .filter-sort {
    margin-right: 25px;
  }
}

.category-filter-row .left:after {
  content: '';
  display: table;
  clear: both;
}

.category-filter-row .right {
  margin-bottom: 10px;
  white-space: nowrap;
}

@media (min-width: 768px) {
  .category-filter-row .right {
    float: right;
    margin-bottom: 0;
  }
}

.category-filter-row .right:after {
  content: '';
  display: table;
  clear: both;
}

.category-filter-row label,
.category-filter-row .form-control {
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 0;
  width: auto;
}

.category-filter-row label {
  margin-right: 6px;
}

.category-filter-row .form-control {
  padding-left: 5px;
  padding-right: 5px;
}

.category-filter-row:after {
  content: '';
  display: table;
  clear: both;
}

.category-filter-row .cat-product-count,
.category-filter-row .btn,
.category-filter-row .filter-product-view {
  display: inline-block;
  vertical-align: middle;
}

.category-filter-row .btn + .btn {
  margin-left: 5px;
}

.category-filter-row .cat-product-count {
  font-weight: 600;
  line-height: 32px;
  margin-right: 25px;
}

@media (max-width: 767px) {
  .category-filter-row .cat-product-count {
    float: left;
  }
}

.category-filter-row .filter-product-view {
  font-size: 0;
}

.category-filter-row .filter-product-view .btn.btn-sm {
  min-width: 32px;
  text-align: center;
  font-size: 18px;
  padding: 1px 3px 2px;
}

.category-filter-row .filter-product-view .btn.btn-sm i {
  margin: 0;
}

@media (max-width: 767px) {
  .category-filter-row .filter-product-view {
    float: right;
  }
}

/* Sidebar */
@media (max-width: 991px) {
  .sidebar.shop-sidebar {
    margin-bottom: 70px;
  }
}

.sidebar.shop-sidebar .widget {
  margin-bottom: 35px;
}

.sidebar.shop-sidebar .widget .widget-title {
  margin-bottom: 18px;
}

.sidebar.shop-sidebar .widget .btn {
  color: #fff;
}

.category-widget-btn {
  display: block;
  width: 20px;
  height: 20px;
  font-family: 'FontAwesome';
  font-size: 10px;
  line-height: 21px;
  text-align: center;
  color: #0069b0;
  background-color: #eaeaea;
  border-radius: 50%;
  position: absolute;
  left: 10px;
  top: 8px;
  transition: all 0.4s;
  -webkit-transition: all 0.4s;
}

.category-widget-btn:after {
  content: "\f105";
}

.category-widget-btn:hover, .category-widget-btn:focus {
  background-color: #333;
  color: #fff;
}

.open .category-widget-btn:after {
  content: "\f107";
}

.filter-price-label {
  font-size: 12px;
}

.filter-color-container,
.filter-size-container {
  font-size: 0;
}

.filter-color-container .filter-row,
.filter-size-container .filter-row {
  margin-left: -2.5px;
  margin-right: -2.5px;
}

.filter-color-container .filter-row:after,
.filter-size-container .filter-row:after {
  content: '';
  display: table;
  clear: both;
}

.filter-color-box,
.filter-size-box {
  display: inline-block;
  width: 24px;
  height: 24px;
  margin: 0 2.5px 5px;
  transition: all 0.5s;
}

.filter-color-box:hover, .filter-color-box:focus,
.filter-size-box:hover,
.filter-size-box:focus {
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.4);
}

.filter-size-box {
  color: #323232 !important;
  background-color: #eaeaea;
  font-size: 11px;
  line-height: 24px;
  text-align: center;
  text-transform: uppercase;
}

.filter-size-box.active, .filter-size-box:hover, .filter-size-box:focus {
  color: #fff !important;
  background-color: #0069b0;
}

.sidebar .filter-group-widget .panel-body {
  padding: 35px 0 30px;
}

.filter-brand-list {
  margin-top: -10px;
}

.filter-brand-list a {
  color: #323232;
  display: block;
  font-size: 13px;
  line-height: 22px;
  color: #7e7e7e;
  padding: 2px 0;
}

.filter-brand-list a:hover, .filter-brand-list a:focus {
  color: #0069b0;
}

.filter-brand-list a i {
  margin-right: 8px;
}

.filter-brand-list a span {
  float: right;
}

#filter-range-details.row {
  margin-left: -5px;
  margin-right: -5px;
}

#filter-range-details.row [class*="col-"] {
  padding-left: 5px;
  padding-right: 5px;
}

#price-slider {
  margin: 0 0 35px;
}

/* noUiSlider Styles */
/* Functional styling;
* These styles are required for noUiSlider to function.
* You don't need to change these rules to apply your design.
*/
.noUi-target,
.noUi-target * {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-touch-action: none;
  -ms-user-select: none;
  -moz-user-select: none;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.noUi-target {
  position: relative;
  direction: ltr;
}

.noUi-base {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
  /* Fix 401 */
}

.noUi-origin {
  position: absolute;
  right: 0;
  top: -1px;
  left: 0;
  bottom: 0;
  height: 3px;
}

.noUi-handle {
  position: relative;
  z-index: 1;
}

.noUi-stacking .noUi-handle {
  /* This class is applied to the lower origin when
   its values is > 50%. */
  z-index: 10;
}

.noUi-state-tap .noUi-origin {
  transition: left 0.3s, top 0.3s;
}

.noUi-state-drag * {
  cursor: inherit !important;
}

/* Painting and performance;
 * Browsers can paint handles in their own layer.
 */
.noUi-base {
  transform: translate3d(0, 0, 0);
}

/* Slider size and handle placement;
 */
.noUi-horizontal {
  height: 3px;
}

.noUi-horizontal .noUi-handle {
  width: 13px;
  height: 13px;
  left: -6.5px;
  top: -5.5px;
}

/* Styling;
 */
.noUi-background {
  background: #eaeaea;
}

.noUi-connect {
  background: #0069b0;
  transition: all 0.4s;
}

.noUi-origin {
  border-radius: 2px;
}

.noUi-target {
  border-radius: 8px;
  border: 1px solid #eaeaea;
}

/* Handles and cursors;
 */
.noUi-dragable {
  cursor: w-resize;
}

.noUi-vertical .noUi-dragable {
  cursor: n-resize;
}

.noUi-handle {
  border-radius: 50px;
  background: #fff;
  cursor: default;
  box-shadow: 0 0 0 3px rgba(122, 174, 182, 0.43);
}

.noUi-handle > div {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 4px;
  font-size: 12px;
  color: #5a5a5a;
}

/* ----------- 12.Cart  ---------------
--------------------------------------*/
/* Cart */
.delete-btn {
  display: inline-block;
  color: #4a4a4a;
  background-color: #f2f2f2;
  font-size: 12px;
  min-width: 26px;
  height: 26px;
  line-height: 26px;
  text-align: center;
  border-radius: 50%;
  transition: all 0.4s;
}

.delete-btn:hover, .delete-btn:focus {
  background-color: #d7d7d7;
  color: #101010;
}

.info-col .product {
  margin: 0;
  padding: 6px 0;
}

.info-col .product figure {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 145px;
  margin-right: 20px;
}

.info-col .product figure img {
  display: block;
  width: 100%;
  height: auto;
}

.info-col .product .product-title {
  font-size: 15px;
  margin: 5px 0 12px;
}

.info-col .product .product-info {
  display: inline-block;
  vertical-align: top;
}

.info-col .product .product-info ul {
  color: #444;
}

.info-col .product .product-info ul li {
  margin-bottom: 1px;
}

.info-col .product .product-info ul span {
  display: inline-block;
  min-width: 80px;
  text-decoration: underline;
}

.info-col .product:after {
  content: '';
  display: table;
  clear: both;
}

.code-col {
  width: 160px;
}

.price-col {
  width: 100px;
  font-size: 15px;
  color: #222;
}

.quantity-col {
  width: 126px;
}

.quantity-col .form-control {
  display: inline-block;
  max-width: 90px;
  margin-bottom: 0;
}

.subtotal-col {
  width: 100px;
  color: #ca1515;
  font-weight: 700;
  font-size: 16px;
}

.delete-col {
  width: 70px;
  text-align: center;
}

.shipping-container {
  margin-top: 15px;
}

.shipping-container .form-control,
.shipping-container .form-group {
  margin-bottom: 15px;
}

.shipping-container .form-group .form-control {
  margin-bottom: 0;
}

@media (min-width: 992px) {
  .shipping-container {
    margin-top: 0;
  }
}

/* Total Table */
.table.total-table {
  font-size: 15px;
  text-transform: uppercase;
  font-weight: 700;
  margin: 40px 0 15px;
}

.table.total-table tr td {
  padding: 15px 18px;
}

.table.total-table tr td:first-child {
  font-weight: 600;
  text-align: right;
  background-color: #f6f6fa;
}

.table.total-table tr:last-child td:last-child {
  color: #ca1515;
}

@media (min-width: 992px) {
  .table.total-table {
    margin-top: 0;
  }
}

/* ----------- 13.Compare ---------------
--------------------------------------*/
/* Compare */
.rating-col {
  width: 130px;
}

.summary-col {
  width: 180px;
}

.summary-col p:last-child {
  margin-bottom: 0;
}

@media (max-width: 767px) {
  .table-responsive .table tbody > tr > .summary-col {
    min-width: 180px;
    white-space: normal;
  }
}

.stock-col {
  width: 150px;
  font-weight: 600;
}

.cart-btn {
  display: inline-block;
  color: #4a4a4a;
  background-color: #f2f2f2;
  font-size: 12px;
  min-width: 26px;
  height: 26px;
  line-height: 26px;
  text-align: center;
  border-radius: 50%;
  transition: all 0.4s;
}

.cart-btn:hover, .cart-btn:focus {
  background-color: #d7d7d7;
  color: #101010;
}

.product-ratings {
  height: 15px;
}

.product-ratings:before {
  content: "\f005  " "\f005  " "\f005  " "\f005  " "\f005";
  color: #eaeaea;
}

.product-ratings,
.ratings {
  display: inline-block;
  font-size: 15px;
  line-height: 1;
  position: relative;
  font-family: FontAwesome;
}

.ratings {
  position: absolute;
  top: 0;
  left: 0;
  white-space: nowrap;
  overflow: hidden;
}

.ratings:before {
  content: "\f005  " "\f005  " "\f005  " "\f005  " "\f005";
  color: #f4b907;
}

.ratings-text {
  font-size: 14px;
  text-indent: -9999px;
}

.ratings-count {
  margin-top: 2px;
  margin-bottom: 0;
  font-size: 12px;
}

/* ----------- 14.Checkout -------------
--------------------------------------*/
/* Checkout */
.table tr.total-row {
  font-size: 15px;
  font-weight: 700;
  text-transform: uppercase;
}

.table tr.total-row td {
  padding: 15px 18px;
}

.table tr.total-row td:first-child {
  font-weight: 600;
  text-align: right;
  background-color: #f6f6fa;
}

.table tr.total-row:last-child td:last-child {
  color: #ca1515;
}

.checkout-action {
  font-size: 0;
}

.checkout-action .btn + .btn {
  margin-left: 4px;
}

/* ----------- 15.Contact  ------------
--------------------------------------*/
#map {
  height: 340px;
  background-color: #171717;
  position: relative;
  margin-bottom: 50px;
}

@media (min-width: 768px) {
  #map {
    height: 420px;
    margin-bottom: 60px;
  }
  #map.vertical {
    height: 490px;
    margin-bottom: 0;
  }
}

#map address {
  font-size: 13px;
  margin: 0;
  padding: 15px 10px 10px;
  line-height: 1.5;
}

.contact-box {
  padding: 18px 20px;
  background-color: #f8f6fa;
  margin-bottom: 30px;
}

@media (max-width: 767px) {
  .contact-box {
    padding: 15px;
  }
}

.contact-box strong {
  font-weight: 600;
  color: #222;
}

.contact-box h3 {
  font-size: 16px;
  margin-bottom: 13px;
  padding-bottom: 13px;
  border-bottom: 1px solid #eaeaea;
}

.contact-box:last-child {
  margin-bottom: 0;
}

.contact-box .social-icons {
  display: inline-block;
}

.contact-box .social-icon {
  color: #222;
}

.contact-box .social-icon:hover, .contact-box .social-icon:focus {
  color: #0069b0;
}

/* ----------- 16.404 -----------------
--------------------------------------*/
.error-page {
  padding-top: 20px;
  padding-bottom: 90px;
}

@media (min-width: 768px) {
  .error-page {
    padding-top: 30px;
    padding-bottom: 100px;
  }
}

@media (min-width: 992px) {
  .error-page {
    padding-top: 60px;
    padding-bottom: 130px;
  }
}

.error-page h1 {
  font-size: 120px;
  margin-bottom: 35px;
  line-height: 0.7;
  color: #0069b0;
  font-weight: 600;
}

@media (min-width: 768px) {
  .error-page h1 {
    font-size: 180px;
    margin-bottom: 50px;
  }
}

@media (min-width: 992px) {
  .error-page h1 {
    font-size: 260px;
    margin-bottom: 70px;
  }
}

.error-page h2 {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 20px;
}

@media (min-width: 768px) {
  .error-page h2 {
    font-size: 26px;
  }
}

@media (min-width: 992px) {
  .error-page h2 {
    font-size: 32px;
    margin-bottom: 30px;
  }
}

.error-page p {
  font-weight: 300;
  font-size: 15px;
  line-height: 1.5;
  max-width: 420px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25px;
}

@media (min-width: 768px) {
  .error-page p {
    font-size: 18px;
  }
}

@media (min-width: 992px) {
  .error-page p {
    font-size: 20px;
    max-width: 520px;
    margin-bottom: 30px;
  }
}

/* ---------- 17.FaQS -----------------
--------------------------------------*/
.sidebar.faqs-sidebar .widget {
  margin-bottom: 50px;
}

.sidebar.faqs-sidebar .widget:last-child {
  margin-bottom: 0;
}

.sidebar.faqs-sidebar .widget .widget-title {
  font-size: 16px;
  text-transform: uppercase;
  padding-bottom: 15px;
  margin-bottom: 20px;
}

.sidebar.faqs-sidebar .widget .widget-title:after {
  bottom: 0;
  max-width: 40px;
  background-color: #eee;
  max-width: 50px;
}

.sidebar.faqs-sidebar .widget .faqs-cat-list {
  counter-reset: count-faqs;
}

.sidebar.faqs-sidebar .widget .faqs-cat-list li {
  counter-increment: count-faqs;
  padding: 5px 0;
  border-bottom: 1px solid #eee;
}

.sidebar.faqs-sidebar .widget .faqs-cat-list li:before {
  font-weight: 600;
  content: counter(count-faqs);
  font-size: 18px;
  color: #d7d7d7;
  margin-right: 6px;
  vertical-align: middle;
}

.sidebar.faqs-sidebar .widget .faqs-cat-list li a {
  color: #333;
  vertical-align: middle;
}

.sidebar.faqs-sidebar .widget .faqs-cat-list li a:hover, .sidebar.faqs-sidebar .widget .faqs-cat-list li a:focus {
  color: #0069b0;
}

/* ----------- 18.Footers ---------------
--------------------------------------*/
.footer {
  background-color: #171717;
  color: #9a9a9a;
}

.footer > .container,
.footer > .container-fluid {
  padding-top: 75px;
  padding-bottom: 15px;
}

.footer hr {
  margin: 27px 0 21px;
  border-top-color: #3e3e3e;
}

.footer a {
  color: #9a9a9a;
}

.footer a:hover, .footer a:focus {
  color: #fff;
}

.footer .widget {
  margin-bottom: 60px;
}

.footer .widget .widget-title {
  font: 400 15px/1.1 "Montserrat", Arial, sans-serif;
  margin: 2px 0 25px;
  text-transform: uppercase;
  color: #fff;
}

.footer .widget.about-widget img {
  display: inline-block;
  max-width: 100%;
  height: auto;
}

.footer .widget .fa-ul li {
  margin-bottom: 9px;
}

.footer .widget .fa-ul li .fa-li {
  font-size: 85%;
}

.footer .widget .tweet_list li {
  padding: 7px 10px 7px 10px;
  position: relative;
  margin-bottom: 14px;
  background-color: #141414;
  transition: all 0.4s;
}

.footer .widget .tweet_list li .twitter-icon {
  position: absolute;
  right: 12px;
  bottom: 6px;
  font-size: 20px;
  line-height: 1;
  color: #0e0e0e;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.06);
}

.footer .widget .tweet_list li .tweet_time {
  display: block;
  position: relative;
  font-size: 12px;
  font-style: italic;
  color: #6a6a6a;
  margin-top: 9px;
  padding-top: 3px;
}

.footer .widget .tweet_list li .tweet_time:after {
  content: '';
  display: block;
  width: 28px;
  height: 1px;
  background-color: #4e4e4e;
  position: absolute;
  top: 0;
  left: 0;
}

.footer .widget .tweet_list li .tweet_time a {
  color: #6a6a6a;
}

.footer .widget .tweet_list li .tweet_time a:hover, .footer .widget .tweet_list li .tweet_time a:focus {
  color: #fff;
}

.footer .widget .tweet_list li:hover {
  background-color: #101010;
}

.footer .widget .tweet_list li:last-child {
  margin-bottom: 0;
}

.footer .widget form > .row {
  margin-left: -8px;
  margin-right: -8px;
}

.footer .widget form > .row [class*="col-"] {
  padding-left: 8px;
  padding-right: 8px;
}

.footer .widget .form-group {
  margin-bottom: 16px;
}

.footer .widget .form-control {
  background-color: #131313;
  border-radius: 0;
  font-size: 13px;
  padding: 7px 12px;
  height: auto;
  color: #9a9a9a;
  border-color: #111;
  margin-bottom: 12px;
}

.footer .widget .form-control::-moz-placeholder {
  color: #9a9a9a;
}

.footer .widget .form-control:-ms-input-placeholder {
  color: #9a9a9a;
}

.footer .widget .form-control::-webkit-input-placeholder {
  color: #9a9a9a;
}

.footer .widget .form-control:focus, .footer .widget .form-control:hover {
  background-color: #111;
  border-color: #101010;
  color: #fff;
}

.footer .widget textarea.form-control {
  min-height: 110px;
}

.footer .widget .btn {
  font-weight: 400;
  font-size: 13px;
  border-radius: 0;
  padding: 5px 12px;
}

.footer .widget.newsletter-widget form {
  margin-bottom: 14px;
}

.footer .widget.newsletter-widget p:last-child {
  font-size: 12px;
  color: #6a6a6a;
  margin-bottom: 0;
}

.footer .widget .tagcloud a {
  display: block;
  float: left;
  padding: 4px 9px;
  background-color: #131313;
  color: #9a9a9a;
  margin: 0 6px 6px 0;
  transition: background 0.45s;
}

.footer .widget .tagcloud a:hover, .footer .widget .tagcloud a:focus {
  background-color: #0069b0;
  color: #fff;
}

.footer .widget .tagcloud:after {
  content: '';
  display: table;
  clear: both;
}

.footer .widget .posts-list li {
  margin-bottom: 12px;
  padding: 6px;
  background-color: #141414;
  transition: all 0.4s;
}

.footer .widget .posts-list li figure {
  max-width: 52px;
  float: left;
}

.footer .widget .posts-list li figure a {
  display: block;
}

.footer .widget .posts-list li figure img {
  display: block;
  width: 100%;
  height: auto;
}

.footer .widget .posts-list li h5, .footer .widget .posts-list li span {
  display: block;
  margin-left: 62px;
}

.footer .widget .posts-list li h5 {
  font-size: 13px;
  font-weight: 600;
  margin-top: 6px;
  margin-bottom: 4px;
}

.footer .widget .posts-list li span {
  font-style: italic;
  font-size: 12px;
}

.footer .widget .posts-list li:after {
  content: '';
  display: table;
  clear: both;
}

.footer .widget .posts-list li:hover {
  background-color: #101010;
}

.footer .widget.flickr-widget .row {
  margin-left: -4px;
  margin-right: -4px;
}

.footer .widget.flickr-widget .flickr-widget-list {
  list-style: none;
}

.footer .widget.flickr-widget .flickr-widget-list li {
  display: block;
  float: left;
  margin: 0 3px 6px;
  padding: 0;
  width: calc( 25% - 6px);
  max-width: 60px;
  overflow: hidden;
}

.footer .widget.flickr-widget .flickr-widget-list li a {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
}

.footer .widget.flickr-widget .flickr-widget-list li a img {
  display: block;
  width: 100%;
  height: auto;
}

.footer .widget .about-list li {
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid #222;
}

.footer .widget .about-list li i {
  float: left;
  font-size: 14px;
  margin-top: 2px;
  min-width: 32px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  background-color: #141414;
  border-radius: 50%;
}

.footer .widget .about-list li .about-list-content {
  margin-left: 44px;
}

.footer .widget .about-list li label {
  margin-bottom: 2px;
}

.footer .widget .about-list li:after {
  content: '';
  display: table;
  clear: both;
}

.footer .widget .about-list li:last-child {
  padding-bottom: 0;
  margin-bottom: 0;
  border-bottom: none;
}

.footer .widget.instagram-widget {
  position: relative;
  margin: 0;
}

.footer .widget.instagram-widget .btn.btn-follow {
  display: block;
  padding: 3px 14px;
  min-width: 170px;
  font-size: 12px;
  margin-left: -85px;
  text-align: center;
  position: absolute;
  left: 50%;
  bottom: 0;
  z-index: 99;
  border-radius: 5px 5px 0 0;
  color: #fff;
  transition: all 0.4s;
  background-color: #0069b0;
  border-color: #0069b0;
  opacity: 0.8;
}

.footer .widget.instagram-widget .btn.btn-follow:hover, .footer .widget.instagram-widget .btn.btn-follow:focus {
  opacity: 1;
  color: #fff;
  background-color: #0069b0;
  border-color: #0069b0;
}

.footer .widget.instagram-widget #instafeed img {
  display: block;
  width: 100%;
  height: auto;
}

.footer .widget.instagram-widget #instafeed a {
  display: block;
  position: relative;
}

.footer .widget.instagram-widget #instafeed a:after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  transition: all 0.75s ease;
  -webkit-transition: all 0.75s ease;
  opacity: 0;
  visibility: hidden;
  background-color: rgba(255, 255, 255, 0.4);
}

.footer .widget.instagram-widget #instafeed a:focus:after, .footer .widget.instagram-widget #instafeed a:hover:after {
  opacity: 1;
  visibility: visible;
}

.footer address {
  margin-bottom: 0;
}

.footer label {
  font-weight: 700;
  margin-right: 3px;
}

.footer-bottom {
  background-color: #121212;
  padding: 35px 0;
}

.footer-bottom .footer-right {
  margin-bottom: 8px;
}

@media (min-width: 992px) {
  .footer-bottom {
    padding: 40px 0;
  }
  .footer-bottom .footer-right {
    float: right;
    margin-bottom: 0;
  }
}

@media (max-width: 991px) {
  .footer-bottom {
    text-align: center;
  }
}

.footer-bottom .copyright {
  margin-bottom: 0;
}

.footer-bottom .copyright a {
  color: #0069b0;
}

.footer-bottom .copyright a:hover, .footer-bottom .copyright a:focus {
  color: #004b7d;
}

.footer-bottom .footer-menu {
  font-size: 0;
}

.footer-bottom .footer-menu li {
  font-size: 13px;
  display: inline;
}

.footer-bottom .footer-menu li + li:before {
  content: '•';
  margin: 0 8px;
}

.footer-bottom .footer-menu li.active a {
  color: #0069b0;
}

.footer-bottom .footer-menu a {
  display: inline-block;
}

.social-icons {
  font-size: 0;
}

.social-icons label {
  font-size: 13px;
}

.social-icons .social-icon {
  display: inline-block;
  font-size: 13px;
  margin: 0 5px;
}

/* -------- Scroll Top Button  -------*/
#scroll-top {
  position: fixed;
  bottom: 70px;
  right: 30px;
  display: block;
  visibility: hidden;
  cursor: pointer;
  z-index: 999;
  width: 36px;
  height: 36px;
  line-height: 36px;
  font-size: 18px;
  opacity: 0;
  transition: all 0.4s;
  transform: translateY(75px);
  text-align: center;
  color: #fff;
  background-color: #0069b0;
}

#scroll-top.fixed {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

#scroll-top:hover, #scroll-top:focus {
  background-color: #004b7d;
}

@media (max-width: 767px) {
  #scroll-top {
    display: none;
    visibility: hidden;
    opacity: 0;
  }
}

/* --------- 19.Owl Carousel -----------
--------------------------------------*/
/* Owl Carousel */
.owl-carousel .animated {
  animation-duration: 1000ms;
  animation-fill-mode: both;
}

.owl-carousel .owl-animated-in {
  z-index: 0;
}

.owl-carousel .owl-animated-out {
  z-index: 1;
}

.owl-carousel .fadeOut {
  animation-name: fadeOut;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/* 
 * 	Owl Carousel - Auto Height Plugin
 */
.owl-height {
  transition: height 500ms ease-in-out;
}

/* 
 *  Core Owl Carousel CSS File
 */
.owl-carousel {
  display: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  /* position relative and z-index fix webkit rendering fonts issue */
  position: relative;
  z-index: 1;
}

.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y;
}

.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  /* fix for flashing background */
  -webkit-transform: translate3d(0px, 0px, 0px);
}

.owl-carousel .owl-item {
  position: relative;
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}

.owl-carousel .owl-item img {
  display: block;
  width: 100%;
  height: auto;
  -webkit-transform-style: preserve-3d;
}

.owl-carousel .owl-nav.disabled,
.owl-carousel .owl-dots.disabled {
  display: none;
}

.owl-carousel .owl-nav .owl-prev,
.owl-carousel .owl-nav .owl-next,
.owl-carousel .owl-dot {
  cursor: pointer;
  user-select: none;
}

.owl-carousel.owl-loaded {
  display: block;
}

.owl-carousel.owl-loading {
  opacity: 0;
  display: block;
}

.owl-carousel.owl-hidden {
  opacity: 0;
}

.owl-carousel.owl-refresh .owl-item {
  display: none;
}

.owl-carousel.owl-drag .owl-item {
  user-select: none;
}

.owl-carousel.owl-grab {
  cursor: move;
  cursor: -webkit-grab;
  cursor: -o-grab;
  cursor: -ms-grab;
}

.owl-carousel.owl-rtl {
  direction: rtl;
}

.owl-carousel.owl-rtl .owl-item {
  float: right;
}

/* No Js */
.no-js .owl-carousel {
  display: block;
}

/* 
 * 	Owl Carousel - Lazy Load Plugin
 */
.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  transition: opacity 400ms ease;
}

.owl-carousel .owl-item img {
  transform-style: preserve-3d;
}

/* 
 * 	Owl Carousel - Video Plugin
 */
.owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000;
}

.owl-carousel .owl-video-play-icon {
  position: absolute;
  height: 80px;
  width: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  background: url("../assets/owl.video.play.png") no-repeat;
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  transition: scale 100ms ease;
}

.owl-carousel .owl-video-play-icon:hover {
  -webkit-transition: scale(1.3, 1.3);
  -moz-transition: scale(1.3, 1.3);
  -ms-transition: scale(1.3, 1.3);
  -o-transition: scale(1.3, 1.3);
  transition: scale(1.3, 1.3);
}

.owl-carousel .owl-video-playing .owl-video-tn,
.owl-carousel .owl-video-playing .owl-video-play-icon {
  display: none;
}

.owl-carousel .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
  transition: opacity 400ms ease;
}

.owl-carousel .owl-video-frame {
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%;
}

/* Nav Dots*/
.owl-dots {
  margin-top: 25px;
  text-align: center;
}

.closer-dots .owl-dots {
  margin-top: 15px;
}

.owl-dot {
  display: inline-block;
  width: 11px;
  height: 11px;
  background-color: #eaeaea;
  margin: 0 5px;
  border: 1px solid #eaeaea;
  border-radius: 50%;
  transition: all 0.4s;
}

.owl-dot:hover, .owl-dot:focus, .owl-dot.active {
  background-color: #0069b0;
  border-color: #0069b0;
}

.no-radius-dots .owl-dot {
  border-radius: 0;
}

.bg-custom .owl-dot:hover, .bg-custom .owl-dot:focus, .bg-custom .owl-dot.active {
  background-color: #004b7d;
  border-color: #004b7d;
}

.bg-lightgray .owl-dot,
.bg-gray .owl-dot {
  border-color: #b7b7b7;
  background-color: #b7b7b7;
}

.bg-lightgray .owl-dot:hover, .bg-lightgray .owl-dot:focus, .bg-lightgray .owl-dot.active,
.bg-gray .owl-dot:hover,
.bg-gray .owl-dot:focus,
.bg-gray .owl-dot.active {
  background-color: #0069b0;
  border-color: #0069b0;
}

.owl-nav {
  position: relative;
  margin-top: 35px;
  text-align: center;
}

.owl-prev,
.owl-next {
  display: inline-block;
  font-size: 16px;
  line-height: 34px;
  height: 36px;
  width: 36px;
  color: #c3c9d5;
  border: 1px solid #ebeff5;
  text-align: center;
  transition: all 0.4s;
}

.owl-prev:hover, .owl-prev:focus,
.owl-next:hover,
.owl-next:focus {
  border-color: #0069b0;
  background-color: #0069b0;
  color: #fff;
}

.owl-next {
  margin-left: 10px;
}

/* --------- 20.Coming Soon -----------
--------------------------------------*/
/* Countdown 1 */
.coming-soon {
  min-height: 100vh;
  width: 100%;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  padding: 80px 0;
}

.coming-soon .site-logo {
  float: none;
  display: inline-block;
  margin: 0 0 15px;
}

@media (min-width: 768px) {
  .coming-soon .site-logo {
    margin-bottom: 20px;
  }
}

.coming-soon h1 {
  font-family: "Montserrat", Arial, sans-serif;
  font-size: 32px;
  margin-bottom: 20px;
}

@media (min-width: 768px) {
  .coming-soon h1 {
    font-size: 48px;
  }
}

@media (min-width: 992px) {
  .coming-soon h1 {
    font-size: 64px;
    margin-bottom: 25px;
  }
}

.coming-soon h2 {
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 300;
  font-size: 20px;
  letter-spacing: -0.02em;
  color: #555;
  margin-bottom: 20px;
}

@media (min-width: 768px) {
  .coming-soon h2 {
    font-size: 26px;
    margin-bottom: 25px;
  }
}

@media (min-width: 992px) {
  .coming-soon h2 {
    font-size: 32px;
    margin-bottom: 30px;
  }
}

.coming-soon p {
  font-size: 14px;
  line-height: 1.5;
  max-width: 620px;
  margin: 0 auto 25px;
  color: #5e5e5e;
}

@media (min-width: 768px) {
  .coming-soon p {
    font-size: 15px;
    margin-bottom: 35px;
  }
}

@media (min-width: 992px) {
  .coming-soon p {
    font-size: 16px;
    margin-bottom: 45px;
  }
}

.coming-soon .soon-countdown {
  max-width: 600px;
  margin: 0 auto 25px;
}

@media (min-width: 768px) {
  .coming-soon .soon-countdown {
    margin-bottom: 35px;
  }
}

@media (min-width: 992px) {
  .coming-soon .soon-countdown {
    font-size: 16px;
    margin-bottom: 40px;
  }
}

.coming-soon .countdown-amount {
  font-family: "Montserrat", Arial, sans-serif;
  font-size: 28px;
  font-weight: 700;
  color: #2e2e2e;
  line-height: 0.8;
}

@media (min-width: 768px) {
  .coming-soon .countdown-amount {
    font-size: 36px;
  }
}

@media (min-width: 992px) {
  .coming-soon .countdown-amount {
    font-size: 44px;
  }
}

.coming-soon .countdown-period {
  font-size: 13px;
  font-weight: 600;
  color: #555;
  line-height: 1.4;
}

@media (min-width: 768px) {
  .coming-soon .countdown-period {
    font-size: 14px;
    line-height: 1.6;
  }
}

@media (min-width: 992px) {
  .coming-soon .countdown-period {
    font-size: 15px;
    line-height: 1.7;
  }
}

.coming-soon .soon-newsletter {
  max-width: 380px;
  margin: 0 auto;
}

.coming-soon .soon-newsletter h3 {
  font-size: 16px;
  margin-bottom: 12px;
}

@media (min-width: 768px) {
  .coming-soon .soon-newsletter h3 {
    font-size: 18px;
    margin-bottom: 15px;
  }
}

.coming-soon form {
  position: relative;
}

.coming-soon form .form-control {
  padding-right: 110px;
  margin-bottom: 0;
}

.coming-soon form .btn {
  position: absolute;
  right: 0;
  top: 0;
}

.soon-nav-container {
  position: fixed;
  left: 15px;
  top: 10px;
  z-index: 999;
}

@media (min-width: 768px) {
  .soon-nav-container {
    top: 15px;
  }
}

@media (min-width: 992px) {
  .soon-nav-container {
    left: 40px;
    top: 40px;
  }
}

.soon-nav-container .soon-menu-toggle {
  display: inline-block;
  position: relative;
  padding: 10px 12px;
  margin: 0 0 15px;
  line-height: 0;
  background-image: none;
  border: none;
  border-radius: 0;
  user-select: none;
  transition: all 0.35s ease;
  border-radius: 50%;
  background-color: transparent;
  border: 1px solid #222;
  cursor: pointer;
}

@media (min-width: 992px) {
  .soon-nav-container .soon-menu-toggle {
    padding: 14.5px 12px;
  }
}

.soon-nav-container .soon-menu-toggle .icon-bar {
  display: block;
  width: 20px;
  height: 2px;
  margin: 11px 0;
  border-radius: 0;
  position: relative;
}

@media (min-width: 992px) {
  .soon-nav-container .soon-menu-toggle .icon-bar {
    width: 30px;
  }
}

.soon-nav-container .soon-menu-toggle .icon-bar, .soon-nav-container .soon-menu-toggle .icon-bar:before, .soon-nav-container .soon-menu-toggle .icon-bar:after {
  display: inline-block;
  width: 20px;
  height: 2px;
  background-color: #222;
  border-radius: 4px;
  transition: all 0.35s ease;
}

@media (min-width: 992px) {
  .soon-nav-container .soon-menu-toggle .icon-bar, .soon-nav-container .soon-menu-toggle .icon-bar:before, .soon-nav-container .soon-menu-toggle .icon-bar:after {
    width: 30px;
  }
}

.soon-nav-container .soon-menu-toggle .icon-bar:before, .soon-nav-container .soon-menu-toggle .icon-bar:after {
  content: '';
  position: absolute;
  left: 0;
}

.soon-nav-container .soon-menu-toggle .icon-bar:before {
  top: 7px;
}

.soon-nav-container .soon-menu-toggle .icon-bar:after {
  top: -7px;
}

.soon-nav-container .soon-menu-toggle:hover {
  background-color: rgba(0, 0, 0, 0.55);
  border-color: #222;
}

.soon-nav-container .soon-menu-toggle:hover .icon-bar, .soon-nav-container .soon-menu-toggle:hover .icon-bar:before, .soon-nav-container .soon-menu-toggle:hover .icon-bar:after {
  background-color: #fff;
}

.soon-nav-container .soon-menu-toggle.open .icon-bar {
  background: transparent !important;
}

.soon-nav-container .soon-menu-toggle.open .icon-bar:before, .soon-nav-container .soon-menu-toggle.open .icon-bar:after {
  transform-origin: 50% 50%;
  top: 0;
}

.soon-nav-container .soon-menu-toggle.open .icon-bar:before {
  transform: rotate3d(0, 0, 1, 45deg);
}

.soon-nav-container .soon-menu-toggle.open .icon-bar:after {
  transform: rotate3d(0, 0, 1, -45deg);
}

.soon-nav-container .soon-nav {
  display: none;
}

.soon-nav-container .soon-nav .soon-menu li {
  display: block;
}

.soon-nav-container .soon-nav .soon-menu li a {
  display: inline-block;
  color: #222;
  font-size: 14px;
  font-weight: 600;
  padding: 6px 13px;
  background-color: rgba(255, 255, 255, 0.88);
  transition: all 0.4s;
}

.soon-nav-container .soon-nav .soon-menu li + li {
  margin-top: 2px;
}

.soon-nav-container .soon-nav .soon-menu li:hover a, .soon-nav-container .soon-nav .soon-menu li:focus a {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.84);
}

.soon-nav-container .soon-nav .soon-menu li.active a {
  color: #fff;
  background-color: rgba(0, 105, 176, 0.84);
}

.close-sections-btn {
  display: none;
  position: absolute;
  right: 25px;
  top: 20px;
  z-index: 999;
  color: #222;
  background-color: #f1f1f6;
  padding: 4px 11px;
  transition: all 0.4s;
}

.close-sections-btn:hover, .close-sections-btn:focus {
  color: #fff;
  background-color: #0069b0;
}

.soon-section {
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.96);
  z-index: 900;
  opacity: 0;
  visibility: hidden;
  transition: transform 0.5s ease, opacity 0.55s, visibility 0.8s;
  transform: translateX(60%);
  overflow-y: auto;
}

@media (min-width: 992px) {
  .soon-section {
    width: 50%;
  }
}

.soon-section .soon-section-wrapper {
  position: relative;
  padding: 70px 15px 40px;
  min-height: 100%;
}

@media (min-width: 992px) {
  .soon-section .soon-section-wrapper {
    padding: 60px 25px;
  }
}

.soon-section h2 {
  font-family: "Montserrat", Arial, sans-serif;
  font-size: 24px;
}

@media (min-width: 992px) {
  .soon-section h2 {
    font-size: 28px;
  }
}

@media (min-width: 992px) {
  .soon-section h2 {
    font-size: 32px;
  }
}

.soon-section h3 {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 22px;
}

@media (min-width: 992px) {
  .soon-section h3 {
    font-size: 20px;
  }
}

@media (min-width: 992px) {
  .soon-section h3 {
    font-size: 22px;
  }
}

.soon-section.active {
  opacity: 1;
  visibility: visible;
  transform: translateX(0);
}

.soon-section.active .close-sections-btn {
  display: block;
}

/* jQuery Countdown styles 2.0.0. */
.countdown-section {
  display: block;
  float: left;
  text-align: center;
}

.countdown-period {
  display: block;
}

.countdown-descr {
  display: block;
  width: 100%;
}

.countdown-row {
  clear: both;
  width: 100%;
  padding: 0px;
  text-align: center;
}

.countdown-row:after {
  content: '';
  display: table;
  clear: both;
}

.countdown-show1 .countdown-section {
  width: 98%;
}

.countdown-show2 .countdown-section {
  width: 48%;
}

.countdown-show3 .countdown-section {
  width: 32.5%;
}

.countdown-show4 .countdown-section {
  width: 24.5%;
}

.countdown-show5 .countdown-section {
  width: 19.5%;
}

.countdown-show6 .countdown-section {
  width: 16.25%;
}

.countdown-show7 .countdown-section {
  width: 14%;
}

/* Countdown 2 */
.dark .coming-soon.overlay-container .overlay {
  opacity: 0.45;
}

.dark .coming-soon h1 {
  color: #fff;
}

.dark .coming-soon h2 {
  color: #f2f2f2;
}

.dark .coming-soon p {
  color: #eee;
}

.dark .coming-soon .countdown-amount {
  color: #fff;
}

.dark .coming-soon .countdown-period {
  color: #eee;
}

.dark .coming-soon .soon-newsletter h3 {
  color: #f1f1f1;
}

.dark .coming-soon form .form-control {
  background-color: #171717;
  border-color: #101010;
  color: #999;
}

.dark .coming-soon form .form-control::placeholder {
  color: #999;
}

.dark .soon-nav-container .soon-menu-toggle {
  border-color: #f1f1f1;
}

.dark .soon-nav-container .soon-menu-toggle .icon-bar, .dark .soon-nav-container .soon-menu-toggle .icon-bar:before, .dark .soon-nav-container .soon-menu-toggle .icon-bar:after {
  background-color: #f1f1f1;
}

.dark .soon-nav-container .soon-menu-toggle:hover {
  background-color: rgba(0, 0, 0, 0.55);
  border-color: #222;
}

.dark .soon-nav-container .soon-menu-toggle:hover .icon-bar, .dark .soon-nav-container .soon-menu-toggle:hover .icon-bar:before, .dark .soon-nav-container .soon-menu-toggle:hover .icon-bar:after {
  background-color: #fff;
}

.dark .soon-nav-container .soon-menu-toggle.open .icon-bar {
  background: transparent !important;
}

.dark .soon-nav-container .soon-nav .soon-menu li a {
  color: #f1f1f1;
  background-color: rgba(0, 0, 0, 0.78);
}

.dark .soon-nav-container .soon-nav .soon-menu li:hover a, .dark .soon-nav-container .soon-nav .soon-menu li:focus a {
  color: #222;
  background-color: rgba(255, 255, 255, 0.78);
}

.dark .soon-nav-container .soon-nav .soon-menu li.active a {
  color: #fff;
  background-color: rgba(0, 105, 176, 0.78);
}

.dark .close-sections-btn {
  color: #f1f1f1;
  background-color: #363636;
}

.dark .close-sections-btn:hover, .dark .close-sections-btn:focus {
  color: #fff;
  background-color: #0069b0;
}

.dark .soon-section {
  color: #eee;
  background-color: rgba(0, 0, 0, 0.8);
}

.dark .soon-section h2, .dark .soon-section h3 {
  color: #f1f1f1;
}

.dark .soon-section .service i.icon-bg {
  color: #f1f1f1;
  background-color: #2b2b2b;
}

.dark .soon-section .service.service-box {
  background-color: #212121;
}

.dark .soon-section .service:hover i.icon-bg, .dark .soon-section .service:focus i.icon-bg {
  color: #fff;
  background-color: #005791;
}

.dark .soon-section .member .member-title small {
  background-color: #242424;
  color: #fff;
}

.dark .soon-section .progress {
  background-color: #3b3b3b;
}

.dark .soon-section .progress-container .progress-title {
  color: #c7c7c7;
}

.dark .soon-section blockquote {
  background-color: #1c1c1c;
}

.dark .soon-section .contact-box {
  background-color: #262626;
}

.dark .soon-section .contact-box strong {
  color: #b7b7b7;
}

.dark .soon-section .contact-box h4 strong {
  color: #eee;
}

.dark .soon-section .contact-box .social-icons .social-icon {
  color: #a1a1a1;
}

.dark .soon-section .contact-box .social-icons .social-icon:hover, .dark .soon-section .contact-box .social-icons .social-icon:focus {
  color: #0069b0;
}

.dark .soon-section .contact-box a:hover,
.dark .soon-section .contact-box a:focus {
  color: #005d9c;
}

.dark .soon-section .form-control {
  background-color: #262626;
  border-color: #171717;
  color: #999;
}

.dark .soon-section .form-control::placeholder {
  color: #999;
}
