/* Elements Typography */
h1,.h1,
h2,.h2,
h3,.h3,
h4,.h4,
h5,.h5,
h6,.h6 {
	font-weight:600;
	font-family: $font-family, Arial, sans-serif;
	line-height:1;
	color: $heading-color;
	margin-top:0;
	margin-bottom:20px;

	small {
		color: inherit;
	}
}

h1,
.h1 {
	font-size: 24px;
}

h2,
.h2{
	font-size: 20px;
}

h3,
.h3 {
	font-size: 18px;
}

h4,
.h4 {
	font-size: 16px;
	margin-bottom:15px;
}

h5,
.h5 {
	font-size: 14px;
	margin-bottom:15px;
}

h6,
.h6 {
	font-size: 12px;
	margin-bottom:15px;
}

a {
	color: $first-color;
	transition: color 0.4s;

	&:hover,
	&:focus {
		color: darken($first-color, 25%);
		text-decoration:none;
	}
}

.text-custom {
	color: $first-color;
}

.text-custom2 {
	color: $second-color;
}

.text-white {
	color: #fff !important;
}

.text-muted {
	color: #777 !important;
}

.text-light {
	font-weight:300 !important;
}

.text-spaced {
	letter-spacing: 0.05em;
}

.text-lineth {
	text-decoration: line-through;
}

.text-underline {
	text-decoration: underline;
}

blockquote {
	position: relative;
	padding:25px 30px 23px 30px;
	margin: 0 0 20px;
	font-size:14px;
	line-height: 1.6;
	background-color: #f6f6fa;
	font-style:italic;
	border:none;
	border-radius: 0;

	.bg-lightgray & {
		background-color: $gray;
	}

	@media (max-width: $maxmqsm) {
		padding:18px 15px 16px 15px;
	}

	p {
		margin-bottom: 0;
	}

	cite {
		display: inline-block;
		margin-top:16px;
		font-size:13px;
		font-weight:600;
		font-style:italic;
		color: $first-color;
	}

	&.blockquote-reverse {
		border:none;
		padding:25px 30px 23px 30px;

		@media (max-width: $maxmqsm) {
			padding:18px 15px 16px 15px;
		}
	}
}

p {
	margin-bottom:15px;
}

.highlight {
	background-color: #101010;
	color: #fff;
	padding: 0 4px;

	&.rounded {
		border-radius:5px;
	}

	&.gray {
		color: #323232;
		background-color: #eaeaea;
	}

	&.darkgray {
		color: #323232;
		background-color: #c7c7c7;
	}

	&.custom {
		background-color: $first-color;
	}

	&.custom2 {
		background-color: $second-color;
	}
}

.dropcap {
	&::first-letter {
		float:left;
		font-size:2.2em;
		padding:0 0.2em;
		margin-right:0.2em;
		line-height:1.4;
		color:#fff;
		background-color: #101010;
	}

	&.gray {
		&::first-letter {
			color: #222;
			background-color: #eaeaea;
		}
	}

	&.custom {
		&::first-letter {
			background-color: $first-color;
		}
	}

	&.custom2 {
		&::first-letter {
			color:#fff;
			background-color: $second-color;
		}
	}
}

.fa-ul {
	margin-left: 23px;
	margin-bottom:20px;
	
	li {
		margin-bottom:7px;

		.fa-li {
			left:-23px;
			width:auto;
			top:50%;
			transform: translateY(-50%);
			-webkit-transform: translateY(-50%);
			-ms-transform: translateY(-50%);
		}
	}
}

.lead {
	&.max-width {
		max-width:680px;

		.text-center & {
			margin-left: auto;
			margin-right: auto;
		}
	}

	&.smaller {
		font-size:15px;

		@media (min-width: $minmqsm) {
			font-size:17px;
		}
	}
}
