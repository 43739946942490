/* Owl Carousel */
.owl-carousel .animated {
	animation-duration: 1000ms;
	animation-fill-mode: both; 
}

.owl-carousel .owl-animated-in {
	z-index: 0;
}

.owl-carousel .owl-animated-out {
	z-index: 1; 
}

.owl-carousel .fadeOut {
	animation-name: fadeOut; 
}


@keyframes fadeOut {
  0% {
    opacity: 1; }

  100% {
    opacity: 0; } 
}

/* 
 * 	Owl Carousel - Auto Height Plugin
 */
.owl-height {
	transition: height 500ms ease-in-out; 
}

/* 
 *  Core Owl Carousel CSS File
 */
.owl-carousel {
	display: none;
	width: 100%;
	-webkit-tap-highlight-color: transparent;
	/* position relative and z-index fix webkit rendering fonts issue */
	position: relative;
	z-index: 1; 
}

.owl-carousel .owl-stage {
	position: relative;
	-ms-touch-action: pan-Y; 
}

.owl-carousel .owl-stage:after {
	content: ".";
	display: block;
	clear: both;
	visibility: hidden;
	line-height: 0;
	height: 0;
}
.owl-carousel .owl-stage-outer {
	position: relative;
	overflow: hidden;
	/* fix for flashing background */
	-webkit-transform: translate3d(0px, 0px, 0px); 
}

.owl-carousel .owl-item {
	position: relative;
	min-height: 1px;
	float: left;
	-webkit-backface-visibility: hidden;
	-webkit-tap-highlight-color: transparent;
	-webkit-touch-callout: none; 
}

.owl-carousel .owl-item img {
	display: block;
	width: 100%;
	height: auto;
	-webkit-transform-style: preserve-3d;
}

.owl-carousel .owl-nav.disabled, 
.owl-carousel .owl-dots.disabled {
	display: none; 
}

.owl-carousel .owl-nav .owl-prev, 
.owl-carousel .owl-nav .owl-next, 
.owl-carousel .owl-dot {
	cursor: pointer;
	user-select: none; 
}

.owl-carousel.owl-loaded {
    display: block; 
}

.owl-carousel.owl-loading {
	opacity: 0;
	display: block; 
}

.owl-carousel.owl-hidden {
    opacity: 0; 
}

.owl-carousel.owl-refresh .owl-item {
	display: none;
}

.owl-carousel.owl-drag .owl-item {
	user-select: none; 
}

.owl-carousel.owl-grab {
	cursor: move;
	cursor: -webkit-grab;
	cursor: -o-grab;
	cursor: -ms-grab;
}

.owl-carousel.owl-rtl {
	direction: rtl; 
}

.owl-carousel.owl-rtl .owl-item {
	float: right; 
}

/* No Js */
.no-js .owl-carousel {
	display: block;
}

/* 
 * 	Owl Carousel - Lazy Load Plugin
 */
.owl-carousel .owl-item .owl-lazy {
	opacity: 0;
	transition: opacity 400ms ease;
}

.owl-carousel .owl-item img {
	transform-style: preserve-3d; 
}

/* 
 * 	Owl Carousel - Video Plugin
 */
.owl-carousel .owl-video-wrapper {
	position: relative;
	height: 100%;
	background: #000;
}

.owl-carousel .owl-video-play-icon {
	position: absolute;
	height: 80px;
	width: 80px;
	left: 50%;
	top: 50%;
	margin-left: -40px;
	margin-top: -40px;
	background: url("../assets/owl.video.play.png") no-repeat;
	cursor: pointer;
	z-index: 1;
	-webkit-backface-visibility: hidden;
	transition: scale 100ms ease;
}

.owl-carousel .owl-video-play-icon:hover {
	-webkit-transition: scale(1.3, 1.3);
	-moz-transition: scale(1.3, 1.3);
	-ms-transition: scale(1.3, 1.3);
	-o-transition: scale(1.3, 1.3);
	transition: scale(1.3, 1.3);
}

.owl-carousel .owl-video-playing .owl-video-tn,
.owl-carousel .owl-video-playing .owl-video-play-icon {
	display: none; 
}

.owl-carousel .owl-video-tn {
	opacity: 0;
	height: 100%;
	background-position: center center;
	background-repeat: no-repeat;
	-webkit-background-size: contain;
	-moz-background-size: contain;
	-o-background-size: contain;
	background-size: contain;
	transition: opacity 400ms ease;
}

.owl-carousel .owl-video-frame {
	position: relative;
	z-index: 1;
	height: 100%;
	width: 100%; 
}

/* Nav Dots*/
.owl-dots {
	margin-top: 25px;
	text-align: center;

	.closer-dots & {
		margin-top: 15px;
	}
}

.owl-dot {
	display:inline-block;
	width:11px;
	height:11px;
	background-color:#eaeaea;
	margin:0 5px;
	border:1px solid #eaeaea;
	border-radius: 50%;
	transition: all 0.4s;

	&:hover,
	&:focus,
	&.active {
		background-color: $first-color;
		border-color:$first-color;
	}

	.no-radius-dots & {
		border-radius: 0;
	}
}

.bg-custom {
	.owl-dot {

		&:hover,
		&:focus,
		&.active {
			background-color: darken($first-color, 10%);
			border-color: darken($first-color, 10%);
		}
	}
}

.bg-lightgray,
.bg-gray {
	.owl-dot {
		border-color: #b7b7b7;
		background-color: #b7b7b7;
		&:hover,
		&:focus,
		&.active {
			background-color: $first-color;
			border-color:$first-color;
		}
	}
}

.owl-nav {
	position: relative;
	margin-top:35px;
	text-align:center;
}

.owl-prev,
.owl-next {
	display:inline-block;
	font-size:16px;
	line-height:34px;
	height:36px;
	width:36px;
	color:#c3c9d5;
	border:1px solid #ebeff5;
	text-align: center;
	transition: all 0.4s;

	&:hover,
	&:focus {
		border-color: $first-color;
		background-color: $first-color;
		color:#fff
	}
}

.owl-next {
	margin-left:10px;
}