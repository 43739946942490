.sidebar.faqs-sidebar {
	.widget {

		margin-bottom: 50px;

		&:last-child {
			margin-bottom:0;
		}

		.widget-title {
			font-size:16px;
			text-transform: uppercase;
			padding-bottom:15px;
			margin-bottom:20px;

			&:after {
				bottom:0;
				max-width:40px;
				background-color: #eee;
				max-width:50px;
			}
		}

		.faqs-cat-list {
			counter-reset: count-faqs;

			li {
			counter-increment: count-faqs;
			padding:5px 0;
			border-bottom:1px solid #eee;
				&:before {
					font-weight:600;
					content: counter(count-faqs);
					font-size:18px;
					color: #d7d7d7;
					margin-right:6px;
					vertical-align:middle;
				}

				a {
					color: #333;
					vertical-align:middle;
					&:hover,
					&:focus {
						color: $first-color;
					}
				}
			}
		}
	}
}