/* Homepages - Index2 */
.slider-container-index2 {
	background-color: #e1e1e1;

	@media (min-width: $minmqlg) {
		height: 600px;
	}

	.tp-caption {
		strong {
			font-weight: 700 !important;
		}

		iframe {
			outline: none;
			border: none;
		}
	}
}

.boxed-news-container {
	padding: 15px;
	
	@media (min-width: $minmqmd) {
		max-width: 80%;
		margin: 0 auto;
	}

	.entry {
		margin-bottom: 0 !important;
	}
}